<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col xl=4 sm=5>
          <v-row>
            <v-col>
              <info-card-simple-bar 
                :icon="'mdi-information'"
                :titulo="region_detail.nombre"
                :subtitulo="'Presupuesto Ejecutado'"
                :valor="data_region_ejecucion"
                :nombre_valor="selected_filtro_1+' '+selected_filtro_2"
              />
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col>
              <info-card-simple 
                :icon="'mdi-information'"
                :titulo="region_detail.nombre"
                :subtitulo="'Gasto'"
                :valor="data_region_gasto"
                :tipo_valor="'Millones de Pesos'"
              />
            </v-col>
            <v-col>
              <info-card-simple 
                :icon="'mdi-information'"
                :titulo="region_detail.nombre"
                :subtitulo="'Marco de Evaluación'"
                :valor="data_region_marco"
                :tipo_valor="'Millones de Pesos'"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col xl=8>
          <info-card-fill-chart 
            :icon="'mdi-home-analytics'"
            :titulo="'Presupuesto Regional '+selected_filtro_2"
            :subtitulo="region_detail.nombre"
            :height_chart="size_fill_chart.height_chart"
            :height_card="size_fill_chart.height_card"
            :data_chart="data_fill_chart"
          />
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px;">
         <v-col>
           <info-card-fill-chart 
            :icon="'mdi-home-analytics'"
            :titulo="'Comparación Regional'"
            :subtitulo="'Presupuesto Ejecutado: '+selected_filtro_1+' '+selected_filtro_2"
            :height_chart="size_fill_chart_regiones.height_chart"
            :height_card="size_fill_chart_regiones.height_card"
            :data_chart="data_fill_chart_regiones"
          />
        </v-col>      
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardSimpleBar from '../../components/infoCardSimpleBar.vue';
import InfoCardFillChart from '../../components/infoCardFillChart.vue';

export default {
  name: 'View17Region',
  components: { HeaderComponent, InfoCardSimple, InfoCardSimpleBar, InfoCardFillChart},
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      selected_filtro_2: this.data_view.filtros[1].data[0],
      data_region_ejecucion:0,
      data_region_marco:null,
      data_region_gasto:null,
      data_fill_chart:null,
      data_fill_chart_regiones: null
    }
  },
  created() {
    this.get_data_region(this.region_selected, this.selected_filtro_2, this.selected_filtro_1)
    this.get_data_region_anio(this.region_selected, this.selected_filtro_2)
    this.get_data_regiones(this.selected_filtro_2, this.selected_filtro_1)
  },
  computed : {
    size_fill_chart () {
        const size_screen = this.$vuetify.breakpoint.name;
        if(size_screen == 'xl'){
          return {
            height_chart:250,
            height_card:330
          }
        }
        else{
          return {
            height_chart:260,
            height_card:311
          }
        }
      },
      size_fill_chart_regiones () {
        const size_screen = this.$vuetify.breakpoint.name;
        if(size_screen == 'xl'){
          return {
            height_chart:350,
            height_card:430
          }
        }
        else{
          return {
            height_chart:185,
            height_card:250
          }
        }
      },
  },
  watch: {
    region_selected() {
      this.get_data_region(this.region_selected, this.selected_filtro_2, this.selected_filtro_1)
      this.get_data_region_anio(this.region_selected, this.selected_filtro_2)
      this.get_data_regiones(this.selected_filtro_2, this.selected_filtro_1)
    }
  },
  methods: {

    async get_data_region(region, anio, mes) {
      let url = this.data_view.url_api[0]
      let url_api = API_URL+url+'?region='+region+'&anio='+anio+'&mes='+mes
      let response = await axios.get(url_api)
      let data = response.data.results[0]

      let marco = data.marco_evaluacion
      let gasto = data.gasto_acumulado
      let ejecucion  = data.ejecucion
    
      this.data_region_ejecucion = ejecucion
      this.data_region_marco = '$'+formatNumber(marco)
      this.data_region_gasto = '$'+formatNumber(gasto)
    },

    async get_data_region_anio(region, anio) {
      let url = this.data_view.url_api[0]
      let url_api = API_URL+url+'?region='+region+'&anio='+anio
      let response = await axios.get(url_api)
      let data = response.data.results
      this.sortByMonthObject(data)
      this.data_fill_chart = this.get_data_fill_chart_mes(data)
    },

    async get_data_regiones(anio, mes) {
      let url = this.data_view.url_api[0]
      let url_api = API_URL+url+'?anio='+anio+'&mes='+mes
      let response = await axios.get(url_api)
      let data = response.data.results
      this.data_fill_chart_regiones = this.get_data_fill_chart(data)
    },

    get_data_fill_chart(data) {
      let categories = []
      let data_gasto = []
      let data_marco = []
      let element;
      for(element of data){
        let region = element.region
        if (!categories.includes(region.nombre)) {
            let category = region.nombre.includes('Arica') ? 
                            ['Arica','Parinacota'] : 
                            region.nombre;

            let value_gasto = element.gasto_acumulado
            let value_marco = element.marco_evaluacion-value_gasto
            data_gasto.push(value_gasto)
            data_marco.push(value_marco)
            categories.push(category)
        }
      }

      return {
        series: [
          {
            data: data_gasto,
            name: 'Gasto Acumulado'
          },
          {
            data: data_marco,
            name: 'Marco de Evaluación'
          }
        ],
        categories: categories,
        name: 'Presupuesto Regional',
        unidad: 'Millones de pesos',
        tipo_unidad: 'monto',
        colors:["#0047AB","#CCCCCC"]
      }        
    },

    get_data_fill_chart_mes(data) {
      let categories = []
      let data_gasto = []
      let data_marco = []
      let meses = this.data_view.filtros[0].data

      if (data.length < 12) {
        for(let i = 0 ; i < 12 ; i++){
          let mes_select = meses[i]
          let value_gasto, value_marco;
          let ultimo_value_marco = data_marco[data_marco.length-1] + data_gasto[data_gasto.length-1]
          let element = data.find(e => e.mes == mes_select.toLowerCase());
          if( element != undefined) {
            value_gasto = element.gasto_acumulado
            value_marco = element.marco_evaluacion-value_gasto
          }
          else {
            value_gasto = 0
            value_marco = ultimo_value_marco
          }
          data_gasto.push(value_gasto)
          data_marco.push(value_marco)
          categories.push(mes_select)
        }
      }

      else {
        let element;
        for(element of data){
          let value_gasto = element.gasto_acumulado
          let value_marco = element.marco_evaluacion-value_gasto
          data_gasto.push(value_gasto)
          data_marco.push(value_marco)
        }
        categories = meses
      }

      return {
        series: [
          {
            data: data_gasto,
            name: 'Gasto Acumulado'
          },
          {
            data: data_marco,
            name: 'Marco de Evaluación'
          }
        ],
        categories: categories,
        name: 'Presupuesto Regional',
        unidad: 'Millones de pesos',
        tipo_unidad: 'monto',
        colors:["#E53935","#CCCCCC"]
      }        
    },

    sortByMonthObject(data) {  
      var months = ["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"];
      data.sort(function(a, b){
        return months.indexOf(a.mes) - months.indexOf(b.mes);
      })
    },

     updateFiltro(newValue, indexFiltro) {

      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
      }

      else if (indexFiltro == 1) {
        this.selected_filtro_2 = newValue
        this.get_data_region_anio(this.region_selected, this.selected_filtro_2)
      }

      this.get_data_region(this.region_selected, this.selected_filtro_2, this.selected_filtro_1)
      this.get_data_regiones(this.selected_filtro_2, this.selected_filtro_1)

    }
  }
}
</script>