<template>
  <v-card flat :height="height_card">
    <v-card-title>
      <div v-if="hasOptions">
        <v-app-bar
          flat
          color="rgba(0, 0, 0, 0)"
          style="margin-top:-15px;margin-left:-15px;"
        >
          <v-icon
            medium
            left
          >
           {{icon}}
          </v-icon>
          <v-toolbar-title >
            <!-- <h4 class="font-weight-medium">{{titulo}}</h4>   -->
            <div class="responsive_xl">{{titulo}}</div>
          </v-toolbar-title>
          <v-col
            
            class="d-flex mt-6 ml-2"
            cols="12"
            sm=12
            md=6
          >
            <v-select
              :value="first_option"
              :items="options"
              :label="label_options"
              dense
              outlined
              item-value="value"
              item-text="text"
              @input="cambiarOpcion"
            ></v-select>
          </v-col>
        </v-app-bar>
      </div>
      <div v-else>
        <v-icon
          medium
          left
        >
          {{icon}}
        </v-icon>
        <h4 class="font-weight-medium">{{titulo}}</h4>
      </div>
    </v-card-title>
    <v-card-subtitle class="text-left" style="margin-top:-35px;margin-left:0px">
      <h4 class="font-weight-medium">{{subtitulo}}</h4>
    </v-card-subtitle>
    <v-card-text style="margin-top:-30px">
      <column-chart 
        v-if="data_chart != null"
        :data_chart="data_chart.series"
        :height_chart="height_chart"
        :categories_chart="data_chart.categories"
        :unidad_chart="data_chart.unidad"
        :tipo_unidad_chart="data_chart.tipo_unidad"
        :colors_chart="['#AFB42B','#2E7D32']"
      />
    </v-card-text>
  </v-card>
</template>

<style>
.responsive_xl { 
  font-weight: 500;
  font-size: 95%;
}
</style>

<script>
import ColumnChart from './charts/ColumnChart.vue'
export default {
  components: { ColumnChart },
  name:'infoCardColumnChartOptions',
  props: [
    'data',
    'icon','titulo','subtitulo',
    'height_card','height_chart',
    'hasOptions', 'options', 'first_option','label_options',
    'create_data_chart'
  ],
  data () {
    return {
      data_chart: null
    }
  },
  watch:{
    data () {
      if(this.hasOptions)
        this.data_chart = this.createDataChart(this.data,this.first_option)
    }
  },
  methods: {
    cambiarOpcion(opcion){
      this.data_chart = this.createDataChart(this.data,opcion)
    },

    getDataChart_1(data_by_option,data_names){
      let cant_dataset_by_option = Object.keys(data_by_option).length
      let series = []

      if(cant_dataset_by_option >= 2) 
      {
        for(let element of data_names){
          let val = element.value
          let data_val = data_by_option[val]
          let name = element.text

          let data_temp = {
            data: data_val,
            name: name
          }
          series.push(data_temp)
        }
      }
      else 
      {
        //Solo un dataset
      }
    
      return {
        series: series
      }

    },

    getDataByOption(data,option){
      let data_result;
   
      data_result = data[option]
    
      return data_result
    },

    getUnidad(data,option){
      let data_result = data.find(e => e.tipo == option)
      return data_result.text
    },

    createDataChart(data_component,option){
      let data_names = data_component.data_names
      let category_name = data_component.category_name

      let unidad =  typeof data_component.unidad == 'string' ? data_component.unidad :
                    this.getUnidad(data_component.unidad,option)

      let tipo_unidad =   typeof data_component.tipo_unidad == 'string' ? data_component.tipo_unidad :
                          data_component.tipo_unidad.find(e => e == option)


      let data = data_component.data_result
      let data_by_option = this.getDataByOption(data,option)
      let data_chart = {};


      
      data_chart = this.getDataChart_1(data_by_option,data_names)
      data_chart['categories'] = data[category_name]
      

      data_chart['name'] = data_component['name']
      data_chart['unidad'] = unidad
      data_chart['tipo_unidad'] = tipo_unidad
      
      return data_chart
    }
  }
}
</script>

<style>

.next_to {
  margin-left: -30px;
}



@media screen and (max-width: 1500px) {
  .next_to {
    margin-left: -45px
  }
}
</style>