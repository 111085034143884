<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col>
          <info-card-simple 
            :icon="'mdi-information'"
            :titulo="tipo_data"
            :subtitulo="'Total'"
            :valor="data_total"
            :tipo_valor="unidad_data"

          />
        </v-col>
        <v-col>
          <info-card-simple
            :icon="'mdi-information'"
            :titulo="tipo_data"
            :subtitulo="'Tramo: '+selected_filtro_4+' M2'"
            :valor="data_tramo"
            :tipo_valor="unidad_data"
          />
        </v-col>
        <v-col>
          <info-card-simple
            :icon="'mdi-information'"
            :titulo="tipo_data"
            :subtitulo="'Tramo máximo'"
            :valor="data_tramo_max"
            :tipo_valor="'Metros Cuadrados (M2)'"
          />
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px;">
        <v-col cols=6>
          <info-card-pie-chart 
            :icon="'mdi-chart-pie'"
            :titulo="tipo_data+' por tramo (M2)'"
            :subtitulo="region_detail.nombre"
            :data="data_pie_chart"
            :width_chart="size_pie_chart.width_chart"
            :height_card="size_pie_chart.height_card"
          />
        </v-col>
        <v-col cols=6>
         
          <info-card-ranking-chart 
            :icon="'mdi-format-list-bulleted'"
            :titulo="'Ranking Regional - '+tipo_data"
            :subtitulo="'Tramo: '+selected_filtro_4+' M2'"
            :height_card="size_ranking_chart.height_card"
            :height_chart="size_ranking_chart.height_chart"
            :data="data_ranking_chart"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardPieChart from '../../components/infoCardPieChart.vue';
import InfoCardRankingChart from '../../components/infoCardRankingChart.vue';

export default {
  components: { HeaderComponent, InfoCardSimple, InfoCardPieChart, InfoCardRankingChart },
  name: 'View4Region',
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      /* Mes */
      selected_filtro_1: this.data_view.filtros[0].data[0],
      /* Año */
      selected_filtro_2: this.data_view.filtros[1].data[0],
      /* Tipo */
      selected_filtro_3: this.data_view.filtros[2].data[0],
      /* Tramo */
      selected_filtro_4: this.data_view.filtros[3].data[0],
      data_total: null,
      data_tramo:null,
      data_tramo_max:null,
      tipo_data:null,
      unidad_data: null,
      data_pie_chart: null,
      data_ranking_chart:null

    }
  },
  created () {
    this.get_data_region(this.region_selected, this.selected_filtro_2, this.selected_filtro_1)
    this.get_data_regiones(this.selected_filtro_2, this.selected_filtro_1)
  },
  computed: {
    size_pie_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if(size_screen == 'xl'){
        return {
            width_chart: 650,
            height_card: 600
        } 
      }
      else {
        return {
            width_chart: 400,
            height_card: 410
        } 
      }
    },

    size_ranking_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen == 'xl') {
        return {
          height_card: 600,
          height_chart: 530
        }
      }
      else {
        return {
          height_card: 410,
          height_chart: 345
        }
      }
    }
  },
  watch: {
    region_selected () {
      this.get_data_region(this.region_selected, this.selected_filtro_2, this.selected_filtro_1)
    }
  },
  methods: {

    async get_data_region(region,anio, mes) {
      let url = this.data_view.url_api[0];
      let url_api = API_URL+url+'?region='+region+'&anio='+anio+'&mes='+mes
      let response = await axios.get(url_api)
      let data_region = response.data.results[0]

      let data_total, data_tramo, tipo_data, unidad,tipo_total
      let data_tramos

      let id_tipo = this.selected_filtro_3.id

      let tramos = [
        '1-35',
        '36-50',
        '51-70',
        '71-100',
        '101-140',
        '140+'
      ]

      data_tramos = [
        data_region[id_tipo+'1_35'],
        data_region[id_tipo+'36_50'],
        data_region[id_tipo+'51_70'],
        data_region[id_tipo+'71_100'],
        data_region[id_tipo+'101_140'],
        data_region[id_tipo+'140_mas']
      ]
      
      tipo_total = id_tipo+'total'
      data_total = data_region[tipo_total]
      tipo_data = this.selected_filtro_3.data
      unidad = this.selected_filtro_3.unidad

      this.data_total = formatNumber(data_total)
      this.tipo_data = tipo_data
      this.unidad_data = unidad

      data_tramo = this.get_data_tramo(data_region, this.selected_filtro_4, this.selected_filtro_3)
      this.data_tramo = formatNumber(data_tramo)

      this.data_tramo_max = this.get_data_tramo_max(data_tramos, tramos)

      let data_pie_chart = this.get_data_pie_chart(data_tramos, tramos, this.selected_filtro_4)
      data_pie_chart['unidad'] = unidad
      data_pie_chart['tipo_unidad'] = 'no_monto'    
      this.data_pie_chart = data_pie_chart
    },
    
    async get_data_regiones(anio,mes) {
      let url = this.data_view.url_api[0];
      let url_api = API_URL+url+'?anio='+anio+'&mes='+mes
      let response = await axios.get(url_api)
      let data_regiones = response.data.results
      
      this.data_ranking_chart = this.get_data_ranking_chart(data_regiones, this.selected_filtro_3, this.selected_filtro_4)
      

    },

    get_data_ranking_chart(data, tipo, tramo) {
      
      let tramos = {
        '1-35': '1_35',
        '36-50': '36_50',
        '51-70': '51_70',
        '71-100': '71_100',
        '101-140': '101_140',
        '140+': '140_mas' 
      }

      let tramo_select = tramos[tramo]  
      let dato_tramo = tipo.text === 'Superficie' ? tipo.id+tramo_select : tipo.id+tramo_select
      let unidad = tipo.text === 'Superficie' ?  tipo.unidad : tipo.unidad

      let series = []
      let categories = []
      let colors = []

      this.order_by_dato(data,dato_tramo)
      
      for (let element of data) {
        if(!categories.includes(element.region.nombre)) {
          categories.push(element.region.nombre)
          colors.push(element.region.color)
        }
        series.push(element[dato_tramo])
      }

      return {
        series: series,
        categories: categories,
        colors: colors,
        unidad: unidad,
        tipo_unidad: 'no_monto',
        nombre_data: tipo.text
      }
    },

    order_by_dato(data, dato) {
      data.sort(function (a,b) {
        return b[dato] - a[dato]
      });
    },

    get_data_pie_chart(data_tramos, tramos, tramo) {
      let colors = []
      for (let t of tramos) {
        if (t === tramo){
          colors.push('#4682B4')
        }
        else {
          colors.push('#89CFF0')
        }
      }
      
      return {
        series: data_tramos,
        categories: tramos,
        colors: colors,
      }
    },

    get_data_tramo_max(data_tramos,tramos) {
      
      let valor_maximo = data_tramos.reduce(function(a, b) {
        return Math.max(a, b);
      }, 0);

      let index_maximo = data_tramos.indexOf(valor_maximo)

      return tramos[index_maximo]
    },

    get_data_tramo(data, tramo, tipo) {

      let data_result;
      if (tramo === '1-35') {
        data_result = tipo === 'Superficie' ? data['s_1_35'] : data['u_1_35']
      }

      else if (tramo === '36-50') {
        data_result = tipo === 'Superficie' ? data['s_36_50'] : data['u_36_50']
      }

      else if (tramo === '51-70') {
        data_result = tipo === 'Superficie' ? data['s_51_70'] : data['u_51_70']
      }

      else if (tramo === '71-100') {
        data_result = tipo === 'Superficie' ? data['s_71_100'] : data['u_71_100']
      }

      else if (tramo === '101-140') {
        data_result = tipo === 'Superficie' ? data['s_101_140'] : data['u_101_140']
      }

      else if (tramo === '140+') {
        data_result = tipo === 'Superficie' ? data['s_140_mas'] : data['u_140_mas']
      }
      
      return data_result
    },

    updateFiltro(newValue, indexFiltro) {

      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
      }

      else if (indexFiltro == 1) {
        this.selected_filtro_2 = newValue
      }

      else if (indexFiltro == 2) {
        this.selected_filtro_3 = newValue
      }

      else if (indexFiltro == 3) {
        this.selected_filtro_4 = newValue
      }

      /* Funciones a actualizar */
      this.get_data_region(this.region_selected, this.selected_filtro_2, this.selected_filtro_1)
      this.get_data_regiones(this.selected_filtro_2, this.selected_filtro_1)
      
    }
  }
}
</script>

<style>

</style>