<template>
  <div id="loading" v-if="loading">
    <loading/>
  </div>
  <div id="dashboard" v-else>
    <v-app>
      <div id="header">
      <v-app-bar app dark 
        color="#0277BD"
        elevation="0"
        clipped-left
      >
       <div class="d-flex align-center centrar img_datari" >
          <v-img
            alt="labict logo"
            class="shrink "
            contain
            src="@/media/logo_blanco.png"
            transition="scale-transition"
            width="140"
            height ="40"
          />
        </div>
      <v-row class="centrar filtros_datari">
        <v-col  xl=2 md=3>
          <v-autocomplete
            dense
            v-model="region_selected"
            :items="regiones"
            flat
            hide-no-data
            hide-details
            label="Regiones"
            solo-inverted
            item-text="nombre"
            item-value="id"
          ></v-autocomplete>
        </v-col>
        <v-col  xl=2 md=3>
          <v-autocomplete
            dense
            v-model="comuna_selected"
            :items="comunas_region"
            flat
            hide-no-data
            hide-details
            label="Comunas"
            solo-inverted
            item-text="nombre"
            item-value="id"
            :disabled="no_comunas"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <div id="user_info" class="user_info">
        <v-icon>mdi-account</v-icon>
          <span class="ml-1 mr-2" v-if="user_type === 'test' || user_type === 'demo'">{{user_email}}</span>
          <span class="ml-1 mr-2" v-else>{{user_name}}</span>
        <v-btn
          @click="logout"
          v-if="isLoggedIn"
          text
        > 
          <v-icon>mdi-power-cycle</v-icon>
        </v-btn>
      </div>
      
      </v-app-bar>
      </div>
      <div id="menu" > 
      <v-navigation-drawer 
        floating
        app
        width="260"
        color="#0288D1"
        dark
        permanent
        clipped
      >
       
        <v-list nav>
          <v-list-item
            link :to="{ path: '/'}"
          >
            <v-list-item-icon><v-icon>mdi-home-outline</v-icon></v-list-item-icon>
            <v-list-item-title class="text-left subtitle-1 font-weight-medium next_to_logo">Home</v-list-item-title>
          </v-list-item>
        
         <template v-for="categoria in categorias">
            <v-list-group 
              class="my-2 "
              :key="categoria.id" 
              color="white"
              :value="categoria.id == currentURL ? true : false"
            >
              <template v-slot:activator >
                <v-list-item-icon><v-icon>{{categoria.icon}}</v-icon></v-list-item-icon>
                <v-list-item-title class="text-left subtitle-1 font-weight-medium next_to_logo">{{categoria.nombre}}</v-list-item-title>
              </template>
              <!-- Se deshabilita el acceso a una vista si usuario es tipo test o demo -->
              <v-list-item
                dense
                v-for="child_categoria in categoria.fuenteDato"  
                class="px-2"
                :key="child_categoria.nombre"
                link :to="{path: '/categoria-'+categoria.id+'/'+child_categoria.url}"
                :disabled="user_type === 'test' && !views_availables_test_demo.includes(child_categoria.url) ? 
                           true :
                           user_type === 'demo' && !views_availables_test_demo.includes(child_categoria.url) ?
                           true :
                           false"
              >
                <v-list-item-icon><v-icon>mdi-view-dashboard</v-icon></v-list-item-icon>
                <v-list-item-title class="text-left subtitle-3 font-weight-medium next_to_logo_child">{{child_categoria.nombre}}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </template>
           <v-list-item class="px-1" color="blue darken-4" link :to="{ path: '/admin_dashboard'}"  v-if="is_staff === 'true'">
           <v-list-item-icon><v-icon>mdi-account-cog-outline</v-icon></v-list-item-icon>
            <v-list-item-title class="text-left subtitle-1 font-weight-medium next_to_logo">Admin</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      </div>
      <v-main
        style="background-color:#E0E0E0"
      >
      <v-container fluid transition="slide-x-transition">
        <router-view 
          :region_selected="region_selected"
          :region_detail="region_detail"
          :regiones="regiones"
          :comunas_region="comunas_region"
          :comuna_selected="comuna_selected"
          :comuna_detail="comuna_detail"
          :no_comunas="no_comunas"
          @update="updateComuna"
          :categorias="categorias"
          :user_type="user_type"
          :vistas_disponibles_test="views_availables_test_demo"
        />
      </v-container>
      </v-main>
      <v-footer dark app class="centrar">
         <div class="d-flex">
          <v-img
            alt="labict logo"
            class="shrink "
            contain
            src="@/media/logo_lab.png"
            transition="scale-transition"
            width="15"
            height ="21"
          />
        </div>
        <span class="white--text ml-2"><small>LAB-ICT &copy; {{ new Date().getFullYear() }}</small> </span>
      </v-footer>
    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src
import {sleep, ordenarArrayASCbyID} from '@/functions/functions.js'
import Loading from './Loading.vue';

export default {
  name: 'Dashboard',
  components: {
    Loading
  },
  data: () => ({
    //Cambiar a true
    loading:true,
    region_selected: 1,
    comuna_selected: 0,
    //Cambiar a false cuando haya vista ComunaGeneral
    no_comunas: false,
    views_availables_test_demo: [
      'data_exportacion_aduana',
      'data_generacion_instalada_regional',
      'data_exportacion_agricola_regional',
      'data_avaluo_predios_agricolas_noagricolas_regional',
      'data_pobreza_ingreso',
      'data_gasto_id_regional'
    ]
  }),
  created(){
    //this.token_expires_in
    

    this.$store.dispatch('getRegiones')
    this.$store.dispatch('getComunas');
    this.$store.dispatch('getCategorias');

    sleep(2000).then(() => {
      this.loading = false;
    })

    const tiempo_conexion = this.token_expires_in
    console.log(tiempo_conexion)
    setTimeout(() => {
      this.logout()
		},tiempo_conexion*1000)
  },
  computed: {
    token_expires_in () { return localStorage.getItem('token_expires_in')},
    isLoggedIn: function(){ return this.$store.getters.isLoggedIn },
    user_name: function(){ return localStorage.getItem('user_name')},
    user_type: function(){ return localStorage.getItem('user_type')},
    user_email: function(){ return localStorage.getItem('user_email')},
    is_staff: function(){ return localStorage.getItem('is_staff')},
    currentURL: function() {
      let url = window.location.pathname
      let temp_url = url.split('/')
      let categoria = temp_url[1].split('-')
      let id_categoria = categoria[1]
      return id_categoria
    },
    regiones: function() { return this.$store.getters.regiones},
    comunas: function() { return this.$store.getters.comunas},
    region_detail: function() {
      let data = this.getRegion(this.region_selected, this.regiones) 
      if(data === undefined) {
        this.logout()
      }
      return data
    },
    comunas_region: function() {
      return this.getComunasRegion(this.region_selected, this.comunas)
    },
    comuna_detail: function() {
      return this.getComuna(this.comuna_selected, this.comunas)
    },
    categorias: function() { return this.$store.getters.categorias}
  },
  methods:{
    logout(){
      this.loading = true
      this.$store.dispatch('logout')
      .then(() => {
        sleep(2500).then(() => {  
          location.reload()
        })
      })
    },

    getRegion(id_region,regiones){
      this.comuna_selected = 0;
      let data_region = regiones.find( r => r.id == id_region)
      return data_region;
    },
  
    getComunasRegion(id_region, comunas){
      let data_comunas_region = comunas.filter( c => c.region.id == id_region)
      data_comunas_region.push({id: '0',nombre: 'Comunas'})
      
      return ordenarArrayASCbyID(data_comunas_region)
    },

    getComuna(id_comuna,comunas){
      if(id_comuna == 0){
        // console.log('sin seleccion de comuna')
        return null
      }
      else{
        let data_comuna = comunas.find( c => c.id == id_comuna)
        return data_comuna;
      } 
    },
    
    updateComuna(no_comunas, id_comuna){
      //no_comunas = false | true
      this.no_comunas = no_comunas
      this.comuna_selected = id_comuna
    }

    
  }
}
</script>


<style>
.centrar {
  display: flex;
  align-items: center;
  justify-content: center
}
.derecha {
  display: flex;
  align-items: right;
  justify-content: right;
}

.user_info {
  margin-left: -10%;
}
</style>

<style>
.next_to_logo {
  margin-left: -15px;
}

.next_to_logo_child {
  margin-left: -25px;
}

.menu {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; 
}

.img_datari{
  margin-left: 40px;
}

.filtros_datari {
  margin-left: -6%;
}

/* .menu ::-webkit-scrollbar {
    display: none;
}

.v-navigation-drawer__content::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px #5d5d5d;
  box-shadow: inset 0 0 6px #5d5d5d;
  background-color: #5d5d5d;
}
.v-navigation-drawer__content::-webkit-scrollbar{
  width: 0px;
}
.v-navigation-drawer__content::-webkit-scrollbar-thumb{
  -webkit-box-shadow: inset 0 0 6px #424242;
  
  background-color: #424249;
} */



</style>
