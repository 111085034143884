<template>
  <div>
    <v-card flat :height="height_card">
      <v-card-title>
        <v-icon
        medium
        left
        >
        {{icon}}
        </v-icon>
        <h4 class="font-weight-medium">{{titulo}}</h4>  
      </v-card-title>
      <v-card-subtitle class="text-left">
          <h3 class="font-weight-medium">{{subtitulo}}</h3>
      </v-card-subtitle>
      <v-card-text style="margin-top:-10px;">
        <div v-if="data != null">
          <pie-chart
            :data_chart="data_chart"
            :labels_chart="labels_chart"
            :colors_chart="colors_chart"
            :legend_chart="legend_chart"
            :width_chart="width_chart"
            :height_chart="height_chart"
            :unidad_chart="unidad_chart"
            :tipo_unidad_chart="tipo_unidad_chart"
          />
        </div>
        <div v-else>
            Sin Datos
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PieChart from './charts/PieChart.vue'
export default {
  components: { PieChart },
  name:'infoCardPieChart',
  props:[
    'icon', 'titulo','subtitulo','data',
    'width_chart','height_chart','height_card'
  ],
  computed:{
    data_chart () {
      return this.data.series
    },
    labels_chart () {
      return this.data.categories
    },
    colors_chart () {
      return this.data.colors
    },
    legend_chart () {
      if( this.data.series.length <= 5){
        return 'bottom'
      }
      else {
        return 'bottom'
      }
    },
    unidad_chart () {
      return this.data.unidad
    },

    tipo_unidad_chart () {
      return this.data.tipo_unidad
    }
    
  }
}
</script>

<style>

</style>