
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function ordenarArrayASCbyID(array){
   return array.sort((a,b) => a.id - b.id)
}

function formatRegion(region){
    let tipo;
    let final;
    if(region === 'Metropolitana'){
      tipo = 'Región ';
    }else if(region === 'Biobío' || region === 'Maule' ){
      tipo = 'Región del '
    }else{
      tipo = 'Región de '
    }
    final = tipo+region;

    return final;
}

function formatNumber(val){
    let valor = val.toLocaleString();
    var i;
    var stringFormat = ''; 
    for(i of valor){
      if( i === '.' ){
        i = ',';
        stringFormat+=i;
      }
      else if(i ===','){
        i = '.';
        stringFormat+=i;
      }
      else{
        stringFormat+=i;
      }
    }
    return stringFormat;
}

function getZoomRegion(codigo_region){
        
  const zoom_regiones = {
    1: 5.5,
    2:5.5,
    3:5.5,
    4:6,
    5:6.5,
    6:6.5,
    7:6.5,
    8:6.5,
    9:6,
    10:5.5,
    11:5,
    12:5,
    13:7,
    14:6.5,
    15: 6.5,
    16: 7
  }

  let codigo = parseInt(codigo_region);

  let zoom =  zoom_regiones[codigo];

  return zoom;
}

function formatNumeroRegion(codigo_region){
  let codigo;
  var tipo = String(codigo_region).length;
  if(tipo == 1){
    if(codigo_region == 8){
      codigo = '08';
    }
    else{
      codigo = '0000'+String(codigo_region);
    }
  }
  else if(tipo == 2){
    if(codigo_region == 16){
      codigo = '16';
    }
    else{
      codigo = '000'+String(codigo_region)
    }
  }

  return codigo;
}

export {
    sleep,
    ordenarArrayASCbyID,
    formatRegion,
    formatNumber,
    formatNumeroRegion,
    getZoomRegion
}