<template>
  <div>
    <div id="header">
      <header-component
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col xl=2 sm=3>
          <info-card-simple 
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Uso de internet'"
            :valor="data_region_value"
            :tipo_valor="selected_filtro_2.text"
          />
          <info-card-simple 
            class="mt-2"
            :icon="'mdi-information'"
            :titulo="'País'"
            :subtitulo="'Uso de internet'"
            :valor="data_pais_value"
            :tipo_valor="selected_filtro_2.text"
          />
        </v-col>
        <v-col xl=5 sm=4>
          <info-card-line-chart 
            :subtitulo="'Uso de Internet - '+region_detail.nombre"
            :titulo="selected_filtro_2.text"
            :icon="'mdi-trending-up'"
            :data="data_line_chart"
            :height_chart="size_line_chart.height_chart"
            :height_card="size_line_chart.height_card"
          />
        </v-col>
        <v-col xl=5 sm=5>
          <info-card-column-chart 
            :icon="'mdi-information'"
            :subtitulo="'Uso de Internet - País'"
            :titulo="selected_filtro_2.text"
            :height_chart="size_column_chart.height_chart"
            :height_card="size_column_chart.height_card"
            :data="data_column_chart"
          />
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px;">
        <v-col>
          <info-card-tree-map 
            :icon="'mdi-chart-tree'"
            :titulo="'Superficies Regionales'"
            :height_card="size_tree_map.height_card"
            :height_chart="size_tree_map.height_chart"
            :data="data_tree_map"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import HeaderComponent from '../../components/HeaderComponent.vue'
import InfoCardSimple from '../../components/infoCardSimple.vue'

import axios from 'axios';
import InfoCardLineChart from '../../components/infoCardLineChart.vue';
import InfoCardColumnChart from '../../components/infoCardColumnChart.vue';
import InfoCardTreeMap from '../../components/infoCardTreeMap.vue';
const API_URL = 'https://api.datari.net/datari/data/'

export default {
  components: { HeaderComponent, InfoCardSimple, InfoCardLineChart, InfoCardColumnChart,InfoCardTreeMap },
  name: 'View1Region',
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      selected_filtro_2: this.data_view.filtros[1].data[0],
      data_region:null,
      anios:null,
      data_region_value:null,
      data_pais:null,
      data_pais_value:null,
      data_regiones: null,
      data_line_chart: null,
      data_column_chart: null,
      data_tree_map:null
    }
  },
  created(){
    this.get_data_region(this.region_selected, this.selected_filtro_2)
    this.get_data_pais(this.selected_filtro_2);
    this.get_data_regiones(this.selected_filtro_2)
  },
  computed:{
    urls_api () {
      return this.data_view.url_api
    },
    id_api () {
      return this.selected_filtro_2.id
    },
    size_tree_map () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl') {
        return {
          height_chart: 385,
          height_card: 440
        }
      }
      else {
        return {
          height_chart: 210,
          height_card: 260
        }
      }
    },

    size_line_chart () {
       const size_screen  = this.$vuetify.breakpoint.name;
       if( size_screen == 'xl' ){
          return {
            height_chart:  250,
            height_card:326
          }
        }
        else{
          return {
            height_chart:230,
            height_card:304
          }
        }
    },
    size_column_chart () {
       const size_screen  = this.$vuetify.breakpoint.name;
       if( size_screen === 'xl' ){
          return {
            height_chart:255,
            height_card:326
          }
        }
        else{
          return {
            height_chart:235,
            height_card:304
          }
        }
    }
  },
  watch:{
    region_selected () {
      this.get_data_region(this.region_selected, this.selected_filtro_2)
      let index_anio = this.anios.indexOf(this.selected_filtro_1)
      this.data_tree_map = this.get_data_tree_map(this.data_regiones, index_anio)
    }
  },
  methods:{

    async get_data_region(region, tipo){
      let url_api = this.select_url_api(this.data_view.url_api,tipo.id)
      let response = await axios.get(API_URL+url_api+'?region='+region)
      let data = response.data.results[0]

      this.anios = data.anios
      let data_anios = data.data_anios
      this.data_region = data_anios
      let index_anio = this.anios.indexOf(this.selected_filtro_1)
      this.data_region_value = this.data_region[index_anio]+'%'

      this.data_line_chart = {
        series: [
          {
            name: this.selected_filtro_2.text,
            data: this.data_region
          }
        ],
        categories: this.anios,
        colors: ['#009cca'],
        unidad: 'Uso de Internet',
        tipo_unidad: 'porcentaje'
      }
    },

    async get_data_pais(tipo) {
      let url_api = this.select_url_api(this.data_view.url_api,tipo.id)
      let response = await axios.get(API_URL+url_api+'?region=18')
      let data = response.data.results[0]

      this.anios = data.anios
      let data_anios = data.data_anios
      this.data_pais = data_anios
      let index_anio = this.anios.indexOf(this.selected_filtro_1)
      this.data_pais_value = this.data_pais[index_anio]+'%'

      this.data_column_chart = {
        series: [
          {
            name: this.selected_filtro_2.text,
            data: this.data_pais
          }
        ],
        categories: this.anios,
        colors: ['#988558'],
        unidad: 'Uso de Internet',
        tipo_unidad: 'porcentaje',
        tipo_categoria: 'anio'
      }
    },

    async get_data_regiones(tipo) {
      let url_api = this.select_url_api(this.data_view.url_api,tipo.id)
      let response = await axios.get(API_URL+url_api)
      let data = response.data.results
      /* Excluye el ultimo dato ya que es nacional */
      data = data.filter(e => e.region.id != 18)

      this.data_regiones = data
      let index_anio = this.anios.indexOf(this.selected_filtro_1)
      this.data_tree_map = this.get_data_tree_map(data, index_anio)
    },

    get_data_tree_map(data, index) {
      let series = []
      let colors = []
      

      for (let element of data) {
        let region = element.region.nombre
        let valor = element.data_anios[index]

        let data_element = {
          x: region,
          y: valor
        }
        
        series.push(data_element)
      }

      this.order_by_valor(series)

      for (let e of series){
        if (e.x === this.region_detail.nombre) {
          colors.push('#4682B4')
        }
        else {
          colors.push('#89CFF0')
        }
      }

      return {
        data: series,
        colors: colors,
        nombre_data: this.selected_filtro_2.text,
        unidad: 'Uso de Internet',
        tipo_unidad: 'porcentaje',
        distributed: true
      }
    },

    order_by_valor(data){
      data.sort(function (a,b) {
        return b.y - a.y
      });
    },

    select_url_api(urls,id){
      return urls[id]
    },
  
    updateFiltro (newValue, indexFiltro) {
      if( indexFiltro == 0){
        this.selected_filtro_1 = newValue
        let index_anio = this.anios.indexOf(this.selected_filtro_1)
        this.data_region_value = this.data_region[index_anio]+'%'
        this.data_pais_value = this.data_pais[index_anio]+'%'
        this.data_tree_map = this.get_data_tree_map(this.data_regiones, index_anio)
      }
      else if( indexFiltro == 1 ) {
        this.selected_filtro_2 = newValue
        this.get_data_region(this.region_selected, this.selected_filtro_2)
        this.get_data_pais(this.selected_filtro_2);
        this.get_data_regiones(this.selected_filtro_2)
      }
    }
  }
}
</script>
