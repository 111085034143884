<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col>
          <info-card-simple
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Capacidad'"
            :valor="data_region"
            :tipo_valor="tipo_dato"
          />
        </v-col>
        <v-col>
          <info-card-simple-bar 
            :icon="'mdi-information'"
            :titulo="'Participación Regional'"
            :subtitulo="'Capacidad a nivel país'"
            :valor="porcentaje_region"
            :nombre_valor="region_detail.nombre"
          />
        </v-col>
        <v-col>
          <info-card-simple
            :icon="'mdi-information'"
            :titulo="'País'"
            :subtitulo="'Capacidad'"
            :valor="data_pais"
            :tipo_valor="tipo_dato"
          />
        </v-col>
        
      </v-row>
      <v-row style="margin-top:-15px;">
        <v-col>
          <info-card-pie-chart 
            :icon="'mdi-chart-pie'"
            :titulo="data_view.nombre_data"
            :data="data_pie_chart"
            :width_chart="size_pie_chart.width_chart"
            :height_card="size_pie_chart.height_card"
          />
        </v-col>
        <v-col>
          <info-card-ranking-chart
            :icon="'mdi-format-list-bulleted'"
            :titulo="'Ranking Nacional'"
            :subtitulo="'Capacidad'"
            :data="data_ranking_chart"
            :height_card="height_ranking_chart.height_card"
            :height_chart="height_ranking_chart.height_chart"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardSimpleBar from '../../components/infoCardSimpleBar.vue';
import InfoCardPieChart from '../../components/infoCardPieChart.vue';
import InfoCardRankingChart from '../../components/infoCardRankingChart.vue';

export default {
  components: { HeaderComponent, InfoCardSimple, InfoCardSimpleBar, InfoCardPieChart, InfoCardRankingChart },
  name: 'View2Region',
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      data_pais: null,
      data_region:null,
      porcentaje_region:null,
      tipo_dato: null,
      data_pie_chart:null,
      data_ranking_chart: null 
    }
  },
  computed: {
    size_pie_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if(size_screen == 'xl'){
        return {
          height_card: 600,
          width_chart: 680
        }
      }
      else {
        return {
          height_card: 404,
          width_chart: 440
        }
      }
    },

    height_ranking_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen == 'xl') {
        return {
          height_card: 600,
          height_chart: 530
        }
      }
      else {
        return {
          height_card: 404,
          height_chart: 345
        }
      }
    }
  },
  created() {
    this.get_data_region(this.region_selected, this.selected_filtro_1);
    this.get_data_regiones(this.selected_filtro_1);
  },
  watch: {
    region_selected () {
      this.get_data_region(this.region_selected, this.selected_filtro_1);
    },

    data_view () {
      this.selected_filtro_1 = this.data_view.filtros[0].data[0];
      this.get_data_region(this.region_selected, this.selected_filtro_1);
      this.get_data_regiones(this.selected_filtro_1);
    }
  },
  methods: {

    async get_data_region(region, anio) {
      let url = this.data_view.url_api[0];
      let url_api = API_URL+url+'?region='+region+'&anio='+anio;
      let response = await axios.get(url_api)
      let data_region = response.data.results[0]
      let data_pais = response.data.totalAnual

      this.data_pais = formatNumber(data_pais)
      this.data_region = formatNumber(data_region.valor_mw)
      this.porcentaje_region = data_region.participacion
      this.tipo_dato = data_region.tipo_dato
    },

    async get_data_regiones(anio){
      let url = this.data_view.url_api[0];
      let url_api = API_URL+url+'?anio='+anio;
      let response = await axios.get(url_api)
      let data = response.data.results;
      let unidad = data[0].tipo_dato
      let tipo_unidad = 'no_monto'
      let data_pie_chart = this.get_data_grafico(data);
      this.order_by_ranking(data)
      let data_ranking_chart = this.get_data_grafico(data);

      data_pie_chart['unidad'] = unidad
      data_pie_chart['tipo_unidad'] = tipo_unidad

      data_ranking_chart['unidad'] = unidad
      data_ranking_chart['tipo_unidad'] = tipo_unidad
      data_ranking_chart['nombre_data'] = 'Capacidad'
    
      this.data_pie_chart = data_pie_chart;
      this.data_ranking_chart = data_ranking_chart;
    },

    order_by_ranking(data) {
      data.sort(function (a,b) {
        return a.rank - b.rank
      });
    },

    get_data_grafico(data) {
      let series = []
      let categories = []
      let colors = []

      for (let element of data) {
        if (!categories.includes(element.region.nombre)) {
          categories.push(element.region.nombre);
          colors.push(element.region.color);
        }
        series.push(element.valor_mw)
      }

      return {
        series: series,
        categories: categories,
        colors: colors
      }

    },

    updateFiltro(newValue,indexFiltro) {
      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
      }
    }
  }
}
</script>