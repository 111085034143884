<template>
  <div>
    <div v-if="data_url.id_vista_comuna == 1">
      <view-1 
        :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url"
        :comuna_selected="comuna_selected" :comuna_detail="comuna_detail"
      />
    </div>
    <div v-else-if="data_url.id_vista_comuna == 2">
      <view-2 
        :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url"
        :comuna_selected="comuna_selected" :comuna_detail="comuna_detail"
      />
    </div>
  </div>
</template>

<script>
import View1 from '../ComunaViews/View1.vue'
import View2 from '../ComunaViews/View2.vue'
export default {
  components: { View1, View2 },
  name: 'SelectViewComuna',
  props: [
    'data_url',
    'region_selected',
    'region_detail',
    'comuna_selected',
    'comuna_detail'
  ]
}
</script>

<style>

</style>