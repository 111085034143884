<template>
  <div id="chart" >
     <apexchart type="bar" :height="height_chart" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<style >
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;   
}
</style>


<script>
export default {
  name: 'ColumnChart',
  props: [
    'data_chart','categories_chart', 'colors_chart',
    'tipo_unidad_chart','unidad_chart','nombre_chart',
    'height_chart','tipo_categoria_chart'
  ],
  computed:{
    series () {
      return this.data_chart
    },

    chartOptions () {
      return {
        chart: {
          id:'Column',
          type: 'bar',
          toolbar:{
            show:false
          },
        },
        unidad: this.unidad_chart,
        tipo_unidad: this.tipo_unidad_chart,
        tipo_categoria: this.tipo_categoria_chart,
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '50%',
            borderRadius: 5,
            distributed: this.tipo_categoria_chart === 'regiones' ? true : false
          },
        },
        dataLabels: {
          enabled: false
        },
        responsive: [{
          breakpoint: 1400,
          options: {
            xaxis: {
              labels: {
                rotate: -51,
                style: {
                  colors: [],
                  fontSize: '10.5px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 500, 
                  cssClass: 'apexcharts-xaxis-label',
                },
              },
            }
          }
        }],
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.categories_chart
        },
        yaxis: {
          title: {
            text: this.unidad_chart
          },
          labels: {
            show: false
          },
        },
        colors: this.colors_chart,
        fill: {
          opacity: 1
        },
        legend:{
          show: this.tipo_categoria_chart === 'regiones' ? false : true
        },
        tooltip: {
          theme: 'dark',
          custom: function({series, seriesIndex,dataPointIndex, w}) {
            const formatNumber = (valor) => {
              let valor_format = valor.toLocaleString();
              let i;
              let result = '';

              for( i of valor_format ){
                if( i == '.') {
                  result = result + ','
                }

                else if( i == ',' ) {
                  result = result + '.'
                }

                else {
                  result = result + i
                }
              }
              return result
            };

            const get_variacion = (value, old_value) => {
              let result = (value/old_value)*100
              let variacion = (result-100).toFixed(2)
              return variacion;
            };

            let value = series[seriesIndex][dataPointIndex]
            let nombre_data = w.globals.seriesNames[seriesIndex]
            let unidad = w.config.unidad
            let tipo_unidad = w.config.tipo_unidad
            let tipo_categoria = w.config.tipo_categoria
            let value_formatted = formatNumber(value)
            let label = w.globals.labels[dataPointIndex];
            let label_html = Array.isArray(label) ? label[0] : label;


            let total = series[seriesIndex].reduce((a, b) => { return a + b}, 0);
            let porcentaje = ((value/total)*100).toFixed(1);

            let text_value = tipo_unidad == 'monto' ? '$'+value_formatted : 
                              tipo_unidad == 'porcentaje' ? value_formatted+'%':
                              value_formatted

            let porcentaje_html_text = '<div class="body-2 ml-3 mt-2 text-left">Porcentaje:</div>'+
                                        '<div class="subtitle-1 font-weight-bold ml-3 text-left">'+porcentaje+'%</div>' 

            /* Variación */
            let old_value = dataPointIndex > 0 ? series[seriesIndex][dataPointIndex-1] : 0;
            let variacion = old_value > 0 ? get_variacion(value,old_value) : 0
            let old_label = dataPointIndex > 0 ? w.globals.labels[dataPointIndex-1]+'-' : '' 
          
            let text_variacion =  variacion > 0 ? variacion+' % <i class="mdi mdi-trending-up"></i>' :
                                  variacion < 0 ? variacion+' % <i class="mdi mdi-trending-down"></i>' : variacion+' %'

            let variacion_html_text = '<div class="body-2 ml-3 mt-2 text-left">'+old_label+label+'</div>' +
                                 '<div class="subtitle-1 font-weight-bold ml-3 text-left">'+text_variacion+'</div>' 




            let data_extra_html = tipo_categoria !== 'anio' && tipo_unidad !== 'porcentaje' ? porcentaje_html_text :
                                  tipo_categoria !== 'anio' && tipo_unidad === 'porcentaje' ? '' :
                                  variacion_html_text

            let tooltip_html ='<div class="arrow_box" style="width:200px;height:170px;">'+
                              '<div class="title ml-3  font-weight-medium text-left">' + label_html + '</div>' +
                              '<div class="body-2 ml-3 mt-3 text-left">'+nombre_data+'</div>' +
                              '<div class="subtitle-1 font-weight-bold ml-3 text-left">'+text_value+'</div>' +
                              '<div class="subtitle-2 ml-3 text-left" style="margin-top:-5px;">' +unidad  + '</div>' +
                              data_extra_html+
                              '</div>'
            
            return tooltip_html
          }
        }
      }
    }
  }
}
</script>
