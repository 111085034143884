<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col xl=3 sm=3>
          <info-card-simple 
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'PIB'"
            :valor="data_region"
            :tipo_valor="'Miles de Millones de Pesos'"
          />
          <info-card-simple 
            class="mt-2"
            :icon="'mdi-information'"
            :titulo="'País'"
            :subtitulo="'PIB'"
            :valor="data_pais"
            :tipo_valor="'Miles de Millones de Pesos'"
          />
        </v-col>
        <v-col xl=9 sm=9>
          <v-card  flat :height="size_line_chart.height_card">
              <v-card-title>
                <v-icon
                medium
                left
                >
                {{icon_line_chart}}
                </v-icon>
                <h4 class="font-weight-medium">{{titulo_line_chart}}</h4>  
              </v-card-title>
              <v-card-text style="margin-top:-15px;" class="">
                <div>
                  <h4 class="text-left">Comparar Regiones</h4>
                  <div class="mt-1 centrar" >
                    <v-slide-group
                      v-model="region_2_index"
                      dense
                      v-on:change="seleccionarRegion"
                    >
                      <v-slide-item
                        v-for="(region,i) in regiones"
                        :key="i"
                        v-slot="{ active, toggle }"
                      >
                      <div>
                         <v-btn v-if="region.id == region_selected"
                          class="ma-1"
                          small
                          depressed
                          disabled
                          
                        >
                          {{romanize(region.numero_region)}}
                        </v-btn>
                        <v-btn
                          v-else-if="region.numero_region != 16 "
                          class="ma-1"
                          small
                          :input-value="active"
                          :color="active ? 'blue darken-3 white--text' : 'grey lighten-2'"
                          depressed
                          @click="toggle"
                        >
                          {{romanize(region.numero_region)}}
                        </v-btn>
                      </div>
                      </v-slide-item>
                    </v-slide-group>
                  </div>
                  
                </div>
                <div style="margin-top:-10px;">
                  <line-chart 
                    v-if="categories_line_chart != null"
                    :data_chart="series_line_chart"
                    :categories_chart="categories_line_chart"
                    :colors_chart="colors_line_chart"
                    :nombre_chart="'PIB'"
                    :unidad_chart="'Miles de Millones de Pesos'"
                    :tipo_unidad_chart="'monto'"
                    :height_chart="size_line_chart.height_chart"
                  />
                </div>
              </v-card-text>
           </v-card>
        </v-col>
      </v-row>
      <v-row style="margin-top: -15px;">
        
         <v-col>
           <info-card-column-chart 
            :icon="'mdi-chart-bar'"
            :titulo="'Comparación Regional'"
            :height_chart="size_column_chart.height_chart"
            :height_card="size_column_chart.height_card"
            :data="data_column_chart"
          />
           
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style>
div.centrar {
    display: flex;
    align-items: center;
    justify-content: center
}
</style>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import {formatNumber, sleep} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardColumnChart from '../../components/infoCardColumnChart.vue';
import LineChart from '../../components/charts/LineChart.vue';

export default {
  name: 'View16Region',
  components: { HeaderComponent, InfoCardSimple, InfoCardColumnChart, LineChart},
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      data_region:null,
      data_pais:null,
      data_anual_regiones:null,
      data_column_chart:null,
      data_line_chart_region:[],
      region_2_index: null,
      regiones: this.data_view.filtro_regiones.data,
      series_line_chart:[],
      colors_line_chart: [ "#FDD835", "#7CB342" ] ,
      categories_line_chart:null,
      titulo_line_chart: 'PIB Anual',
      icon_line_chart: 'mdi-trending-up',
      subtitulo_line_chart:'2013 - 2019',
    }
  },
  created() { 
    this.get_data_region(this.region_selected, this.selected_filtro_1)
    this.get_data_regiones(this.selected_filtro_1)
    this.get_data_regiones_anual()

    sleep(1500).then(() =>{
      let data_anual_region_1 = this.data_anual_regiones.filter(e => e.region.id == this.region_selected)
      let data_line_chart_1 = this.get_data_line_chart(data_anual_region_1)
      this.series_line_chart = [data_line_chart_1.series[0]]
      this.categories_line_chart = data_line_chart_1.categories
    })
  },
  computed : {
    size_column_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 430,
          height_chart: 350
        }
      }
      else {
        return {
          height_card: 260,
          height_chart: 215
        }
      }
    },
    size_line_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 327,
          height_chart: 215
        }
      }
      else {
        return {
          height_card: 307,
          height_chart:200
        }
      }
    },
  },
  watch: {
    region_selected() {
      this.get_data_region(this.region_selected, this.selected_filtro_1)
      this.get_data_regiones(this.selected_filtro_1)
      
      if (this.region_2_index == null) {
        let data_anual_region_1 = this.data_anual_regiones.filter(e => e.region.id == this.region_selected)
        let data_line_chart_1 = this.get_data_line_chart(data_anual_region_1)
        this.series_line_chart = [data_line_chart_1.series[0]]
        this.categories_line_chart = data_line_chart_1.categories
      } 
      else {
        let region_2 = this.regiones[this.region_2_index]
        let id_region_2 = region_2.id

        let data_anual_region_1 = this.data_anual_regiones.filter(e => e.region.id == this.region_selected)
        let data_line_chart_1 = this.get_data_line_chart(data_anual_region_1)
        this.series_line_chart = [data_line_chart_1.series[0]]
  
        let data_anual_region_2 = this.data_anual_regiones.filter(e => e.region.id == id_region_2)
        let data_line_chart_2 = this.get_data_line_chart(data_anual_region_2)
        this.series_line_chart.push(data_line_chart_2.series[0])         
        
        this.categories_line_chart = data_line_chart_1.categories
      }
    }
  },
  methods: {

    async get_data_region(region,anio) {
      let url = this.data_view.url_api[0];
      let  url_api = API_URL+url+'?region='+region+'&anio='+anio
      let response = await axios.get(url_api)
      let data = response.data

      let data_region = data.results[0]
      let data_pais = data.totalAnual

      this.data_region = '$'+formatNumber(data_region.valor_pib)
      this.data_pais = '$'+formatNumber(data_pais)
    },

    async get_data_regiones(anio) {
      let url = this.data_view.url_api[0];
      let  url_api = API_URL+url+'?anio='+anio
      let response = await axios.get(url_api)
      let data = response.data

      let data_regiones = data.results
      this.data_column_chart = this.get_data_column_chart(data_regiones)
    },

    async get_data_regiones_anual() {
      let url = this.data_view.url_api[0];
      let url_api = API_URL+url
      let response = await axios.get(url_api)
      let data = response.data.results
      this.data_anual_regiones = data
      
      // let data_line_chart = this.get_data_line_chart(data)
      // this.data_line_chart_region.push(data_line_chart)
    },

    romanize (num) {
      if (isNaN(num))
        return NaN;
    
      var digits = String(+num).split(""),
          key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
                "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
                "","I","II","III","IV","V","VI","VII","VIII","IX"],
          roman = "",
          i = 3;
      while (i--)
          roman = (key[+digits.pop() + (i * 10)] || "") + roman;
      return Array(+digits.join("") + 1).join("M") + roman;
    },

    get_data_line_chart(data) {
      let data_chart = []
      let categories = []

      this.order_by_anio(data)

      for (let element of data) {
        if (!categories.includes(element.anio)) {
          categories.push(element.anio)
          data_chart.push(element.valor_pib)
        }        
      }

      let unidad = 'Miles de Millones de Pesos'
      let tipo_unidad = 'monto'

      return {
        series: [
          {
            name: data[0].region.nombre,
            data: data_chart
          },
        ],
        categories: categories,
        colors: ['#254cb4'],
        unidad: unidad,
        tipo_unidad: tipo_unidad,
        nombre_chart: 'PIB'
      }
    },

    get_data_column_chart(data){
      let series = []
      let categories = []
      let colors = []
      
      for (let element of data) {
        if(element.region.id != 18){
          if (!categories.includes(element.region.nombre)) {
            let category = element.region.nombre.includes('Arica') ? 
                            ['Arica','Parinacota'] : 
                            element.region.nombre;
            
            categories.push(category);
            let dato = element.valor_pib
            series.push(dato)

            if (element.region.id == this.region_selected) {
              colors.push('#398104');
            }
            else{
              colors.push("#898989");
            }
          }
        }
      }

      return {
        series: [
          {
            data: series,
            name: 'PIB'
          }
        ],
        categories: categories,
        colors: colors,
        unidad: 'Miles de Millones de Pesos',
        tipo_unidad:'monto',
        tipo_categoria: 'regiones'
      }
    }, 

    seleccionarRegion(region_index){
      if (region_index === undefined) {
        this.series_line_chart.pop()
      }
      
      else {
        let region = this.regiones[region_index]
        let id_region = region.id
        
        if (this.series_line_chart.length == 1){
          let data_anual_region_2 = this.data_anual_regiones.filter(e => e.region.id == id_region)
          let data_line_chart_2 = this.get_data_line_chart(data_anual_region_2)
          this.series_line_chart.push(data_line_chart_2.series[0])         
        }

        else {
          this.series_line_chart.pop()
          let data_anual_region_2 = this.data_anual_regiones.filter(e => e.region.id == id_region)
          let data_line_chart_2 = this.get_data_line_chart(data_anual_region_2)
          this.series_line_chart.push(data_line_chart_2.series[0])   
        }
      }
    },

    order_by_anio(data){
       data.sort(function (a,b) {
        return a.anio - b.anio
      });
    },

    updateFiltro(newValue, indexFiltro) {

      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
      }
      this.get_data_region(this.region_selected, this.selected_filtro_1)
      this.get_data_regiones(this.selected_filtro_1)

    }
  }
}
</script>