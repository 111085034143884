<template>
   <v-card  flat :height="height_card">
      <v-card-title>
        <v-icon
        medium
        left
        >
        {{icon}}
        </v-icon>
        <h4 class="font-weight-medium">{{titulo}}</h4>  
      </v-card-title>
     
      <v-card-text style="margin-top:-20px;">
        <sankey
          v-if="data != null" 
          :nodes="data.nodes"
          :links="data.links"
          :unidad="data.unidad"
          :height_chart="height_chart"
        />
        <!-- <line-chart 
          v-if="data != null"
          :data_chart="data.series"
          :categories_chart="data.categories"
          :colors_chart="data.colors"
          :nombre_chart="data.name"
          :unidad_chart="data.unidad"
          :tipo_unidad_chart="data.tipo_unidad"
          :height_chart="height_chart"
        /> -->
      </v-card-text>
  </v-card>
</template>

<script>
import Sankey from './charts/Sankey.vue'
export default {
  name:'infoCardSankey',
  components:{Sankey},
  props:[
    'icon', 'titulo','data','height_chart',
    'height_card'
  ],
}
</script>

<style>

</style>