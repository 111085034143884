<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col>
          <info-card-simple 
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Índice: '+data_view.filtros[0].data[0].text"
            :valor="data_region_1"
            :tipo_valor="selected_filtro_2"
          />
        </v-col>
        <v-col>
           <info-card-simple 
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Índice: '+data_view.filtros[0].data[1].text"
            :valor="data_region_2"
            :tipo_valor="selected_filtro_2"
          />
        </v-col>
        <v-col>
           <info-card-simple 
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Índice: '+data_view.filtros[0].data[2].text"
            :valor="data_region_3"
            :tipo_valor="selected_filtro_2"
          />
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px">
        <v-col>
          <info-card-column-chart 
            :icon="'mdi-chart-bar'"
            :titulo="'Variación Anual'"
            :subtitulo="data_view.nombre_data"
            :height_chart="size_column_chart.height_chart"
            :height_card="size_column_chart.height_card"
            :data="data_column_chart"
          />
        </v-col>
        <v-col>
          <info-card-ranking 
            :icon="'mdi-format-list-bulleted'"
            :titulo="'Ranking Regional - '+selected_filtro_2"
            :height_card="size_ranking.height_card"
            :height_chart="size_ranking.height_chart"
            :data="data_ranking"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
// import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardColumnChart from '../../components/infoCardColumnChart.vue';
import InfoCardRanking from '../../components/infoCardRanking.vue';

export default {
  components: { HeaderComponent, InfoCardSimple, InfoCardColumnChart, InfoCardRanking },
  name: 'View4Region',
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1:this.data_view.filtros[0].data[0],
      selected_filtro_2:this.data_view.filtros[1].data[0],
      data_region_1:null,
      data_region_2:null,
      data_region_3:null,
      data_column_chart: null,
      data_ranking: null
    }
  },
  created () {
    this.get_data_region(this.region_selected, this.selected_filtro_2)
    this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2)
  },
  computed: { 
    size_column_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 600,
          height_chart: 515
        }
      }
      else {
        return {
          height_card: 415,
          height_chart: 330
        }
      }
    },

    size_ranking () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 600,
          height_chart: 'xl'
        }
      }
      else {
        return {
          height_card: 415,
          height_chart: 'small'
        }
      }
    }
  },
  watch: {
    region_selected () {
      this.get_data_region(this.region_selected, this.selected_filtro_2)
      this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2)
    },
    data_view () {
      this.selected_filtro_1 = this.data_view.filtros[0].data[0]
      this.selected_filtro_2 = this.data_view.filtros[1].data[0]
      this.get_data_region(this.region_selected, this.selected_filtro_2)
      this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2)
    }
  },
  methods: {

    async get_data_region(region, anio) {
      let url = this.data_view.url_api[0]
      let url_api = API_URL+url+'?region='+region
      let response = await axios.get(url_api)
      let data = response.data.results
      let data_anual = data.filter( e => e.anio_data == anio )

      let data_region_1 = data_anual[0].valor
      let data_region_2 = data_anual[1].valor
      let data_region_3 = data_anual[2].valor

      this.data_region_1 = data_region_1.toFixed(2)+'%'
      this.data_region_2 = data_region_2.toFixed(2)+'%'
      this.data_region_3 = data_region_3.toFixed(2)+'%'

      const anios = [...this.data_view.filtros[1].data].sort()
      let data_column_chart = this.get_data_column_chart(data,anios)
      let unidad  = 'Indice'
      let tipo_unidad = 'porcentaje'
      let tipo_categoria = 'anio'

      data_column_chart['unidad'] = unidad
      data_column_chart['tipo_unidad'] = tipo_unidad
      data_column_chart['tipo_categoria'] = tipo_categoria

      this.data_column_chart = data_column_chart
    },

    async get_data_regiones(indice, anio) {
      let url = this.data_view.url_api[0]
      let url_api = API_URL+url+'?categoria='+indice.value+'&anio_data='+anio
      let response = await axios.get(url_api)
      let data = response.data.results
      
      this.order_by_valor(data)

      let data_ranking = this.get_data_ranking(data, indice.text)
      data_ranking['selected'] = this.region_detail.nombre
      this.data_ranking = data_ranking
    },

    get_data_ranking(data, indice){
      let nombre_labels = 'Regiones'
      let nombre_data ='Índice: '+indice
      let data_ranking = []
      let labels_ranking = []

      for (let element of data) {
        let region = element.region.nombre
        let indice_region = element.valor

        data_ranking.push(indice_region)
        labels_ranking.push(region)
      }

      return {
        data: data_ranking,
        labels: labels_ranking,
        nombre_labels: nombre_labels,
        nombre_data: nombre_data
      }
    },

    order_by_valor(data) {
      data.sort(function (a,b) {
        return b.valor - a.valor
      });
    },

    get_data_column_chart(data, anios) {
      let indice_1 = this.data_view.filtros[0].data[0]
      let indice_2 = this.data_view.filtros[0].data[1]
      let indice_3 = this.data_view.filtros[0].data[2]
      let data_1 = []
      let data_2 = []
      let data_3 = []
      let categories = []
      let colors = ["#000099","#FBC02D","#CC3399"]

      for (let anio of anios){
        if(!categories.includes(anio)) {
          let data_anio = data.filter(e => e.anio_data == anio)
          let valor_1 = data_anio[0].valor
          let valor_2 = data_anio[1].valor
          let valor_3 = data_anio[2].valor

          data_1.push(valor_1)
          data_2.push(valor_2)
          data_3.push(valor_3)
          categories.push(anio)
        }
      }

      return {
        series:[
          {
            data: data_1,
            name: indice_1.text
          },
          {
            data: data_2,
            name: indice_2.text
          },
          {
            data: data_3,
            name: indice_3.text
          }
        ],
        categories: categories,
        colors: colors
      }
    },

    updateFiltro(newValue, indexFiltro) {

      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
      }

      else if (indexFiltro == 1) {
        this.selected_filtro_2 = newValue
        this.get_data_region(this.region_selected, this.selected_filtro_2)
      }
      this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2)
    }
  }
}
</script>
