<template>
  <div>
    <v-card flat >
      <v-card-title>
        <v-icon
        medium
        left
        >
        {{icon}}
        </v-icon>
        <h4 class="font-weight-medium">{{titulo}}</h4>  
      </v-card-title>
      <v-card-subtitle class="text-left">
          <h3 class="font-weight-medium">{{subtitulo}}</h3>
      </v-card-subtitle>
      <v-card-text style="margin-top:-5px">
        <v-progress-linear 
          v-model="valor"
          color="blue-grey"
          :height="is_responsive ? 23 : 31"
        >
          <template v-slot:default="{ value }">
            <strong>{{ value.toFixed(2) }}%</strong>
          </template>
        </v-progress-linear>
        <h3 class="font-weight-medium mt-2">{{nombre_valor}}</h3>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name:'infoCardSimpleBar',
  props:[
    'icon', 'titulo','subtitulo','valor','nombre_valor'
  ],
  computed: {
    is_responsive () {
      if( this.$vuetify.breakpoint.name != 'xl'){
        return true
      }
      else {
        return false
      }
    }
  }
}
</script>

<style>

</style>