<template>
  <div class="modal-backdrop">
    <div class="modal">
      <header class="modal-header">
        <slot name="header">
          <h3 class="font-weight-medium">Confirmación</h3>
        </slot>
        <button
          type="button"
          class="btn-close"
          @click="close"
        >
          x
        </button>
      </header>

      <section class="modal-body">
        <slot name="body">
          <div>
            <span>Usuario: <b>{{user_email}}</b></span>
          </div>
          <div class="mt-2">
            <span>¿Desea eliminar este usuario?</span>
          </div>
        </slot>
       </section>

      <footer class="modal-footer">
        <slot name="footer">
          <v-row>
            <v-col>
              <v-btn
                depressed
                dark
                color='#1E88E5'
                @click="close"
              >
                Cerrar
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                depressed
                color="red darken-1"
                dark
                @click="delete_user"
              >
                Eliminar
              </v-btn>
            </v-col>
          </v-row>
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: [
    'user_email'
  ],
  methods: {
    close() {
      this.$emit('close');
    },
    delete_user () {
      this.$emit('delete');
    }
  },
}
</script>

<style>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: black;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: black;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #4AAE9B;
    border: 1px solid #4AAE9B;
    border-radius: 2px;
  }
</style>