<template>
  <v-card flat :height="height_mapa">
    <div id="mapContainer" class="basemap"></div>
  </v-card> 
</template>

<style>
.basemap {
  width: 100%;
  height: 100%;
}
</style>

<style>
.mapboxgl-popup {
  max-width: 400px;
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
</style>

<script>
import mapboxgl from "mapbox-gl";
import {getZoomRegion, formatNumeroRegion} from '@/functions/functions.js'
export default {
  name: 'MapPresupuestoMunicipal',
  props: [
    'coordenadas_region',
    'codigo_region',
    'codigo_comuna',
    'comuna_detail',
    'data_mapa',
    'height_mapa'
  ],
  data () {
    return {
      accessToken: 'pk.eyJ1IjoibGFiZGV2MjIiLCJhIjoiY2tzaHFvamRtMXdwZDJ1b2E2ZHRrMDNvYyJ9.A2-aL3_dJ9BIK26yO0Ahug',
      mapa_region:null
    }
  },
  mounted() {
    const zoom_region = getZoomRegion(this.codigo_region)
    const coordenadas_region = this.coordenadas_region
    const codigo_region = formatNumeroRegion(this.codigo_region)
    this.mapa_region = this.create_basemap(coordenadas_region,zoom_region)
    this.addRegionSource(this.mapa_region);
    this.addRegionLayer(this.mapa_region,codigo_region)
    this.addComunasSource(this.mapa_region, this.data_mapa)
    this.addLayerComunas(this.mapa_region, this.codigo_comuna)
    this.addLayerComunaSelected(this.mapa_region, this.codigo_comuna)
    this.mapPopUp(this.mapa_region, 'comunas')
    this.mapPopUp(this.mapa_region, 'comuna_selected')
  },
  watch: {
    data_mapa() {
      const zoom_region = getZoomRegion(this.codigo_region)
      const coordenadas_region = this.coordenadas_region
      const codigo_region = formatNumeroRegion(this.codigo_region)
      this.mapa_region = this.create_basemap(coordenadas_region,zoom_region)
      this.addRegionSource(this.mapa_region);
      this.addRegionLayer(this.mapa_region,codigo_region)
      this.addComunasSource(this.mapa_region, this.data_mapa)
      this.addLayerComunas(this.mapa_region, this.codigo_comuna)
      this.addLayerComunaSelected(this.mapa_region, this.codigo_comuna)
      this.mapPopUp(this.mapa_region, 'comunas')
      this.mapPopUp(this.mapa_region, 'comuna_selected')
    },
    codigo_comuna () {
      this.mapa_region.removeLayer('comunas')
      this.mapa_region.removeLayer('comuna_selected')
      this.addLayerComunasAfter(this.mapa_region, this.codigo_comuna)
      this.addLayerComunaSelectedAfter(this.mapa_region, this.codigo_comuna)
      this.mapPopUp(this.mapa_region, 'comunas')
      this.mapPopUp(this.mapa_region, 'comuna_selected')
    }
  },
  methods: {
    create_basemap(coordinates,zoom_region){
      mapboxgl.accessToken = this.accessToken;
      let basemap = new mapboxgl.Map({
        container: "mapContainer",
        style: "mapbox://styles/mapbox/light-v10",
        center: coordinates,
        zoom: this.height_mapa < 600 ? zoom_region : zoom_region+1,
      });

      basemap.addControl(new mapboxgl.NavigationControl());

      return basemap;
    },

    mapPopUp(mapa,layer) {
      mapa.on('load', () => { 
        const popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        });

        mapa.on('mouseenter', layer, (e) => {
          // Change the cursor style as a UI indicator.
          mapa.getCanvas().style.cursor = 'pointer';

          function formatCL(val){
            var valor = val.toLocaleString();
            var i;
            var stringFormat = ''; 
            for(i of valor){
              if( i === '.' ){
                i = ',';
                stringFormat+=i;
              }
              else if(i ===','){
                i = '.';
                stringFormat+=i;
              }
              else{
                stringFormat+=i;
              }
            }
            return stringFormat;
          }
          
          // Copy coordinates array.
          const coordinates = e.features[0].geometry.coordinates.slice();
          let comuna = e.features[0].properties.comuna
          let ingresos = formatCL(e.features[0].properties.ingresos)
          let gastos = formatCL(e.features[0].properties.gastos)
          let ejecucion = e.features[0].properties.porcentaje
          let unidad = e.features[0].properties.unidad
          const description = '<div class="text-left">'+
                              '<div class="title">'+comuna+'</div>'+
                              '<div class="subtitle-2 mt-2 font-weight-light">Ingresos</div>'+
                              '<div class="subtitle-1 mt-0 font-weight-medium">$'+ingresos+'</div>'+
                              '<div class="subtitle-2 font-weight-light">'+unidad+'</div>'+
                              '<div class="subtitle-2 mt-2 font-weight-light">Gastos</div>'+
                              '<div class="subtitle-1 mt-0 font-weight-medium">$'+gastos+'</div>'+
                              '<div class="subtitle-2 font-weight-light">'+unidad+'</div>'+
                              '<div class="subtitle-2 mt-2 font-weight-light">Ejecución</div>'+
                              '<div class="subtitle-1 mt-0 font-weight-medium">'+ejecucion+'%</div>'+
                              '</div>';
          
          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          
          // Populate the popup and set its coordinates
          // based on the feature found.
          popup.setLngLat(coordinates).setHTML(description).addTo(mapa);
          });
          
        mapa.on('mouseleave', layer, () => {
          mapa.getCanvas().style.cursor = '';
          popup.remove();
        });
      });
    },

    addRegionSource(mapa){
       mapa.on('load', () => {
        mapa.addSource('map_regiones', {
          type: 'vector',
          url: 'mapbox://labdev22.azm906hb',
        });
      });
    },

    addComunasSource(mapa, data) {
      mapa.on('load', () => {
        mapa.addSource('mapa_comunas', {
            type: 'geojson',
            data: {
              "type": "FeatureCollection",
              "features": data
            }
          }          
        );
      });
    },

    addLayerComunas(mapa, codigo_comuna) {
      mapa.on('load', () => {
        mapa.addLayer({
          id:'comunas',
          source:'mapa_comunas',
          type: 'circle',
          layout: {
            'visibility':'visible'
          },
          paint: {
            'circle-radius': [
                'step',
                ['get','ingresos'],
                6,
                1000000,
                10,
                5000000,
                12,
                10000000,
                15,
                15000000,
                28,
                20000000,
                22,
                25000000,
                24,
                30000000,
                26,
                35000000,
                28,
                40000000,
                30,
                45000000,
                32,
                50000000,
                36,
                70000000,
                38,
                90000000,
                42,
                110000000,
                50
              ],
            'circle-color': '#42A5F5',
            'circle-stroke-width': 1,
          },
          "filter": ["!=", "codigo_comuna", codigo_comuna]
        });
      });
    },

    addLayerComunasAfter(mapa, codigo_comuna) {
      
        mapa.addLayer({
          id:'comunas',
          source:'mapa_comunas',
          type: 'circle',
          layout: {
            'visibility':'visible'
          },
          paint: {
            'circle-radius': [
                'step',
                ['get','ingresos'],
                7,
                1000000,
                10,
                5000000,
                13,
                10000000,
                19,
                15000000,
                26,
                20000000,
                28,
                25000000,
                30,
                30000000,
                32,
                35000000,
                34,
                40000000,
                36,
                45000000,
                38,
                50000000,
                40,
                70000000,
                42,
                90000000,
                44,
                110000000,
                46
              ],
            'circle-color': '#42A5F5',
            'circle-stroke-width': 1,
          },
          "filter": ["!=", "codigo_comuna", codigo_comuna]
        });
    
    },

    addLayerComunaSelected(mapa, codigo_comuna){
      mapa.on('load', () => {
        mapa.addLayer({
          id:'comuna_selected',
          source:'mapa_comunas',
          type: 'circle',
          layout: {
            'visibility':'visible'
          },
          paint: {
            'circle-radius': [
                'step',
                ['get','ingresos'],
                7,
                1000000,
                10,
                5000000,
                13,
                10000000,
                19,
                15000000,
                26,
                20000000,
                28,
                25000000,
                30,
                30000000,
                32,
                35000000,
                34,
                40000000,
                36,
                45000000,
                38,
                50000000,
                40
              ],
            'circle-color': '#CD2400',
            'circle-stroke-width': 1,
          },
          "filter": ["==", "codigo_comuna", codigo_comuna]
        });
      });
    },
    
    addLayerComunaSelectedAfter(mapa, codigo_comuna){
     
      mapa.addLayer({
        id:'comuna_selected',
        source:'mapa_comunas',
        type: 'circle',
        layout: {
          'visibility':'visible'
        },
        paint: {
          'circle-radius': [
              'step',
              ['get','ingresos'],
              7,
              1000000,
              10,
              5000000,
              13,
              10000000,
              19,
              15000000,
              26,
              20000000,
              28,
              25000000,
              30,
              30000000,
              32,
              35000000,
              34,
              40000000,
              36,
              45000000,
              38,
              50000000,
              40
            ],
          'circle-color': '#CD2400',
          'circle-stroke-width': 1,
        },
        "filter": ["==", "codigo_comuna", codigo_comuna]
      });
      
    },

    addRegionLayer(mapa,codigo_region){
      mapa.on('load', () => {
        mapa.addLayer({
          id:'region',
          source: 'map_regiones',
          'source-layer': 'chile_regiones_comunas-6uwztq',
          type: 'fill',
          paint: {
            // 'fill-color': '#5D6D7E',
            'fill-color': [
              'match', ['get','codigo_region'],
              codigo_region,  '#8A9A5B',
              '#C0C0C0'
            ],
            'fill-opacity':0.3,
            'fill-outline-color': '#00194c'
          },
          // filter: ['==',['get', 'codigo_region'], codigo_region]
        });

        mapa.addLayer({
          'id': 'borde-regiones',
          'type': 'line',
          source: 'map_regiones',
          'source-layer': 'chile_regiones_comunas-6uwztq',
          'paint': {
            'line-color': '#414141',
            'line-width': 0.8,
            'line-opacity': 0.5
          },
        });
      });
    },
  }
}
</script>