<template>
  <div id="chart" >
    <apexchart width="100%" :height="height_chart" ref="chart" type="bar" :options="options" :series="series" >
    </apexchart>
  </div>
  
</template>

<style>
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: px; */
}
</style>


<script>
export default {
  name: 'FillChart',
  props: [
    'data_chart',
    'height_chart',
    'colors_chart'
  ],
  
  computed:{
    series () {
      return this.data_chart.series
    },
    options () {
      return {
        chart: {
          id: 'Fill Chart',
          zoom: false,
          toolbar:{
              show:false
          },
          stacked: true,
        },
        plotOptions:{
          bar: {
            barWidth: '100%',
            dataLabels: {
              position: 'top'
           },
            borderRadius: 5,
          }
        },
        tipo_unidad: this.data_chart.tipo_unidad,
        unidad: this.data_chart.unidad,
        xaxis: {
          type: "categories",
          categories: this.data_chart.categories,
          style: {
              colors: '#fff',
              fontSize: '11px',
              fontWeight: 550
          },
        },
        yaxis:{
          show: false,
          labels: {
              formatter: function (val) {
                  return val+'%'
              }
          }
        },
        colors:this.colors_chart,
        legend: {
            show:false
        },
        grid: {
          show:true
        },
        dataLabels: {
          enabled: this.height_chart > 200 ? true: false,
          enabledOnSeries: [0],
          offsetY:-20,
          offsetX:0,
          formatter: function(val,w){
            var index = w['dataPointIndex'];
            var data = w['w'].globals.series
            var totalData = data[0][index] + data[1][index]
            var porcentaje = parseFloat(((val / totalData)*100).toFixed(1));
            if(porcentaje != 0.00){
              return porcentaje+'%';
            }else{
              return '0%';
            }
          },
          style: {
            fontSize: '12px',
            colors: ['#000']
          },  
        },
        tooltip: {
          theme: 'dark',
          custom: function({series,dataPointIndex, w}) {
            const formatNumber = (valor) => {
              let valor_format = valor.toLocaleString();
              let i;
              let result = '';

              for( i of valor_format ){
                if( i == '.') {
                  result = result + ','
                }

                else if( i == ',' ) {
                  result = result + '.'
                }

                else {
                  result = result + i
                }
              }
              return result
            };

            
            let value_1 = series[0][dataPointIndex]
            let value_2 = series[1][dataPointIndex]+value_1
           
            let value_1_formatted = formatNumber(value_1)
            let value_2_formatted = formatNumber(value_2)

            let porcentaje = ((value_1/value_2)*100).toFixed(1);
           
            let label = w.globals.labels[dataPointIndex];
            let unidad = w.config.unidad
            let tipo_unidad = w.config.tipo_unidad
            let text_value_1 = tipo_unidad == 'monto' ? '$'+value_1_formatted : value_1_formatted
            let text_value_2 = tipo_unidad == 'monto' ? '$'+value_2_formatted : value_2_formatted

            let nombre_data_1 = w.globals.seriesNames[0]
            let nombre_data_2 = w.globals.seriesNames[1]

            let tooltip_html ='<div class="arrow_box" style="width:170px;height:220px;">'+
                              '<div class="title ml-3  font-weight-medium text-left">' + label + '</div>' +
                              '<div class="body-2 ml-3 mt-0 text-left">'+nombre_data_1+'</div>' +
                              '<div class="subtitle-1 font-weight-bold ml-3 text-left" style="margin-top:-5px;">'+text_value_1+'</div>' +
                              '<div class="subtitle-2 ml-3 text-left" style="margin-top:-5px;">' +unidad  + '</div>' +
                              '<div class="body-2 ml-3 mt-2 text-left">'+nombre_data_2+'</div>' +
                              '<div class="subtitle-1 font-weight-bold ml-3 text-left" style="margin-top:-5px;">'+text_value_2+'</div>' +
                              '<div class="subtitle-2 ml-3 text-left" style="margin-top:-5px;">' +unidad  + '</div>' +
                              '<div class="body-2 ml-3 mt-2 text-left">Ejecución</div>' +
                              '<div class="subtitle-1 font-weight-bold ml-3 text-left" style="margin-top:-5px;">'+porcentaje+'%</div>' +

                              '</div>'
            return tooltip_html
          }
        }

      }
    }
  }
}
</script>

<style>

</style>