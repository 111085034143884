<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col xl=3 sm=3>
          <info-card-simple 
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Registradas'"
            :valor="data_region"
            :tipo_valor="'Patentes'"
          />
          <info-card-simple
            class="mt-2" 
            :icon="'mdi-information'"
            :titulo="'País'"
            :subtitulo="'Registradas'"
            :valor="data_pais"
            :tipo_valor="'Patentes'"
          />
        </v-col>
        
        <v-col xl=9 sm=9>
          <info-card-column-chart 
            :icon="'mdi-chart-bar'"
            :titulo="'Comparación Regional'"
            :subtitulo="'Patentes Registradas'"
            :height_chart="size_column_chart.height_chart"
            :height_card="size_column_chart.height_card"
            :data="data_column_chart"
          />
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px;">
      <v-col xl=3 sm=3>
        <info-card-pie-chart 
          :icon="'mdi-chart-pie'"
          :titulo="'Distribución por tipo'"
          :subtitulo="selected_filtro_1.text"
          :data="data_pie_chart"
          :width_chart="size_pie_chart.width_chart"
          :height_card="size_pie_chart.height_card"
        />
      </v-col>
      <v-col xl=9 sm=9>
          <info-card-line-chart 
            :titulo="'Variación Anual'"
            :subtitulo="'Tipo: '+selected_filtro_2.text"
            :icon="'mdi-trending-up'"
            :data="data_line_chart"
            :height_chart="size_line_chart.height_chart"
            :height_card="size_line_chart.height_card"
          />
        </v-col>
      
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardLineChart from '../../components/infoCardLineChart.vue';
import InfoCardPieChart from '../../components/infoCardPieChart.vue';
import InfoCardColumnChart from '../../components/infoCardColumnChart.vue';

export default {
  name: 'View11Region',
  components: { HeaderComponent, InfoCardSimple, InfoCardLineChart, InfoCardPieChart, InfoCardColumnChart},
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      selected_filtro_2: this.data_view.filtros[1].data[0],
      data_region:null,
      data_pais: null,
      data_line_chart: null,
      data_pie_chart: null,
      data_column_chart: null
    }
  },
  created() {
    this.get_data_region_anio(this.region_selected, this.selected_filtro_1.value)
    this.get_data_region_tipo(this.region_selected, this.selected_filtro_2)
    this.get_data_regiones(this.selected_filtro_1.value)
  },
  computed : {
    size_column_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 327,
          height_chart: 250
        }
      }
      else {
        return {
          height_card: 307,
          height_chart: 240
        }
      }
    },
    size_line_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 430,
          height_chart: 360
        }
      }
      else {
        return {
          height_card: 250,
          height_chart: 190
        }
      }
    },

    size_pie_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 430,
          width_chart: 430
        }
      }
      else {
        return {
          height_card: 250,
          width_chart: 205
        }
      }
    },
  },
  watch: {
    region_selected() {
        this.get_data_region_anio(this.region_selected, this.selected_filtro_1.value)
        this.get_data_region_tipo(this.region_selected, this.selected_filtro_2)
        this.get_data_regiones(this.selected_filtro_1.value)
    }
  },
  methods: {

    async get_data_region_anio(region, anio) {
      let url = this.data_view.url_api[0];
      let  url_api = API_URL+url+'?region='+region+'&anio='+anio
      let response = await axios.get(url_api)
      let data = response.data.results

      let data_region = data.cantidad_patentes_region 
      this.data_region = formatNumber(data_region)

      let data_tipos = data.data_por_tipo
      let data_pie_chart = this.get_data_pie_chart(data_tipos)
      this.data_pie_chart = data_pie_chart
    },

    async get_data_region_tipo(region, tipo){
       let url = this.data_view.url_api[0];
      let  url_api = API_URL+url+'?region='+region+'&tipo='+tipo.value
      let response = await axios.get(url_api)
      let data = response.data.results
      let data_anios = data.data_por_anio

      let data_line_chart = this.get_data_line_chart(data_anios)
      this.data_line_chart = data_line_chart
    },
      
    async get_data_regiones(anio) {
      let url = this.data_view.url_api[0];
      let url_api = API_URL+url+'?anio='+anio;
      let response = await axios.get(url_api)
      let data = response.data.results
      
      let data_pais = data.data_pais
      this.data_pais = formatNumber(data_pais)

      let data_regiones = data.data_regiones
      let data_column_chart = this.get_data_column_chart(data_regiones)
      this.data_column_chart = data_column_chart
    },

    get_data_column_chart(data) {
      let series = []
      let categories = []
      let colors = []
      
      for (let element of data) {
        if (!categories.includes(element.region)) {
          let category = element.region.includes('Arica') ? 
                          ['Arica','Parinacota'] : 
                          element.region;
          
          categories.push(category);
        }

        let dato = element.cantidad
        series.push(dato)

        if (element.region == this.region_detail.nombre) {
          colors.push('#398104');
        }
        else{
          colors.push("#898989");
        }
      }

      return {
        series: [
          {
            data: series,
            name: 'Patentes Registradas'
          }
        ],
        categories: categories,
        colors: colors,
        unidad: 'Patentes',
        tipo_unidad:'no_monto',
        tipo_categoria: 'regiones'
      }
    },

    get_data_pie_chart(data) {
      let series = []
      let categories = []
      let colors = ['#A52A2A','#CD7F32','#C19A6B']
      for (let element of data) { 
        if (!categories.includes(element.tipo)) {
          categories.push(element.tipo)
        }

        series.push(element.cantidad)
      }
      let unidad = 'Patentes'
      let tipo_unidad = 'no_monto'

      return {
        series: series,
        categories: categories,
        colors: colors,
        unidad: unidad,
        tipo_unidad: tipo_unidad
      }
    },

    get_data_line_chart(data) {
      let series = []
      let categories = []

      this.order_by_anio(data)

      for (let element of data) {
        if (!categories.includes(element.anio)) {
          categories.push(element.anio)
        }

        series.push(element.cantidad)
      }

      let unidad = 'Patentes'
      let tipo_unidad = 'no_monto'
      let nombre_data = 'Patentes Registradas'

      return {
        series: [
          {
            name: nombre_data,
            data: series
          }
        ],
        categories: categories,
        colors: ['#254cb4'],
        unidad: unidad,
        tipo_unidad: tipo_unidad
      }
    },

    order_by_anio(data) {
      data.sort(function (a,b) {
        return a.anio - b.anio
      });
    },

    updateFiltro(newValue, indexFiltro) {

      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
        this.get_data_regiones(this.selected_filtro_1.value)
        this.get_data_region_anio(this.region_selected, this.selected_filtro_1.value)
      }

      else if (indexFiltro == 1) {
        this.selected_filtro_2 = newValue
        this.get_data_region_tipo(this.region_selected, this.selected_filtro_2)
      }
    }
  }
}
</script>