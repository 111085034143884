<template>
  <v-card flat :height="height_card">
    <v-card-title>
      <div v-if="hasOptions">
        <v-app-bar
          flat
          color="rgba(0, 0, 0, 0)"
          style="margin-top:-15px;margin-left:-15px;"
        >
          <v-icon
            medium
            left
          >
           {{icon}}
          </v-icon>
          <v-toolbar-title>
            <h4 class="font-weight-medium">{{titulo}}</h4>  
          </v-toolbar-title>
          <v-col
            class="d-flex mt-6 "
            cols="12"
            sm="3"
            md=4
          >
            <v-select
              :value="first_option"
              :items="options"
              :label="label_options"
              dense
              outlined
              item-value="value"
              item-text="text"
              @input="cambiarOpcion"
            ></v-select>
          </v-col>
        </v-app-bar>
      </div>
      <div v-else>
        <v-icon
          medium
          left
        >
          {{icon}}
        </v-icon>
        <h4 class="font-weight-medium">{{titulo}}</h4>
      </div>
     
    </v-card-title>
    <v-card-subtitle class="text-left" style="margin-top:-15px;">
      <h3 class="font-weight-medium">{{subtitulo}}</h3>
    </v-card-subtitle>
    <v-card-text style="margin-top:-40px">
      <fill-chart 
        v-if="data_chart != null"
        :data_chart="data_chart" 
        :height_chart="height_chart"
        :colors_chart="colors_chart"
      />
      
    </v-card-text>
  </v-card>
</template>

<script>
import FillChart from './charts/FillChart.vue'
export default {
  components: { FillChart },
  name:'infoCardFillChartOptions',
  props: [
    'icon','titulo','subtitulo','hasOptions','options','first_option',
    'data','height_card','height_chart','label_options'
    
  ],
  data() {
    return {
      data_chart:null,
      colors_chart: ["#E53935","#CCCCCC"]
    }
  },
  watch:{
    data () {
      this.data_chart = this.createDataChart(this.data,this.first_option)
    }
  },
  methods: {
    cambiarOpcion(opcion){
      this.data_chart = this.createDataChart(this.data,opcion)
    },

    createDataChart(data,option){
      const data_option = this.getDataByAnio(option, data.data_result)
      let data_names = data.data_names
      let category_name = data.category_name
      let data_chart = this.getDataChart(data_option,data_names,category_name)
      data_chart['name'] = data.name;
      data_chart['unidad'] = data.unidad;
      data_chart['tipo_unidad'] = data.tipo_unidad;
      return data_chart
    },

    getDataByAnio(anio,data){
      let data_result = data.filter(e => e.anio == anio)
      return data_result
    },

    getDataChart(data,data_names,category_name){
      
      let var_1 = data_names[0]
      let var_2 = data_names[1]
      let categories = []
      let data_1 = []
      let data_2 = []

      if( category_name == 'mes'){
        this.sortByMonthObject(data)
        this.sortByMonth(categories)
        var months = ["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"];
        
        if(data.length < 12){
          for(let i = 0 ; i < 12 ; i++){
            let mes_select = months[i]
            let value_1, value_2;
            let ultimo_value_2 = data_2[data_2.length-1] + data_1[data_1.length-1]
            let element = data.find(e => e.mes == mes_select);
            if( element != undefined) {
              value_1 = element[var_1.value]
              value_2 = element[var_2.value]-value_1
            }
            else {
              value_1 = 0
              value_2 = ultimo_value_2
            }
            data_1.push(value_1)
            data_2.push(value_2)
            categories.push(mes_select)
          }
        }
        else {
          let element;
          for(element of data){
            let value_1 = element[var_1.value]
            let value_2 = element[var_2.value]-value_1
            data_1.push(value_1)
            data_2.push(value_2)
          }
          categories = months
        }
      }

      
      let data_result = {
        series: [
          {
            data: data_1,
            name: var_1.text
          },
          {
            data: data_2,
            name: var_2.text
          }
        ],
        categories: categories
      }      
      return data_result
    },

     sortByMonthObject(arr) {  
      var months = ["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"];
      arr.sort(function(a, b){
        return months.indexOf(a.mes) - months.indexOf(b.mes);
      })
    },
    sortByMonth(arr) {  
      var months = ["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"];
      arr.sort(function(a, b){
        return months.indexOf(a) - months.indexOf(b);
      })
    },
  }
}
</script>

<style>

</style>