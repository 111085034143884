<template>
  <div id="chart" >
    <apexchart type="bar" :height="height_chart" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<style >
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;   
}
</style>

<script>
export default {
  name: 'RankingChart',
  props: [
    'data_chart', 'categories_chart','colors_chart',
    'unidad_chart', 'tipo_unidad_chart', 'height_chart',
    'nombre_data'
  ],
  computed: {
    series () {
      return [{
        data: this.data_chart
      }]
    },
    
    chartOptions () {
      return {
        chart: {
          type: 'bar',
          toolbar:{
            show:false
          },
        },
        plotOptions: {
          bar: {
            barHeight: '90%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom'
            },
          }
        },
        colors: this.colors_chart,
        unidad: this.unidad_chart,
        tipo_unidad: this.tipo_unidad_chart,
        nombre_data: this.nombre_data,
        legend: {
          show:false
        },
        dataLabels: {
          enabled: false,
          textAnchor: 'start',
          style: {
            colors: ['#000']
          },
          formatter: function (val, opt) {

            console.log(opt)
            return  val
          },
          offsetX: 0,
          dropShadow: {
            enabled: false
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: this.categories_chart,
          labels: {
            show:this.height_chart >= 300 ? true : false,
            formatter: function(value) {
              const formatNumber = (valor) => {
                let valor_format = valor.toLocaleString();
                let i;
                let result = '';

                for( i of valor_format ){
                  if( i == '.') {
                    result = result + ','
                  }

                  else if( i == ',' ) {
                    result = result + '.'
                  }

                  else {
                    result = result + i
                  }
                }
                return result
              };

              return formatNumber(value)
            }
          }
        },
        yaxis: {
          labels: {
            show: true,
              maxWidth: 300,
              align: 'left',
             style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
          }
        },
        tooltip: {
          theme: 'dark',
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            const formatNumber = (valor) => {
              let valor_format = valor.toLocaleString();
              let i;
              let result = '';

              for( i of valor_format ){
                if( i == '.') {
                  result = result + ','
                }

                else if( i == ',' ) {
                  result = result + '.'
                }

                else {
                  result = result + i
                }
              }
              return result
            };

            let total = series[seriesIndex].reduce((a, b) => { return a + b}, 0);
            let value = series[seriesIndex][dataPointIndex]
            let porcentaje = ((value/total)*100).toFixed(2)
            let label = w.globals.labels[dataPointIndex];
            let unidad = w.config.unidad
            let nombre_data = w.config.nombre_data
            let tipo_unidad = w.config.tipo_unidad
            let text_value = tipo_unidad == 'monto' ? '$'+formatNumber(value) : formatNumber(value)

            let tooltip_html ='<div class="arrow_box" style="width:200px;height:170px;">'+
                              '<div class="title ml-3  font-weight-medium text-left">' + label+'</div>' +
                              '<div class="subtitle-1 font-weight-bold ml-3 text-left">'+nombre_data+'</div>' +
                              '<div class="title font-weight-bold ml-3 text-left" style="margin-top:-5px;">'+text_value+'</div>' +
                              '<div class="subtitle-2 ml-3 text-left" style="margin-top:-5px;">' +unidad  + '</div>' +
                               '<div class="subtitle-2 font-weight-bold mt-3 ml-3 text-left">Porcentaje</div>' +
                              '<div class="subtitle-1 ml-3 text-left" style="margin-top:-5px;">' +porcentaje+'%'+'</div>' +
                              '</div>'

            return tooltip_html

          }
        }
      }
    }
  }
}
</script>

