<template>
  <div id="ChangePassword">
    <v-app>
      <v-main style="background-color:#E0E0E0">
        <v-container  fill-height>
          <v-layout align-center justify-center>
            <v-flex style="max-width:350px">
              <v-card>
                <v-card-title class="justify-center">
                  <div class="d-flex">
                    <v-img
                      alt="labict logo"
                      class="shrink "
                      contain
                      src="@/media/logo_azul.png"
                      transition="scale-transition"
                      width="200"
                      height ="56"
                    />
                  </div>
                </v-card-title>
                <v-card-text class="px-6">
                  <div>
                    <h2 class="font-weight-medium">Cambio de Contraseña</h2>
                    <h3 class="font-weight-medium mt-3">Ingrese una nueva contraseña</h3>
                  </div>
                  <div class="mt-6">
                    <v-form @submit.prevent="changePassword"  ref="form" class="mt-4">
                      <v-text-field  
                        v-model='password'  
                        light 
                        outlined
                        prepend-inner-icon='mdi-lock' 
                        label='Contraseña' 
                        type='password' 
                        :rules="[
                          (v) => !!v || 'Ingrese una contraseña',
                          (v) => v && v.length <= 10 || 'Contraseña debe tener maximo 10 caracteres'
                        ]"
                      />
            
                      <v-text-field  
                        v-model='password_confirm'  
                        light 
                        outlined
                        prepend-inner-icon='mdi-lock' 
                        label='Repita Contraseña' 
                        type='password' 
                        max-length=10
                        :rules="[
                          (v) => !!v || 'Repita la contraseña',
                          (v) => v && v.length <= 10 || 'Contraseña debe tener maximo 10 caracteres'
                        ] "
                      />

                       <div>
                        <v-alert
                          dense
                          type="success"
                          :value="valid_success"
                          transition="fade-transition"
                        > 
                          <div style="margin-left:-30px;">
                            <span>Contraseña actualizada!</span>
                          </div>            
                        </v-alert>
                      </div>

                      <div>
                        <v-alert
                          dense
                          type="error"
                          :value="valid_fail"
                          transition="fade-transition"
                        > 
                          <div style="margin-left:-30px;">
                            <span>Contraseñas no coinciden</span>
                          </div>            
                        </v-alert>
                      </div>

                      <v-btn color="#1565C0"  
                        link :to="{path: '/login'}" 
                        class="mt-2 white--text" 
                        v-if="valid_success"
                      >
                        Ir a login
                      </v-btn>
                      <v-btn color="#1565C0" 
                        block 
                        type='submit' 
                        class="mt-2 white--text" 
                        v-else
                      >
                        Cambiar contraseña
                      </v-btn>
                    </v-form>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
const API_URL_CHANGE_PASSWORD = 'https://api.datari.net/auth/password-reset-complete';
import axios from 'axios';

export default {
  name: 'ChangePassword',
  props: ['token','uidb64'],
  data () {
    return {
      valid_success:false,
      valid_fail:false,
      password: null,
      password_confirm:null
    }
  },
  watch: {
    password () {
      this.valid_success = false
      this.valid_fail = false
    },

    password_confirm () {
      this.valid_success = false
      this.valid_fail = false
    }
  },
  methods: {
    changePassword() {
      this.$refs.form.validate();
      if (this.password != null && this.password_confirm != null) {
        if(this.password.length <= 10 && this.password_confirm.length <= 10){
          if(this.password === this.password_confirm) {
            let password_final = this.password;
            let token_final = this.token;
            let cod_uidb = this.uidb64;
            axios({
              method: 'patch',
              url: API_URL_CHANGE_PASSWORD,
              data: {
                password: password_final,
                token: token_final,
                uidb64: cod_uidb
              }
            })
            this.valid_success = true
          }
          else {
            this.valid_fail = true
          }
        }

      } 
    }
  }
}
</script>

<style>

</style>