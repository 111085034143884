<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col xl=5 sm=6>
          <v-row>
            <v-col>
              <info-card-simple-bar 
                :icon="'mdi-information'"
                :titulo="comuna_detail.nombre"
                :subtitulo="'Presupuesto Ejecutado'"
                :valor="data_comuna_ejecucion"
                :nombre_valor="selected_filtro_1"
              />
            </v-col>
          </v-row>
          <v-row style="margin-top:-15px;">
            <v-col cols=6>
              <info-card-simple
                :icon="'mdi-information'"
                :titulo="comuna_detail.nombre"
                :subtitulo="'Gastos'"
                :valor="data_comuna_gasto"
                :tipo_valor="'Millones de Pesos'"
              />
            </v-col>
            <v-col cols=6>
              <info-card-simple
                :icon="'mdi-information'"
                :titulo="comuna_detail.nombre"
                :subtitulo="'Ingresos'"
                :valor="data_comuna_ingresos"
                :tipo_valor="'Millones de Pesos'"
              />
            </v-col>
          </v-row>
          <v-row style="margin-top:-15px;">
            <v-col>
              <info-card-column-chart 
                :icon="'mdi-chart-bar'"
                :titulo="'Variación Anual'"
                :subtitulo="'Presupuesto Municipal'"
                :height_chart="size_column_chart.height_chart"
                :height_card="size_column_chart.height_card"
                :data="data_column_chart"
              />
            </v-col>
          </v-row> 
        </v-col>
        <v-col xl=7 sm=6>
          <presupuesto-municipal-map
            v-if="data_mapa_region != null"
            :height_mapa="size_mapa"
            :coordenadas_region="[ region_detail.lon, region_detail.lat]"
            :codigo_region="region_detail.numero_region"
            :data_mapa="data_mapa_region"
            :comuna_detail="comuna_detail"
            :codigo_comuna="codigo_comuna"
          />
        </v-col>
      </v-row>
      
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
const API_URL_MAPS = 'https://api.datari.net/datari/mapas/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardSimpleBar from '../../components/infoCardSimpleBar.vue';
import InfoCardColumnChart from '../../components/infoCardColumnChart.vue';
import PresupuestoMunicipalMap from '../../components/maps/PresupuestoMunicipalMap.vue';
export default {
  name: 'View2Comuna',
  components: { HeaderComponent, InfoCardSimple, InfoCardSimpleBar, InfoCardColumnChart, PresupuestoMunicipalMap},
  props: [
    'region_selected',
    'data_view',
    'region_detail',
    'comuna_selected',
    'comuna_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      data_comuna_ingresos:null,
      data_comuna_gasto:null,
      data_comuna_ejecucion:null,
      data_column_chart:null,
      data_mapa_region:null
    }
  },
  created() {
    this.get_data_comuna(this.selected_filtro_1, this.codigo_comuna)
    this.get_data_comuna_anios(this.codigo_comuna)
    this.get_data_mapa_region(this.region_selected, this.selected_filtro_1)
  },
  computed : {
    codigo_comuna () {
      return this.comuna_detail.codigo
    },
    size_column_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 440,
          height_chart:350
        }
      }
      else {
        return {
          height_card: 255,
          height_chart: 185
        }
      }
    },
    size_mapa () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return 776
      }
      else {
        return 571
      }
    }
  },
  watch: {
    comuna_selected() {
      this.get_data_comuna(this.selected_filtro_1, this.codigo_comuna)
      this.get_data_comuna_anios(this.codigo_comuna)
      //cambio de comuna en mapa
    },

    region_selected() {
      this.get_data_mapa_region(this.region_selected, this.selected_filtro_1)
    }
  },
  methods: {
    
    async get_data_comuna(anio,codigo_comuna) {
      let url = this.data_view.url_api[0]
      let url_api = API_URL+url+'?codigo_comuna='+codigo_comuna+'&anio_data='+anio
      let response = await axios.get(url_api)
      let data = response.data.results[0]

      this.data_comuna_ejecucion = data.porcentaje_gasto
      this.data_comuna_gasto = '$'+formatNumber(data.gastos)
      this.data_comuna_ingresos = '$'+formatNumber(data.ingresos)
    },

    async get_data_comuna_anios(codigo_comuna) {
      let url = this.data_view.url_api[0]
      let url_api = API_URL+url+'?codigo_comuna='+codigo_comuna
      let response = await axios.get(url_api)
      let data = response.data.results

      this.order_by_anio(data)
      this.data_column_chart = this.get_data_column_chart(data)
    },

    async get_data_mapa_region(region,anio) {
      let url = this.data_view.url_api[1];
      let url_api = API_URL_MAPS+url+'?region='+region+'&anio_data='+anio
      let response = await axios.get(url_api)
      let data = response.data.results
      this.data_mapa_region = data
    },

    order_by_anio(data){
       data.sort(function (a,b) {
        return a.anio_data - b.anio_data
      });
    },

    get_data_column_chart(data){
      let data_ingresos = []
      let data_gastos = []
      let categories = []
      let colors = ['#E1C16E','#CD7F32']
      
      for (let element of data) {
        if (!categories.includes(element.anio_data)) {
          categories.push(element.anio_data)
          data_ingresos.push(element.ingresos)
          data_gastos.push(element.gastos)
        }
      }

      return {
        series: [
          {
            data: data_gastos,
            name: 'Gastos'
          },
          {
            data: data_ingresos,
            name: 'Ingresos'
          }
        ],
        categories: categories,
        colors: colors,
        unidad: 'Millones de Pesos',
        tipo_unidad:'monto',
        tipo_categoria: 'anio'
      }
    }, 

    updateFiltro(newValue, indexFiltro) {

      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
      }
      this.get_data_comuna(this.selected_filtro_1, this.codigo_comuna)
      this.get_data_mapa_region(this.region_selected, this.selected_filtro_1)

    }
  }
}
</script>

