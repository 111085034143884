<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col xl=4 sm=5>
          <v-row>
            <v-col cols=6>
              <info-card-simple 
                :icon="'mdi-information'"
                :titulo="region_detail.nombre"
                :valor="data_region"
                :tipo_valor="selected_filtro_2.unidad"
              />
            </v-col>
            <v-col cols=6>
              <info-card-simple 
                :icon="'mdi-information'"
                :titulo="'País'"
                :valor="data_pais"
                :tipo_valor="selected_filtro_2.unidad"
              />
            </v-col>
          </v-row>
          <v-row style="margin-top:-15px;">
            <v-col>
              <importacion-exportacion-3-d-map 
                v-if="data_mapa_region != null"
                :coordenadas_region="[ region_detail.lon, region_detail.lat]"
                :codigo_region="region_detail.numero_region"
                :data_mapa="data_mapa_region"
                :height_mapa="size_mapa.height_card"
                :data_tipos_puntos="data_tipos_puntos"
                :m_top_map="size_mapa.margin_top"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col xl=8 sm=7>
          <v-row>
            <v-col>
              <info-card-column-chart 
                :icon="'mdi-chart-bar'"
                :titulo="'Comparación Regional - '+selected_filtro_1"
                :height_chart="size_column_chart.height_chart"
                :height_card="size_column_chart.height_card"
                :data="data_column_chart"
              />
              <info-card-tree-map 
                class="mt-2"
                :icon="'mdi-chart-tree'"
                :titulo="'Puntos de Control'"
                :subtitulo="region_detail.nombre"
                :height_card="size_tree_map.height_card"
                :height_chart="size_tree_map.height_chart"
                :data="data_tree_map"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
const API_URL_MAPS = 'https://api.datari.net/datari/mapas/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardColumnChart from '../../components/infoCardColumnChart.vue';
import InfoCardTreeMap from '../../components/infoCardTreeMap.vue';
import ImportacionExportacion3DMap from '../../components/maps/ImportacionExportacion3DMap.vue';

export default {
  name: 'View18Region',
  components: { HeaderComponent, InfoCardSimple, InfoCardColumnChart, InfoCardTreeMap,   ImportacionExportacion3DMap},
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      selected_filtro_2: this.data_view.filtros[1].data[0],
      data_region: null,
      data_pais: null,
      data_tipos_puntos:null,
      data_column_chart:null,
      data_tree_map: null,
      data_mapa_region: null
    }
  },
  created() {
    this.get_data(this.region_selected, this.selected_filtro_1, this.selected_filtro_2)
    this.get_mapa_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2)
  },
  computed : {
    size_mapa () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl') {
        return {
          height_card:628,
          margin_top: -25
        }
      }
      else {
        return {
          height_card: 433,
          margin_top: -25
        }
      }
    },
    size_column_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 327,
          height_chart: 270
        }
      }
      else {
        return {
          height_card: 307,
          height_chart: 260
        }
      }
    },
    size_tree_map () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 440,
          height_chart: 350
        }
      }
      else {
        return {
          height_card: 255,
          height_chart: 185
        }
      }
    },
  },
  watch: {
    region_selected() {
      this.get_data(this.region_selected, this.selected_filtro_1, this.selected_filtro_2)
      this.get_mapa_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2)
    },

    data_view () {
      this.selected_filtro_1 = this.data_view.filtros[0].data[0]
      this.selected_filtro_2 = this.data_view.filtros[1].data[0]
      this.get_data(this.region_selected, this.selected_filtro_1, this.selected_filtro_2)
      this.get_mapa_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2)
    }
  },
  methods: {

    async get_data(region, anio, tipo_dato) {
      let tipo = this.data_view.tipo_data
      let url = this.data_view.url_api[0];
      let  url_api = API_URL+url+'?region='+region+'&tipo='+tipo.value+'&tipo_dato='+tipo_dato.value+'&anio='+anio
      let response = await axios.get(url_api)
      let data = response.data
      let data_region = data.data_region
      let data_pais = data.data_pais
      let data_regiones = data.data_regiones
      let data_puntos_region = data.results
      
      this.data_tipos_puntos = data.data_region_puntos
      
      this.data_region = tipo_dato.value === 'monto' ? 
                         '$'+formatNumber(data_region.total_anual/1000000) :
                         formatNumber(data_region.total_anual)

      this.data_pais = tipo_dato.value === 'monto' ? 
                        '$'+formatNumber(data_pais.total_anual/1000000) :
                        formatNumber(data_pais.total_anual)

      this.data_column_chart = this.get_data_column_chart(data_regiones, tipo)

      this.order_by_valor(data_puntos_region)
      this.data_tree_map = this.get_data_tree_map(data_puntos_region, tipo)
    },

    async get_mapa_region(region, anio, tipo_dato) {
      let tipo = this.data_view.tipo_data
      let url = this.data_view.url_api[1];
      let url_api = API_URL_MAPS+url+'?region='+region+'&tipo='+tipo.value+'&tipo_dato='+tipo_dato.value+'&anio='+anio
      let response = await axios.get(url_api)
      let data = response.data.results
      this.data_mapa_region = data
    },

    order_by_valor(data){
      
       data.sort(function (a,b) {
        return b.valor - a.valor
      });
    },

    get_data_tree_map(data,tipo){
      let series = []

      for (let element of data) {
        let punto_control = element.punto_ingreso_salida.nombre
        let valor = element.valor
        
        if (punto_control === 'CRISTO REDENTOR DE LOS ANDES (Libertadores)') {
          punto_control = 'LIBERTADORES'
        }

        else if( punto_control === 'INTEGRACION AUSTRAL (MONTE AYMOND)') {
          punto_control = 'MONTE AYMOND'
        }

        else if (punto_control === 'CONCORDIA (CHACALLUTA)'){
          punto_control = 'CHACALLUTA'
        }

        let data_element = {
          x: punto_control,
          y: valor
        }

        series.push(data_element)
      }
      return {
        data: series,
        distributed: false,
        nombre_data: tipo.text,
        unidad: this.selected_filtro_2.unidad,
        tipo_unidad:this.selected_filtro_2.value,        
      }
    },
  
    get_data_column_chart(data,tipo){
      let series = []
      let categories = []
      let colors = []
      
      for (let element of data) {
        if(element.region.id != 18){
          if (!categories.includes(element.region.nombre)) {
            let category = element.region.nombre.includes('Arica') ? 
                            ['Arica','Parinacota'] : 
                            element.region.nombre;
            
            let dato = element.data_region.total_anual
            categories.push(category);
            series.push(dato)

            if (element.region.id == this.region_selected) {
              colors.push('#398104');
            }
            else{
              colors.push("#898989");
            }
          }
        }
      }

      return {
        series: [
          {
            data: series,
            name: tipo.text
          }
        ],
        categories: categories,
        colors: colors,
        unidad: this.selected_filtro_2.unidad,
        tipo_unidad:this.selected_filtro_2.value == 'monto' ? 'monto' : 'no_monto',
        tipo_categoria: 'regiones'
      }
    },

    updateFiltro(newValue, indexFiltro) {

      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
      }

      else if (indexFiltro == 1) {
        this.selected_filtro_2 = newValue
      }
      this.get_data(this.region_selected, this.selected_filtro_1, this.selected_filtro_2)
      this.get_mapa_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2)

    }
  }
}
</script>