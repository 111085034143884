<template>
  <div>
    <v-card flat>
      <v-card-title>
        <v-icon
        medium
        left
        >
        {{icon}}
        </v-icon>
        <h5 v-if="is_responsive" class="font-weight-medium">{{titulo}}</h5>  
        <h4  v-else class="font-weight-medium">{{titulo}}</h4>
      </v-card-title>
      <v-card-subtitle class="text-left">
          <h3 class="font-weight-medium">{{subtitulo}}</h3>
      </v-card-subtitle>
      <v-card-text class="mt-0">
          <h2 class="font-weight-bold" v-if="is_responsive" style="margin-top:-6px;">{{valor}}</h2>
          <h1 class="font-weight-bold" v-else style="margin-top:5px;">{{valor}}</h1>
          <h3 class="font-weight-medium mt-2 ">{{tipo_valor}}</h3>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name:'infoCardSimple',
  props:[
    'icon', 'titulo','subtitulo','valor','tipo_valor'
  ],
  computed: {
    is_responsive () {
      if( this.$vuetify.breakpoint.name != 'xl'){
        return true
      }
      else {
        return false
      }
    }
  }
}
</script>

<style>

</style>