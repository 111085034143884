<template>
  <div>
    <v-chip v-for="tipo in data_tipos_puntos"
      dark 
      :key="tipo.tipo" 
      :color="tipo.color"
      class="mr-2 tipos" 
      small
    > 
      <div v-if="is_responsive">
        <h5 class="font-weight-medium">{{tipo.tipo}}</h5>
      </div>
      <div v-else>
        <h3 class="font-weight-medium">{{tipo.tipo}}</h3>
      </div>
    </v-chip>
    <v-card flat :height="height_mapa"> 
      <div id="mapContainer" class="basemap" :style="'margin-top:'+m_top_map+'px;'"></div>
      <div id="tooltip"></div>
    </v-card>
  </div>
</template>

<style>
.basemap {
  width: 100%;
  height: 100%;
}
</style>

<style>
.tipos {
  z-index: 1;
  top:15px;
}
</style>

<style >
#tooltip {
  font-family: Helvetica, Arial, sans-serif;
  position: absolute;
  padding: 4px;
  margin: 8px;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  max-width: 300px;
  font-size: 14px;
  z-index: 9;
  pointer-events: none;
  height: 280px !important;
  width: 280px !important;
}
</style>

<script>
import mapboxgl from "mapbox-gl";
import {ColumnLayer} from '@deck.gl/layers';
import {MapboxLayer} from '@deck.gl/mapbox';
import {getZoomRegion, formatNumeroRegion} from '@/functions/functions.js'

export default {
  name: 'MapTransporteTerritorial',
  props: [
    'coordenadas_region',
    'codigo_region',
    'height_mapa',
    'm_top_map',
    'data_mapa',
    'data_tipos_puntos'
  ],
  data () {
    return {
      accessToken: 'pk.eyJ1IjoibGFiZGV2MjIiLCJhIjoiY2tzaHFvamRtMXdwZDJ1b2E2ZHRrMDNvYyJ9.A2-aL3_dJ9BIK26yO0Ahug',
      mapa_region:null,
    }
  },
  mounted () {
    const zoom_region = getZoomRegion(this.codigo_region)
    const coordenadas_region = this.coordenadas_region
    const codigo_region = formatNumeroRegion(this.codigo_region)
    this.mapa_region = this.get_base_map(coordenadas_region, zoom_region)
    this.add_regionSource(this.mapa_region);
    this.add_regionLayer(this.mapa_region,codigo_region)
    this.add_dataLayer(this.mapa_region, this.data_mapa)
  },
  watch: {
    data_mapa() {
      const zoom_region = getZoomRegion(this.codigo_region)
      const coordenadas_region = this.coordenadas_region
      const codigo_region = formatNumeroRegion(this.codigo_region)
      this.mapa_region = this.get_base_map(coordenadas_region, zoom_region)
      this.add_regionSource(this.mapa_region);
      this.add_regionLayer(this.mapa_region,codigo_region)
      this.add_dataLayer(this.mapa_region, this.data_mapa)

      
    }
  },
  computed: {
    is_responsive() {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl') {
        return false
      }
      else {
        return true
      }
    }
  },
  methods: {

    add_dataLayer(mapa, data) {
      let dataLayer = this.create_data_layer(data)
      mapa.on('load', () => {
          mapa.addLayer(dataLayer)
      })
    },

    create_data_layer(data) {
      return new MapboxLayer({
        id: 'DataLayer',
        type: ColumnLayer,
        data: data,
        angle: 20,
        coverage: 1,
        diskResolution: 100,
        elevationScale: 60,
        extruded: true,
        getElevation: d => d.properties.participacion * 25 ,
        getFillColor: d => d.properties.color,
        getLineColor: [0, 0, 0],
        getLineWidth: 10,
        getPosition: d => d.geometry.coordinates,
        radius: 2500,
        pickable: true,
        controller: true,
        highlightColor: [0, 0, 128, 128],
        autoHighlight: true,
        onHover: ({object,x,y}) => {
          const tooltip = document.getElementById('tooltip');

          function formatCL(val){
            var valor = val.toLocaleString();
            var i;
            var stringFormat = ''; 
            for(i of valor){
              if( i === '.' ){
                i = ',';
                stringFormat+=i;
              }
              else if(i ===','){
                i = '.';
                stringFormat+=i;
              }
              else{
                stringFormat+=i;
              }
            }
            return stringFormat;
          }

          if(object){
            let nombre = object.properties.nombre;
            let valor = object.properties.valor;
            let tipo = object.properties.tipo_dato;
            let tipo_punto = object.properties.tipo_punto;
            let tipo_valor = object.properties.tipo_valor;
            let anio = object.properties.año;
            let participacion = object.properties.participacion;
            let unidad;

            if(tipo_valor.includes('Peso')){
              unidad = 'Toneladas';
              valor = formatCL(valor)
            }
            else {
              unidad = 'Millones de Dólares'
              valor = valor/1000000
              valor = '$'+formatCL(valor)
            }

            tooltip.style.top = `${y}px`;
            tooltip.style.left = `${x+20}px`;
            tooltip.innerHTML = `
              <div class="title text-left ml-3"><b>${nombre} &nbsp;</b></div>
              <div class="text-left ml-3"><b>${tipo_punto}</b></div>
              <div class="text-left subtitle-1 ml-3 mt-2"><b>${tipo} - ${anio}</b></div>
              <div class="text-left ml-3 mt-0">${tipo_valor}</div>
              <div class="text-left ml-3 title"><b>${valor}</b></div>
              <div class="text-left ml-3 mt-0 subtitle-2">${unidad}</div>
              <div class="text-left ml-3 mt-4"><b>Participación Regional</b></div>
              <div class="text-left ml-3 title">${participacion} %</div>
              
            `;
            tooltip.style.visibility = "visible";
          }
          else {
            tooltip.style.visibility = "hidden";
            tooltip.innerHTML = '';
          }            
        }
      });
    },


    get_base_map(coordenadas, zoom) {
      mapboxgl.accessToken = this.accessToken;
      let basemap = new mapboxgl.Map({
        container: "mapContainer",
        style: "mapbox://styles/mapbox/light-v10",
        center: coordenadas,
        zoom: zoom+0.8,
        pitch: 65,
        bearing: 45,
        antialias: true
      });

      basemap.addControl(new mapboxgl.NavigationControl());
      basemap.addControl(new mapboxgl.FullscreenControl());

      return basemap;
    },

    add_regionSource(mapa){
       mapa.on('load', () => {
        mapa.addSource('map_regiones', {
          type: 'vector',
          url: 'mapbox://labdev22.azm906hb',
        });
      });
    },

    add_regionLayer(mapa,codigo_region){
      mapa.on('load', () => {
        mapa.addLayer({
          id:'region',
          source: 'map_regiones',
          'source-layer': 'chile_regiones_comunas-6uwztq',
          type: 'fill',
          paint: {
            // 'fill-color': '#5D6D7E',
            'fill-color': [
              'match', ['get','codigo_region'],
              codigo_region,  '#191970',
              '#5D6D7E'
            ],
            'fill-opacity':0.25,
            'fill-outline-color': '#00194c'
          },
        });

        mapa.addLayer({
          'id': 'borde-regiones',
          'type': 'line',
          source: 'map_regiones',
          'source-layer': 'chile_regiones_comunas-6uwztq',
          'paint': {
            'line-color': '#414141',
            'line-width': 0.5,
            'line-opacity': 0.4
          },
        });
      });
    },
  }
}
</script>

<style>

</style>