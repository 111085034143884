<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col xl=3 sm=3>
          <info-card-simple
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :valor="data_region"
            :tipo_valor="'Vehículos'"
          />
      
          <info-card-simple
            class="mt-2"
            :icon="'mdi-information'"
            :titulo="'País'"
            :valor="data_pais"
            :tipo_valor="'Vehículos'"
          />
          
          <info-card-donut-chart 
            class="mt-2"
              :height_card="size_donut_chart.height_card"
              :titulo="'Tipo de Motor'"
              :subtitulo="'Cant. Vehículos'" 
              :icon="'mdi-chart-donut'"         
              :data="data_donut_chart"
              :height_chart="size_donut_chart.height_chart"
              :width_chart="size_donut_chart.width_chart"
          />
        </v-col>
        <v-col xl=9 sm=9>
          <info-card-sankey 
            :icon="'mdi-chart-sankey'"
            :titulo="'Comparación Regional'"
            :height_chart="size_sankey_chart.height_chart"
            :height_card="size_sankey_chart.height_card"
            :data="data_sankey"
          />

          <info-card-column-chart 
            class="mt-2"
            :icon="'mdi-chart-bar'"
            :titulo="'Variación Anual'"
            :subtitulo="'Vehículo Catalitico y No Catalitico'"
            :height_chart="size_column_chart.height_chart"
            :height_card="size_column_chart.height_card"
            :data="data_column_chart"
          />
          
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardDonutChart from '../../components/infoCardDonutChart.vue';
import InfoCardColumnChart from '../../components/infoCardColumnChart.vue';
import InfoCardSankey from '../../components/infoCardSankey.vue';

export default {
  name: 'View14Region',
  components: { HeaderComponent, InfoCardSimple, InfoCardDonutChart, InfoCardColumnChart, InfoCardSankey},
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      data_region:null,
      data_pais:null,
      data_donut_chart: null,
      data_column_chart: null,
      data_sankey: null
    }
  },
  created() {
    this.get_data_region_anio(this.region_selected, this.selected_filtro_1)
    this.get_data_region_anios(this.region_selected)
    this.get_data_regiones(this.selected_filtro_1)
  },
  computed : {
    size_sankey_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 520,
          height_chart: 470
        }
      }
      else {
        return {
          height_card: 327,
          height_chart: 270
        }
      }
    },
    size_column_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 250,
          height_chart:180
        }
      }
      else {
        return {
          height_card: 240,
          height_chart: 170
        }
      }
    },
     size_donut_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 487,
          height_chart: 540,
          width_chart:500
        }
      }
      else {
        return {
          height_card: 305,
          height_chart: 330,
          width_chart:300
        }
      }
    }
  },
  watch: {
    region_selected() {
      this.get_data_region_anio(this.region_selected, this.selected_filtro_1)
      this.get_data_region_anios(this.region_selected)
    }
  },
  methods: {

    async get_data_region_anio(region, anio) {

      let url = this.data_view.url_api[0];
      let  url_api = API_URL+url+'?region='+region+'&anio_data='+anio
      let response = await axios.get(url_api)
      let data = response.data

      let data_region = data.results[0]
      this.data_region = formatNumber(data_region.total)

      let data_pais = data.total_pais
      this.data_pais = formatNumber(data_pais)

      let data_donut_chart = {
        series: [data_region.bencina, data_region.gas, data_region.electrico, data_region.diesel ],
        labels: ['Bencina', 'Gas', 'Eléctrico','Diesel'],
        unidad: 'Vehículos',
        tipo_unidad: 'no_monto',
        colors:  ["#138D75",'#FBC02D','#304FFE','#616A6B']
      }

      this.data_donut_chart = data_donut_chart
    },

    async get_data_regiones(anio) {
      let url = this.data_view.url_api[0];
      let  url_api = API_URL+url+'?anio_data='+anio
      let response = await axios.get(url_api)
      let data = response.data
      let nodes = data.nodes_sankey
      let links = data.links_sankey

      this.data_sankey = {
        nodes: nodes,
        links: links,
        unidad: 'Vehículos'
      }

      
    },

    async get_data_region_anios(region) {
      let url = this.data_view.url_api[2];
      let  url_api = API_URL+url+'?region='+region
      let response = await axios.get(url_api)
      let data = response.data.results

      this.data_column_chart = this.get_data_column_chart(data)
  },

    get_data_column_chart(data){
      let data_cat = []
      let data_nocat = []
      let categories = []
      let colors = ['#E1C16E','#CD7F32']
      
      for (let element of data) {
        if (!categories.includes(element.anio_data)) {
          categories.push(element.anio_data)
          data_cat.push(element.catalitico)
          data_nocat.push(element.no_catalitico)
        }
      }

      return {
        series: [
          {
            data: data_cat,
            name: 'Cataliticos'
          },
          {
            data: data_nocat,
            name: 'No Cataliticos'
          }
        ],
        categories: categories,
        colors: colors,
        unidad: 'Vehículos',
        tipo_unidad:'no_monto',
        tipo_categoria: 'anio'
      }
    }, 
    
    updateFiltro(newValue, indexFiltro) {

      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
      }
      this.get_data_region_anio(this.region_selected, this.selected_filtro_1)
      this.get_data_regiones(this.selected_filtro_1)
    }
  }
}
</script>