var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"rounded",attrs:{"color":"light-blue darken-2","flat":"","dark":""}},[_c('v-toolbar-title',[(this.$vuetify.breakpoint.name === 'xl')?_c('h3',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.titulo))]):_c('h4',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.titulo))])]),(_vm.filtros.length != 0)?_c('div',{staticClass:"ml-5"},[_c('v-row',[_vm._l((_vm.filtros.slice(0,2)),function(filtro,index){return _c('v-col',{key:index,attrs:{"xl":filtro.text === 'Año' && _vm.filtros.length > 1 ? 3 : 
                filtro.text === 'Año'  && _vm.filtros.length == 1 ? 5 : 
                filtro.text !== 'Año' && _vm.filtros.length > 1 ? 4 : 
                filtro.text !== 'Año' && _vm.filtros.length == 1 ? 8 : 4,"sm":filtro.text === 'Año'  && _vm.filtros.length > 1 ? 3 :
                filtro.text === 'Año'  && _vm.filtros.length == 1 ? 5 : 
                filtro.text !== 'Año' && _vm.filtros.length >1 ? 5 :
                filtro.text !== 'Año' && _vm.filtros.length == 1 ? 6 : 5}},[_c('v-select',{attrs:{"value":filtro.data[0],"items":filtro.data,"label":filtro.text,"hide-no-data":"","hide-details":"","flat":"","solo-inverted":"","dense":"","item-value":"id","item-text":"text","return-object":""},on:{"change":function($event){return _vm.changeItem($event,index)}}})],1)}),(_vm.filtros.length > 2)?_c('v-col',{attrs:{"xl":"1","sm":"1"}},[_c('v-bottom-sheet',{attrs:{"hide-overlay":"","width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"light-blue darken-4","dark":"","elevation":"1"}},'v-btn',attrs,false),on),[_vm._v(" +Filtros ")])]}}],null,false,1503149759),model:{value:(_vm.mas_filtros),callback:function ($$v) {_vm.mas_filtros=$$v},expression:"mas_filtros"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"130px","rounded":""}},[_c('div',{staticClass:"centrar",staticStyle:{"width":"100%"}},_vm._l((_vm.filtros.slice(2)),function(filtro,index){return _c('div',{key:index,staticClass:"mt-4 ml-2 mr-2"},[_c('v-select',{attrs:{"value":filtro.data[0],"items":filtro.data,"label":filtro.text,"hide-no-data":"","hide-details":"","outlined":"","dense":"","item-value":"id","item-text":"text","return-object":""},on:{"change":function($event){return _vm.changeItem($event,index+2)}}})],1)}),0),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"error"},on:{"click":function($event){_vm.mas_filtros = !_vm.mas_filtros}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")]),_c('span',{staticClass:"ml-1"},[_vm._v("Cerrar")])],1)],1)],1)],1):_vm._e()],2)],1):_vm._e(),_c('v-spacer'),_c('div',{attrs:{"id":"fuente"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v("Fuente: "+_vm._s(_vm.fuente)+" ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }