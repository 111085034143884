<template>
  <div>
    <v-card flat :height="height_card">
      <v-card-title>
        <v-icon
        medium
        left
        >
        {{icon}}
        </v-icon>
        <h4 class="font-weight-medium">{{titulo}}</h4>  
      </v-card-title>
      <v-card-subtitle class="text-left">
          <h3 class="font-weight-medium">{{subtitulo}}</h3>
      </v-card-subtitle>
      <v-card-text style="margin-top:-25px;">
        <ranking-chart 
          v-if="data != null"
          :data_chart="data_chart"
          :categories_chart="labels_chart"
          :height_chart="height_chart"
          :colors_chart="colors_chart"
          :unidad_chart="unidad_chart"
          :tipo_unidad_chart="tipo_unidad_chart"
          :nombre_data="nombre_data"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RankingChart from './charts/RankingChart.vue'
export default {
  components: { RankingChart },
  name:'infoCardRankingChart',
  props:[
    'icon', 'titulo','subtitulo',
    'data',
    'height_card', 'height_chart'
  ],
  computed:{
    data_chart () {
      return this.data.series
    },
    labels_chart () {
      return this.data.categories
    },
    colors_chart () {
      return this.data.colors
    },
    unidad_chart () {
      return this.data.unidad
    },

    tipo_unidad_chart () {
      return this.data.tipo_unidad
    },

    nombre_data () {
      return this.data.nombre_data
    }
  }
}
</script>