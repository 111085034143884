import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js';
import Dashboard from '../views/Dashboard.vue'
import Login from '../views/Login.vue'
import Reset from '../views/ResetPassword.vue'
import ChangePassword from '../views/ChangePassword.vue'
import VerifyEmail from '../views/VerifyEmail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/reset_password',
    name:'ResetPassword',
    component: Reset
  },
  {
    path: '/verify_email',
    name:'VerifyEmail',
    component: VerifyEmail,
    props: (route) => ({
      email: route.query.email,
    })
  },
  {
    path: '/change_password',
    name: 'ChangePassword',
    component: ChangePassword,
    props: (route) => ({
      //Token para cambio de contraseña según sea correo valido.
      token: route.query.token,
      uidb64: route.query.uidb64
    })
  },
  {
    path: '/',
    component: Dashboard,
    props:true,
    children: [
      {
        path: '',
        component: () => import('../views/Home.vue'),
        props:true
      },
      {
        path: 'categoria-:categoria/:url',
        component: () => import('../views/GenerarDatos.vue'),
        props:true
      },
      {
        path: 'admin_dashboard/',
        component: () => import('../views/AdminDashboard.vue'),
        props:true

      },
    ],
    meta: {
      requiresAuth: true
    },
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login') 
  } else {
    next() 
  }
})

export default router
