import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
const API_URL = 'https://api.datari.net/datari/data/'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		status: '',
		token: localStorage.getItem('token') || null,
		user: {},
		regiones: [],
		comunas: [],
		categorias: []
	},
  mutations: {
		auth_request(state){
			state.status = 'cargando...'
		},
		auth_success(state,token,user){
			state.status = 'autorizado'
			state.token = token
			state.user = user
		},
		auth_error(state){
			state.status = 'error'
		},
		register_request(state){
			state.status = 'cargando...'
		},
		register_success(state){
			state.status = 'registrado'
		},register_error(state){
			state.status = 'error'
		},
		SET_REGIONES (state, regiones) {
            state.regiones = regiones
        },
		SET_COMUNAS (state, comunas){
			state.comunas = comunas
		},
		SET_CATEGORIAS (state, categorias){
			state.categorias = categorias
		},
		logout(state){
			state.status = ''
			state.token = ''
		},
	},
  actions: {
		register({commit},user){
			return new Promise((resolve,reject) => {
				commit('register_request')
				axios({url:'https://api.datari.net/auth/register/', data: user, method: 'POST'})
				.then(resp => {
					commit('register_success')
					resolve(resp)
				})
				.catch(err => {
					commit('register_error')
					reject(err)
				})	
			})
		},
    login({commit},user){
		return new Promise((resolve,reject) => {
			commit('auth_request')
			axios({url:'https://api.datari.net/auth/login/', data: user, method: 'POST'})
			.then(resp => {
				let data_user = resp.data;
				
				let email = data_user.email;
				let username = data_user.username;
				let is_staff = data_user.is_staff;
				let user_type = data_user.user_type;
				const token = data_user.token['access'];
				const token_expires_in = data_user.token['expires_in']
				localStorage.setItem('token',token);
				localStorage.setItem('token_expires_in',token_expires_in)
				localStorage.setItem('is_staff',is_staff); 
				localStorage.setItem('user_type',user_type);
				localStorage.setItem('user_name',username);
				localStorage.setItem('user_email',email);
				axios.defaults.headers.common['Authorization'] = "Token " + token
				commit('auth_success', token, user)
				resolve(resp)
			})
			.catch(err => {
				commit('auth_error')
				localStorage.removeItem('token')
				localStorage.removeItem('is_staff');
				localStorage.removeItem('user_type');
				localStorage.removeItem('user_name');
				localStorage.removeItem('user_email');
				reject(err)
			})
		})
	},
  logout({commit}){
		return new Promise((resolve) => {
			commit('logout')
			localStorage.removeItem('is_staff')
			localStorage.removeItem('token')
			localStorage.removeItem('user_type');
			localStorage.removeItem('user_name');
			localStorage.removeItem('user_email');
			delete axios.defaults.headers.common['Authorization']
			resolve()
		})
	},
	async getRegiones ({ commit }) {
		try {
			let url = API_URL+'regiones/';
			const response = await axios.get(url);
			let data_regiones = response.data.results;
			let regiones = data_regiones.filter( r => r.id != 17 && r.id != 18);
			commit('SET_REGIONES', regiones)
		
		}
		catch (error) {
			console.log(error);
		}
	},
	async getComunas ({ commit }) {
		try {
			let url = API_URL+'comunas/';
			const response = await axios.get(url);
			let data_comunas = response.data.results;
			commit('SET_COMUNAS',data_comunas)
			
		}
		catch (error) {
			console.log(error)
		}
	},
	async getCategorias({commit}) {
		try {
			let url = API_URL+'categorias/';
			const response = await axios.get(url);
			let data_categorias = response.data.results
			commit('SET_CATEGORIAS',data_categorias)
		}
		catch (error) {
			console.log(error)
		}
	}
  },
  modules: {
  },
  getters: {
		isLoggedIn: state => !!state.token,
		authStatus: state => state.status,
		userLogged: state => state.user,
		regiones: state => {
			return state.regiones;
		},
		comunas: state => {
			return state.comunas;
		},
		categorias: state => {
			return state.categorias;
		}
	}
})


