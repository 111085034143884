<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col xl=3 sm=3>
          <info-card-simple 
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Superficie al año '+selected_filtro_1"
            :valor="data_region"
            :tipo_valor="tipo_dato"
          />
          <info-card-simple 
            class="mt-2"
            :icon="'mdi-information'"
            :titulo="'País'"
            :subtitulo="'Superficie al año '+selected_filtro_1"
            :valor="data_pais"
            :tipo_valor="tipo_dato"
          />
        </v-col>
        <v-col xl=9 sm=9>
          <info-card-line-chart 
            :titulo="'Variación Superficie'"
            :subtitulo="region_detail.nombre"
            :icon="'mdi-trending-up'"
            :data="data_line_chart"
            :height_chart="height_line_chart.height_chart"
            :height_card="height_line_chart.height_card"
          />
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px;">
        <v-col>
          <info-card-tree-map 
            :icon="'mdi-chart-tree'"
            :titulo="'Superficies Regionales'"
            :height_card="height_tree_map.height_card"
            :height_chart="height_tree_map.height_chart"
            :data="data_tree_map"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardLineChart from '../../components/infoCardLineChart.vue';
import InfoCardTreeMap from '../../components/infoCardTreeMap.vue';

export default {
  components: {HeaderComponent, InfoCardSimple, InfoCardLineChart, InfoCardTreeMap},
  name: 'View3Region',
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      data_pais:null,
      data_region:null,
      tipo_dato: null,
      data_line_chart:null,
      data_tree_map:null
    }
  },
  created () {
    this.get_data_region_anio(this.region_selected, this.selected_filtro_1)
    this.get_data_region(this.region_selected)
    this.get_data_regiones_anio(this.selected_filtro_1)
  },
  computed: {
    height_line_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl') {
        return {
          height_chart:  232,
          height_card:326
        }
      }
      else {
        return {
          height_chart:  215,
          height_card:307
        }
      }
    },

    height_tree_map () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl') {
        return {
          height_chart: 380,
          height_card: 430
        }
      }
      else {
        return {
          height_chart: 200,
          height_card: 255
        }
      }
    }
  },
  watch: {
    region_selected () {
      this.get_data_region_anio(this.region_selected, this.selected_filtro_1)
      this.get_data_region(this.region_selected)
      this.get_data_regiones_anio(this.selected_filtro_1)
    },

    data_view () {
      this.selected_filtro_1 = this.data_view.filtros[0].data[0];
      this.get_data_region_anio(this.region_selected, this.selected_filtro_1)
      this.get_data_region(this.region_selected)
      this.get_data_regiones_anio(this.selected_filtro_1)
    }
  },
  methods: {

    async get_data_region_anio(region,anio) {
      let url = this.data_view.url_api[0];
      let url_api = API_URL+url+'?region='+region+'&anio='+anio
      let response = await axios.get(url_api)
      let data_region = response.data.results[0]
      let data_pais = response.data.totalAnual

      this.data_region = formatNumber(data_region.superficie)
      this.data_pais = formatNumber(data_pais)
      this.tipo_dato = data_region.tipo_dato
    },

    async get_data_region(region) {
       let url = this.data_view.url_api[0];
      let url_api = API_URL+url+'?region='+region
      let response = await axios.get(url_api)
      let data_region = response.data.results
      this.order_by_anio(data_region)
      let data_line_chart = this.get_data_grafico_line_chart(data_region)
      this.data_line_chart = data_line_chart;
    },

    async get_data_regiones_anio(anio) {
      let url = this.data_view.url_api[0];
      let url_api = API_URL+url+'?anio='+anio
      let response = await axios.get(url_api)
      let data_regiones = response.data.results
      this.order_by_superficie(data_regiones)
      let data_tree_map = this.get_data_grafico_tree_map(data_regiones)
      this.data_tree_map = data_tree_map
    },

    order_by_superficie(data) {
      data.sort(function (a,b) {
        return b.superficie - a.superficie
      });
    },

    order_by_anio(data) {
      data.sort(function (a,b) {
        return a.anio - b.anio
      });
    },

    get_data_grafico_tree_map(data) {
      let series = []
      let colors = []

      for (let element of data) {
        let region = element.region.nombre
        let superficie = element.superficie

        let data_element = {
          x: region,
          y: superficie
        }
        
        series.push(data_element)


        if (element.region.id == this.region_selected) {
          colors.push('#4682B4')
        }
        else {
          colors.push('#89CFF0')
        }
      }

      let nombre_data = 'Superficie'
      let unidad = 'hectareas'
      let tipo_unidad = 'no_monto'
      let distributed = true

      return {
        data: series,
        colors: colors,
        nombre_data: nombre_data,
        unidad: unidad,
        tipo_unidad: tipo_unidad,
        distributed: distributed
      }

    },

    get_data_grafico_line_chart(data) {
      let series = []
      let categories = []

      for (let element of data) {
        if (!categories.includes(element.anio)) {
          categories.push(element.anio)
        }

        series.push(element.superficie)
      }

      let unidad = data[0].tipo_dato
      let tipo_unidad = 'no_monto'
      let nombre_data = 'Superficie'

      return {
        series: [
          {
            name: nombre_data,
            data: series
          }
        ],
        categories: categories,
        colors: ['#254cb4'],
        unidad: unidad,
        tipo_unidad: tipo_unidad
      }
    },
    
    updateFiltro(newValue, indexFiltro) {
      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
      }
      /* Funciones a actualizar */
      this.get_data_region_anio(this.region_selected, this.selected_filtro_1)
      this.get_data_regiones_anio(this.selected_filtro_1)
    }
  }
}
</script>

<style>

</style>