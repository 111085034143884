<template>
  <div :style="'height:'+height_chart+'px;width:100%'">
    <chart-sankey :data='chartData' :config='chartConfig'></chart-sankey>
  </div>
</template>

<script>
import { ChartSankey } from 'vue-d2b'

export default {
  props:['nodes','links','unidad','height_chart'],
  components: {
    ChartSankey
  },
  computed: {
    chartData() {
      return {
       nodes: this.nodes,
       links: this.links
      }
    }
  },
  data () {
    return {
      chartConfig (config) {
        config
          .sankey()
          .sankey()
          .nodePadding(10)

        config
          .sankey()
          .nodeDraggableY(true)

        config
          .nodeTooltip().html((d) => {
            function format_number(val){
              var valor = val.toLocaleString();
              var i;
              var stringFormat = ''; 
              for(i of valor){
                if( i === '.' ){
                  i = ',';
                  stringFormat+=i;
                }
                else if(i ===','){
                  i = '.';
                  stringFormat+=i;
                }
                else{
                  stringFormat+=i;
                }
              }
              return stringFormat;
            }

            const value = format_number(d.value);
            const unidad = d.data.unidad

            const tool = `<b>${d.data.name}:</b> ${value} ${unidad}`
            return tool
          })

        config
            .sankey()
            .sankey()
      }
    }
  }
}
</script>

<style>

</style>