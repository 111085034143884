<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col xl=3 sm=4>
          <info-card-simple 
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Mayor Ingreso: '+comuna_max_ingreso"
            :valor="data_comuna_max_ingreso"
            :tipo_valor="'Millones de Pesos'"
          />
          <info-card-simple 
            class="mt-2"
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Menor Ingreso: '+comuna_min_ingreso"
            :valor="data_comuna_min_ingreso"
            :tipo_valor="'Millones de Pesos'"
          />
        </v-col>
        <v-col xl=9 sm=8>
          <info-card-column-chart 
            :icon="'mdi-chart-bar'"
            :titulo="'Comparación Ingresos - '+selected_filtro_1"
            :subtitulo="'Capitales Regionales'"
            :height_chart="size_column_chart.height_chart"
            :height_card="size_column_chart.height_card"
            :data="data_column_chart"
          />
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px;">
         <v-col cols=7>
          <info-card-tree-map 
            :icon="'mdi-chart-tree'"
            :titulo="'Distribución Comunal'"
            :subtitulo="'Ingresos - '+selected_filtro_1"
            :height_card="size_tree_map.height_card"
            :height_chart="size_tree_map.height_chart"
            :data="data_tree_map"
          />
        </v-col>
        <v-col cols=5>
          <div>
            <v-card flat :height="ranking_chart.height_card">
              <v-card-title>
                <v-icon
                medium
                left
                >
                {{ranking_chart.icon}}
                </v-icon>
                <h4 class="font-weight-medium">{{ranking_chart.titulo}}</h4>  
              </v-card-title>
              <v-card-subtitle class="text-left">
                  <h3 class="font-weight-medium">{{ranking_chart.subtitulo}}</h3>
              </v-card-subtitle>
              <v-card-text style="margin-top:-25px;">
                <div id="chart">
                   <ranking-chart 
                    v-if="data_ranking_chart != null"
                      :data_chart="data_ranking_chart.series"
                      :categories_chart="data_ranking_chart.categories"
                      :height_chart="ranking_chart.height_chart"
                      :colors_chart="data_ranking_chart.colors"
                      :unidad_chart="data_ranking_chart.unidad"
                      :tipo_unidad_chart="data_ranking_chart.tipo_unidad"
                      :nombre_data="'Ingresos'"
                   />
                </div>
                <div id="controles" class="centrar">
                  <v-slide-group
                    v-model="opcion_ingreso"
                    dense
                    mandatory
                    v-on:change="seleccionarDataRanking"
                  >
                    <v-slide-item
                      v-for="(opcion,i) in data_view.opciones_ingresos"
                      :key="i"
                      v-slot="{ active, toggle }"
                    >
                    <div>
                        
                      <v-btn
                        class="ma-2"
                        small
                        :input-value="active"
                        :color="active ? 'blue darken-3 white--text' : 'grey lighten-2'"
                        depressed
                        @click="toggle"
                      >
                        {{opcion.text}}
                      </v-btn>
                    </div>
                    </v-slide-item>
                  </v-slide-group>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style>
div.centrar {
    display: flex;
    align-items: center;
    justify-content: center
}
</style>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardColumnChart from '../../components/infoCardColumnChart.vue';
import InfoCardTreeMap from '../../components/infoCardTreeMap.vue';
import RankingChart from '../../components/charts/RankingChart.vue';

export default {
  name: 'View20Region',
  components: { HeaderComponent, InfoCardSimple, InfoCardColumnChart, InfoCardTreeMap, RankingChart},
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      comuna_max_ingreso: '',
      data_comuna_max_ingreso:null,
      comuna_min_ingreso: '',
      data_comuna_min_ingreso: null,
      data_capitales:null,
      data_tree_map:null,
      data_column_chart: null,
      opcion_ingreso: 0,
      data_ranking_pais: null,
      data_ranking_chart:null
    }
  },
  created() {
    this.get_data_region(this.region_selected, this.selected_filtro_1)
    this.get_data_pais(this.selected_filtro_1)
  },
  computed : {
    size_tree_map () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 440,
          height_chart: 365
        }
      }
      else {
        return {
          height_card: 255,
          height_chart: 185
        }
      }
    },
    size_column_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 327,
          height_chart: 250
        }
      }
      else {
        return {
          height_card: 306,
          height_chart: 260
        }
      }
    },
    ranking_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen == 'xl') {
        return {
          icon:'mdi-format-list-bulleted',
          titulo: 'Ranking Nacional - '+this.selected_filtro_1,
          subtitulo: 'Comunas con '+this.data_view.opciones_ingresos[this.opcion_ingreso].data,
          height_card: 440,
          height_chart: 300
        }
      }
      else {
        return {
          icon:'mdi-format-list-bulleted',
          titulo: 'Ranking Nacional - '+this.selected_filtro_1,
          height_card: 255,
          height_chart: 150
        }
      }
    }
  },
  watch: {
    region_selected() {
      this.get_data_region(this.region_selected, this.selected_filtro_1)
      this.data_column_chart = this.get_data_column_chart(this.data_capitales)
      let data_ranking = this.get_data_ranking(this.data_ranking_pais, this.opcion_ingreso)
      this.data_ranking_chart = this.get_data_ranking_chart(data_ranking.slice(0,6))
    }
  },
  methods: {

    async get_data_region(region, anio) {
      let url = this.data_view.url_api[0]
      let url_api = API_URL+url+'?region='+region+'&anio_data='+anio
      let response = await axios.get(url_api)
      let data = response.data.results.data_region
      
      let data_max_ingreso_comuna  = data.max_ingresos_comuna
      let data_min_ingreso_comuna = data.min_ingresos_comuna
      
      this.comuna_max_ingreso = data_max_ingreso_comuna.comuna.comuna
      this.data_comuna_max_ingreso = '$'+formatNumber(data_max_ingreso_comuna.ingresos)

      this.comuna_min_ingreso = data_min_ingreso_comuna.comuna.comuna
      this.data_comuna_min_ingreso = '$'+formatNumber(data_min_ingreso_comuna.ingresos)
    
      let data_ingresos_comunas_region = data.list
      this.data_tree_map = this.get_data_tree_map(data_ingresos_comunas_region)
    },

    async get_data_pais(anio) {
      let url = this.data_view.url_api[0]
      let url_api = API_URL+url+'?anio_data='+anio
      let response = await axios.get(url_api)
      let data = response.data

      this.data_capitales = data.data_capitales
      this.data_column_chart = this.get_data_column_chart(this.data_capitales)

      this.data_ranking_pais = data.data_pais
      let data_ranking = this.get_data_ranking(this.data_ranking_pais, this.opcion_ingreso)
      this.data_ranking_chart = this.get_data_ranking_chart(data_ranking.slice(0,6))
    },

    get_data_ranking_chart(data) {
      let series = []
      let categories = []
      let colors = []

      for (let element of data) {
        if (!categories.includes(element.comuna.comuna)) {
          categories.push(element.comuna.comuna);
          series.push(element.ingresos)

          if (element.comuna.region === this.region_detail.nombre) {
            colors.push('#398104');
          }
          else{
            colors.push("#898989");
          }
          
        }
      }

      return {
        series: series,
        categories: categories,
        colors: ['#CD7F32'],
        unidad: 'Millones de Pesos',
        tipo_unidad:'monto',
      }

    },
  
    get_data_ranking(data,opcion) {
      let result =  opcion == 0 ? 
                    data.ranking_mayores_ingresos :
                    data.ranking_menores_ingresos
      
      return result
    },

    seleccionarDataRanking(opcion) {
      let data_ranking = this.get_data_ranking(this.data_ranking_pais, opcion)
      this.data_ranking_chart = this.get_data_ranking_chart(data_ranking.slice(0,6))
    },

    get_data_column_chart(data){
      let series = []
      let categories = []
      let colors = []
      
      for (let element of data) {
        if (!categories.includes(element.nombre)) {
          categories.push(element.nombre);
          series.push(element.ingresos)

          if (element.region === this.region_detail.nombre) {
            colors.push('#398104');
          }
          else{
            colors.push("#898989");
          }
        }
      }

      return {
        series: [
          {
            data: series,
            name: 'Ingresos'
          }
        ],
        categories: categories,
        colors: colors,
        unidad: 'Millones de Pesos',
        tipo_unidad:'monto',
        tipo_categoria: 'regiones'
      }
    }, 

    get_data_tree_map(data){
      let series = []

      this.order_by_ingresos(data)

      for (let element of data) {
        let comuna = element.comuna.comuna
        let valor = element.ingresos
        
        if (comuna === 'San Pedro de Atacama') {
          comuna = 'San Pedro'
        }

        let data_element = {
          x: comuna,
          y: valor
        }

        series.push(data_element)
      }

      return {
        data: series,
        distributed: false,
        nombre_data: 'Ingresos',
        unidad: 'Millones de Pesos',
        tipo_unidad:'monto',        
      }
    },

    order_by_ingresos(data){
       data.sort(function (a,b) {
        return b.ingresos - a.ingresos
      });
    },

    updateFiltro(newValue, indexFiltro) {

      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
      }
      this.get_data_region(this.region_selected, this.selected_filtro_1)
      this.get_data_pais(this.selected_filtro_1)

      

    }
  }
}
</script>