<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col>
          <info-card-simple 
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Gasto Regional'"
            :valor="data_region"
            :tipo_valor="'Millones de Pesos'"
          />
        </v-col>
        <v-col>
          <info-card-simple-bar 
            :icon="'mdi-information'"
            :titulo="'Participación Regional'"
            :subtitulo="'Gasto a nivel país'"
            :valor="data_region_pais"
            :nombre_valor="region_detail.nombre"
          />
        </v-col>
        <v-col>
          <info-card-simple 
            :icon="'mdi-information'"
            :titulo="'País'"
            :subtitulo="'Gasto Nacional'"
            :valor="data_pais"
            :tipo_valor="'Millones de Pesos'"
          />
        </v-col>
      </v-row>
      <v-row style="margin-top:-10px;">
        <v-col xl=6>
          <info-card-line-chart 
            :titulo="'Gasto Anual'"
            :subtitulo="region_detail.nombre"
            :icon="'mdi-trending-up'"
            :data="data_line_chart"
            :height_chart="size_line_chart.height_chart"
            :height_card="size_line_chart.height_card"
          />
        </v-col>
        <v-col xl=6>
          <info-card-tree-map
            :icon="'mdi-chart-tree'"
            :titulo="'Comparación Regional'"
            :subtitulo="'Gasto en Innovación'"
            :height_card="size_tree_map.height_card"
            :height_chart="size_tree_map.height_chart"
            :data="data_tree_map"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardSimpleBar from '../../components/infoCardSimpleBar.vue';
import InfoCardTreeMap from '../../components/infoCardTreeMap.vue';
import InfoCardLineChart from '../../components/infoCardLineChart.vue';

export default {
  name: 'View10Region',
  components: { HeaderComponent, InfoCardSimple, InfoCardSimpleBar, InfoCardTreeMap, InfoCardLineChart},
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      data_region: null,
      data_pais: null,
      data_region_pais: null,
      data_tree_map: null,
      data_line_chart:null
    }
  },
  created() {
    this.get_data_region_anio(this.region_selected, this.selected_filtro_1)
    this.get_data_regiones(this.selected_filtro_1)
    this.get_data_region(this.region_selected)
  },
  computed : {
    size_line_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 600,
          height_chart: 520
        }
      }
      else {
        return {
          height_card: 255,
          height_chart:190
        }
      }
    },
    size_tree_map () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 600,
          height_chart: 520
        }
      }
      else {
        return {
          height_card: 390,
          height_chart: 310
        }
      }
    }
  },
  watch: {
    region_selected() {
      this.get_data_region_anio(this.region_selected, this.selected_filtro_1)
      this.get_data_regiones(this.selected_filtro_1)
      this.get_data_region(this.region_selected)
    }
  },
  methods: {

    async get_data_region_anio(region, anio) {
      let url = this.data_view.url_api[0];
      let url_api = API_URL+url+'?region='+region+'&anio='+anio
      let response = await axios.get(url_api)
      let data = response.data
      let data_region = data.results[0]
      let data_pais = data.totalAnual
      this.data_region = formatNumber(data_region.gasto)
      this.data_pais = formatNumber(data_pais)
      this.data_region_pais = (data_region.porcentaje)
    },

    async get_data_regiones(anio) {
      let url = this.data_view.url_api[0];
      let url_api = API_URL+url+'?anio='+anio
      let response = await axios.get(url_api)
      let data_regiones = response.data.results
      this.order_by_gasto(data_regiones)
      let data_tree_map = this.get_data_grafico_tree_map(data_regiones)
      this.data_tree_map = data_tree_map
    },

    async get_data_region(region) {
      let url = this.data_view.url_api[0];
      let url_api = API_URL+url+'?region='+region
      let response = await axios.get(url_api)
      let data = response.data.results
      this.data_line_chart = this.get_data_line_chart(data)
    },

    get_data_line_chart(data) {
      
      let data_chart = []
      let categories = []

      for (let element of data) {
        if (!categories.includes(element.anio)) {
          categories.push(element.anio)
          data_chart.push(element.gasto)
        }
      }

      let unidad = 'Millones de Pesos'
      let tipo_unidad = 'monto'
      let nombre_data = 'Gasto en I+D'

      return {
        series: [
          {
            name: nombre_data,
            data: data_chart
          }
        ],
        categories: categories,
        colors: ['#398104'],
        unidad: unidad,
        tipo_unidad: tipo_unidad
      }
    },

    get_data_grafico_tree_map(data) {
      let series = []
      let colors = []

      for (let element of data) {
        let region = element.region.nombre
        let gasto = element.gasto

        let data_element = {
          x: region,
          y: gasto
        }
        
        series.push(data_element)


        if (element.region.id == this.region_selected) {
          colors.push('#4682B4')
        }
        else {
          colors.push('#89CFF0')
        }
      }

      let nombre_data = 'Gasto en Innovación'
      let unidad = 'Millones de Pesos'
      let tipo_unidad = 'monto'
      let distributed = true

      return {
        data: series,
        colors: colors,
        nombre_data: nombre_data,
        unidad: unidad,
        tipo_unidad: tipo_unidad,
        distributed: distributed
      }

    },

    order_by_gasto(data) {
      data.sort(function (a,b) {
        return b.gasto - a.gasto
      });
    },

    updateFiltro(newValue, indexFiltro) {

      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
      }

      this.get_data_region_anio(this.region_selected, this.selected_filtro_1)
      this.get_data_regiones(this.selected_filtro_1)

    }
  }
}
</script>
