<template>
  <div id="chart" class="chart-wrapper">
    <apexchart type="donut"  :width="width_chart" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
export default {
  name: 'DonutChart',
  props: [
    'data_chart','labels_chart','colors_chart',
    'width_chart',
    'tipo_unidad_chart','unidad_chart'
  ],
  computed: {
    series () {
      return this.data_chart
    },

    chartOptions () {
      return {
        chart: {
          type: 'donut',
        },
        labels: this.labels_chart,
        legend:{
          show:this.width_chart <= 350 ? false : true,
          position: 'bottom',
          horizontalAlign: 'center', 
          offsetX:-25,
          offsetY:10,
          width:0,
          height:30,
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
        },
        dataLabels:{
          formatter: function (val) {
            let result = val > 5 ? val.toFixed(1)+'%' : ''
            return result
        },
        },
        colors: this.colors_chart,
        unidad: this.unidad_chart,
        tipo_unidad: this.tipo_unidad_chart,
        responsive: [{
          breakpoint: 1500,
          options: {
            chart: {
              width: this.width_chart
            },
            legend: {
              show:false,
              position: 'bottom'
            },
            dataLabels:{
              enabled:false,
              style: {
                fontSize: '12px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 'bold',
                colors: ['#fff']
              },
            }
          }
        }],
        tooltip:{
          enabled: true,
          theme:'dark',
           
          custom: function({series, seriesIndex, w}) {
            const formatNumber = (valor) => {
              let valor_format = valor.toLocaleString();
              let i;
              let result = '';

              for( i of valor_format ){
                if( i == '.') {
                  result = result + ','
                }

                else if( i == ',' ) {
                  result = result + '.'
                }

                else {
                  result = result + i
                }
              }
              return result
            };

            let total = series.reduce((a, b) => { return a + b}, 0);
            let value = series[seriesIndex]
            let porcentaje = ((value/total)*100).toFixed(1);
            let label = w.globals.labels[seriesIndex];
            let unidad = w.config.unidad
            let tipo_unidad = w.config.tipo_unidad
            let text_value = tipo_unidad == 'monto' ? '$'+formatNumber(value) : formatNumber(value)

            let tooltip_html ='<div class="arrow_box" style="width:200px;height:170px;">'+
                              '<div class="title ml-3  font-weight-medium text-left">' + label + '</div>' +
                              '<div class="subtitle-1 font-weight-bold ml-3 text-left">'+text_value+'</div>' +
                              '<div class="subtitle-2 ml-3 text-left" style="margin-top:-5px;">' +unidad  + '</div>' +
                              '<div class="body-1 ml-3 mt-2 text-left">' +porcentaje+'%'+'</div>' +
                              '</div>'

            return tooltip_html
          }
        },
        plotOptions: {
          pie: {
            startAngle: 0,
            endAngle: 360,
            expandOnClick: true,
            offsetX: 0,
            offsetY: 0,
            customScale: 1,
            dataLabels: {
                offset: 0,
                minAngleToShowLabel: 10
            }, 
            donut: {
              size: '60%',
              background: 'transparent',
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: '12px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: undefined,
                  offsetY: -5,
                  formatter: function (val) {
                    return val
                  }
                },
                value: {
                  show: true,
                  fontSize: '16px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 400,
                  color: undefined,
                  offsetY: 0,
                  
                  formatter: function (val,w) {
                    let string_val = val.toString()
                    let tipo_unidad = w.config.tipo_unidad;
                    let result_number
                    let largo_value = string_val.length;
                    
                    const tamanios = {
                      4:1,
                      5:2,
                      6:3,
                      7:1,
                      8:2,
                      9:3,
                      10:1,
                      11:2,
                      12:3
                    }
                  
                  const format_number = (string,tamanio) => {
                    let cabeza, resto;
                    let result;
                    let tamanio_cabeza = tamanios[tamanio]
                    cabeza = string.slice(0,tamanio_cabeza);
                    if( tamanio < 4 ){
                      result = string
                    }
                    else if( tamanio >= 4 && tamanio < 7){
                      resto = string.slice(tamanio_cabeza,tamanio);
                      result = cabeza+'.'+resto;
                   
                    }
                    else{
                      let resto_temp = string.slice(tamanio_cabeza,tamanio);
                      let largo_resto_temp = resto_temp.length;

                      let temp = ''
                      let c;
                      for(c in resto_temp){
                        let i = parseInt(c)+1
                        
                        if( i % 3 == 0){
                          let resto_result = i == largo_resto_temp ? resto_temp[i-1] : resto_temp[i-1]+'.'
                          temp += resto_result
                        }
                        else{
                          temp += resto_temp[i-1]
                        }
                      }
                      
                      resto = temp
                      result = cabeza+'.'+resto;
                    }
                    
                    return result;
                  };


                  
                  if(string_val.includes('.')){
                    
                    let temp_string = string_val.split('.')
                    let no_decimal, decimal;
                  
                    no_decimal = temp_string[0]
                    let largo_no_decimal = no_decimal.length;
                    
                    if(temp_string.length == 1){
                      result_number = format_number(no_decimal,largo_no_decimal)
                    }
                    else if(temp_string.length > 1){
                      decimal = temp_string[1]
                      result_number = format_number(no_decimal,largo_no_decimal)+','+decimal
                    }
                  }
                  else {
                    
                    result_number = format_number(string_val,largo_value)
                  }

                  let result_text = tipo_unidad == 'monto' ? '$'+result_number : result_number
                  return result_text
                  
                  }
                  
                },
                total: {
                  show: true,
                  showAlways: false,
                  label: 'Total',
                  fontSize: '14px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 600,
                  color: '#373d3f',
                  formatter: function (w) {
                    const formatNumber = (valor) => {
                      let valor_format = valor.toLocaleString();
                      let i;
                      let result = '';

                      for( i of valor_format ){
                        if( i == '.') {
                          result = result + ','
                        }

                        else if( i == ',' ) {
                          result = result + '.'
                        }

                        else {
                          result = result + i
                        }
                      }
                      return result
                    };

                    let total =  w.globals.seriesTotals.reduce((a, b) => {
                      return a + b
                    }, 0)

                    let tipo_unidad = w.config.tipo_unidad

                    let result_text = tipo_unidad == 'monto' ? '$'+formatNumber(total) : formatNumber(total)
                    return result_text
                  }
                }
              }
            },      
          }
        }
      }
    }
  }
}
</script>

<style>
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center
}
</style>