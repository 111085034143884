<template>
  <div id="Region">
    <div id="header">
      <header-simple :titulo="nombre_region"/>
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col sm=2 xl=2>
          <info-card-simple 
            :icon="'mdi-human-male-female'"
            :titulo="'Población'"
            :subtitulo="'Regional'"
            :valor="cantidad_habitantes"
            :tipo_valor="'Habitantes'"
          />
        
           <info-card-pie-chart
            class="mt-2"
            :icon="'mdi-human-male-female'"
            :titulo="'Población'"
            :subtitulo="null"
            :data="data_poblacion_sexo"
            :width_chart="responsive_pie_chart.width_chart"
            :height_chart="responsive_pie_chart.height_chart"
            :height_card="responsive_pie_chart.height_card"
            
          />

          <info-card-donut-chart
            class="mt-2"
            :height_card="responsive_donut_chart.card"
            :titulo="'Empresas'" 
            :icon="'mdi-factory'"         
            :data="data_empresas_region"
            :height_chart="responsive_donut_chart.chart"
            :width_chart="responsive_donut_chart.width_chart"
          />

          
        </v-col>
        <v-col sm=6 xl=6>
          <v-row>
            <v-col>
            <info-card-simple 
              :icon="'mdi-human-male-female'"
              :titulo="'Población'"
              :subtitulo="'Migrantes'"
              :valor="cantidad_habitantes_migrantes"
              :tipo_valor="'Habitantes'"
            />
            </v-col>
            <v-col >
            <info-card-simple 
              :icon="'mdi-human-male-female'"
              :titulo="'Población'"
              :subtitulo="'P. Originarios'"
              :valor="cantidad_habitantes_p_originarios"
              :tipo_valor="'Habitantes'"
            />
            </v-col>
            <v-col >
            <info-card-simple 
              :icon="'mdi-home-city-outline'"
              :titulo="'Vivienda'"
              :subtitulo="'Cantidad'"
              :valor="cantidad_viviendas"
              :tipo_valor="'Viviendas'"
              />
            </v-col>
          </v-row>
          <v-row style="margin-top:-15px;">
            <v-col>
               <info-card-column-chart
                :icon="'mdi-truck-delivery-outline'"
                :titulo="'Importación'"
                :subtitulo="'Exportación'"
                :height_card="height_column_chart.card"
                :height_chart="height_column_chart.chart"
                :hasOptions="true"
                :options="[
                  {
                    text: 'Peso',
                    value: 'peso',
                  },
                  {
                    text: 'Monto',
                    value: 'monto'
                  }
                ]"
                :first_option="'peso'"
                :label_options="'Tipo'"
                :data="data_imp_exp_region"
                
              />
            </v-col>
          </v-row>
          <v-row style="margin-top:-15px;">
            <v-col>
               <info-card-fill-chart
                :icon="'mdi-home-analytics'"
                :titulo="'Presupuesto Regional'"
                :hasOptions="true"
                :label_options="'Años'"
                :options="anios_prespuesto"
                :first_option="anios_prespuesto[0]"
                :data="data_presupuesto_region"
                :height_chart="height_fill_chart.chart"
                :height_card="height_fill_chart.card"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col sm=4 xl=4>
          <map-region-comunas 
            :coordenadas_region="coordenadas_region" 
            :codigo_region="numero_region"
            :height_map="height_map"
          />

          <info-card-line-chart 
            class="mt-2"
            :titulo="'Producto Interno Bruto'"
            :subtitulo="''"
            :icon="'mdi-trending-up'"
            :data="data_pib_region"
            :height_chart="height_line_chart.height_chart"
            :height_card="height_line_chart.height_card"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>


<script>
import {formatRegion, formatNumber} from '@/functions/functions.js'
import HeaderSimple from '../../components/HeaderSimple.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardPieChart from '../../components/infoCardPieChart.vue';
import MapRegionComunas from '../../components/maps/RegionComunas.vue';
import InfoCardLineChart from '../../components/infoCardLineChart.vue';

const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import InfoCardFillChart from '../../components/infoCardFillChartOptions.vue';
import InfoCardColumnChart from '../../components/infoCardColumnChartOptions.vue';
import InfoCardDonutChart from '../../components/infoCardDonutChart.vue';



export default {
    name:'RegionHome',
    props:['region_selected','region_detail'],
    components:{
      HeaderSimple,
        InfoCardSimple,
        InfoCardPieChart,
        MapRegionComunas,
        InfoCardLineChart,
        InfoCardFillChart,
        InfoCardColumnChart,
        InfoCardDonutChart
    },
    data: () => ({
      data_pib_region:null,
      data_empresas_region:null,
      data_presupuesto_region:null,
      anios_prespuesto:[],
      data_imp_exp_region: null
    }),
    created(){
      this.getDataPIB(this.region_selected)
      this.getDataPresupuestoRegional(this.region_selected)
      this.getDataEmpresas(this.region_selected)
      this.getDataImpExp(this.region_selected)
    },
    computed:{
      nombre_region() {
        return this.formatRegion(this.region_detail.nombre)
      },
      coordenadas_region() {
        return [this.region_detail.lon, this.region_detail.lat]
      },
      numero_region () {
        return this.region_detail.numero_region
      },
      cantidad_habitantes() {
        return this.formatNumber(this.region_detail.datos_censo[0].cantidad_habitantes)
      },
      cantidad_habitantes_migrantes () {
        return this.formatNumber(this.region_detail.datos_censo[0].poblacion_migrante)
      },
      cantidad_habitantes_p_originarios () {
        return this.formatNumber(this.region_detail.datos_censo[0].poblacion_pueblos)
      },
      data_poblacion_sexo() {
        let series = [ this.region_detail.datos_censo[0].cantidad_hombres, this.region_detail.datos_censo[0].cantidad_mujeres]
        let labels = ['Hombres','Mujeres']
        let colors = ['#3498DB','#EC7063']
        let data = {
          series: series,
          categories: labels,
          colors: colors,
          unidad: 'Habitantes',
          tipo_unidad: 'no_monto'
        }

        return data
      },
     
      cantidad_viviendas() {
        return this.formatNumber(this.region_detail.datos_censo[0].cantidad_viviendas)
      },
      height_map () {
        const size_screen  = this.$vuetify.breakpoint.name;
        if( size_screen == 'xl' ){
          return '457'
        }
        else{
          return '369'
        }
      },
      height_line_chart () {
        const size_screen  = this.$vuetify.breakpoint.name;
        if( size_screen == 'xl' ){
          return {
            height_card:325,
            height_chart:255
          }
        }
        else{
          return {
            height_card:209,
            height_chart:165
          }
        }
      },
      height_fill_chart () {
        const size_screen = this.$vuetify.breakpoint.name;
        if(size_screen == 'xl'){
          return {
            chart:285,
            card:325
          }
        }
         else if(size_screen == 'lg'){
          return {
            chart:180,
            card:210
          }
        }
        else{
          return {
            chart:175,
            card:210
          }
        }
      },
      height_column_chart () {
        const size_screen = this.$vuetify.breakpoint.name;
        // console.log(size_screen)
        if(size_screen == 'xl'){
          return {
            chart:220,
            card:288
          }
        }
        else if(size_screen == 'lg'){
          return {
            chart:150,
            card:210
          }
        }
        else{
          return {
            chart:175,
            card:210
          }
        }
      },
      responsive_donut_chart () {
        const size_screen = this.$vuetify.breakpoint.name;
        if(size_screen == 'xl'){
          return {
            chart:300,
            card:325,
            width_chart: 310
          }
        }
        else if(size_screen == 'lg'){
          return {
            chart:175,
            card:212,
            width_chart: 215
          }
        }
        else{
          return {
            chart:175,
            card:215,
            width_chart: 215
          }
        }
      },
      responsive_pie_chart () {
        const size_screen = this.$vuetify.breakpoint.name;
        if(size_screen == 'xl'){
          return {
            height_chart:175,
            height_card:289,
            width_chart: 300,
      
          }
        }
        else if(size_screen == 'lg'){
          return {
            height_chart:175,
            height_card:210,
            width_chart:210,
           
          }
        }
        else{
          return {
            width_chart:100,
            width_chart_r:200
          }
        }
      },
    },
    watch:{
      region_selected () {
        this.getDataPIB(this.region_selected)
        this.getDataPresupuestoRegional(this.region_selected)
        this.getDataEmpresas(this.region_selected)
        this.getDataImpExp(this.region_selected)


      }
    },
    methods:{
      formatRegion(region){
        return formatRegion(region)
      },

      formatNumber(valor){
        return formatNumber(valor)
      },

      async getDataPIB(region) {
        let response = await axios.get(API_URL+'pib_anual/?region='+region);
        let data_result = response.data.results;
        let anios = data_result.map( d => d.anio)
        let valores = data_result.map( d => d.valor_pib)
        let tipo_unidad = 'monto'
        let unidad = 'M. Millones de pesos'
        let nombre_serie = 'PIB'

        let data_chart = {
          series: [
            {
              name:nombre_serie,
              data: valores
            }
          ],
          categories: anios,
          colors: ['#254cb4'],
          unidad: unidad,
          tipo_unidad: tipo_unidad
        }

        this.data_pib_region = data_chart  
      },

      async getDataEmpresas(region){
        
        let ultimo_anio = 2019
        let response = await axios.get(API_URL+'est_empresas_tramo/?anio='+ultimo_anio+'&region='+region)
        let data_result = response.data.data_region.data_region
        let tramos = data_result.data_tramos

        let element
        let cantidad_empresas_tramo = []
        let tramos_name = []
        for (element of tramos){
          let nombre_tramo = element.tramo == 'Sin Información' ? 'S/I' : element.tramo
          let empresas_tramo = element.cantidad_empresas_tramo
          tramos_name.push(nombre_tramo)
          cantidad_empresas_tramo.push(empresas_tramo)
        }
        let data_chart = {
          series: cantidad_empresas_tramo,
          labels: tramos_name,
          unidad: 'Empresas',
          tipo_unidad: 'no_monto',
          colors:['#0069c0','#001970',' #009cca  ',' #00278c ',' #0039ca '],

        }

        this.data_empresas_region = data_chart

        // console.log(tramos)
      },

      async getDataImpExp(region){
        let response = await axios.get(API_URL+'importacion_exportacion_aduana/?region='+region)
        let data_result = response.data.results;
       
        let data_chart = {
          data_result: data_result,
          data_names: [
            {
              text: 'Importación',
              value: 'importacion',
            },
            {
              text: 'Exportación',
              value: 'exportacion'
            }
          ],
          category_name: 'anios',
          name: 'Importación - Exportación',
          unidad: [
            {
              tipo: 'peso',
              text: 'Toneladas'
            }, 
            {
              tipo:'monto',
              text: 'Dolares FOB'
            }  
          ],
          tipo_unidad: ['peso','monto']

        }

        this.data_imp_exp_region = data_chart
      },

      async getDataPresupuestoRegional(region){
        let response = await axios.get(API_URL+'presupuesto_regional/?region='+region);
        let data_result = response.data.results;
        let anios = this.get_anios(data_result)
        // let meses = ["enero","febrero","marzo","abril","mayo","junio","julio","agosto","septiembre","octubre","noviembre","diciembre"];
        this.anios_prespuesto = anios;
        let data_chart = {
          data_result: data_result,
          data_names : [
            {
              text:'Gasto Acumulado',
              value: 'gasto_acumulado'
            },{
              text:'Marco Evaluación',
              value:'marco_evaluacion'
            }
          ],
          category_name: 'mes',
          name: 'Presupuesto Regional',
          unidad: 'Millones de pesos',
          tipo_unidad: 'monto'
        }
        
        this.data_presupuesto_region = data_chart
      },

      get_anios(data){
        let anios = new Set()
        data.forEach(e => anios.add(e.anio))
        let result = [...anios]
        return result.sort((a,b) => b-a)
      }

      
    }
}
</script>
