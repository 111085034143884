<template>
  <v-card flat :height="height_card">
    <v-card-title>
      <v-icon
      medium
      left
      >
      {{icon}}
      </v-icon>
      <h4 class="font-weight-medium">{{titulo}}</h4>  
    </v-card-title>
    <v-card-subtitle class="text-left">
        <h3 class="font-weight-medium">{{subtitulo}}</h3>
    </v-card-subtitle>
    <v-card-text style="margin-top:-25px;">
      <tree-map
        v-if="data != null"
        :data_chart="data_chart"
        :colors_chart="colors_chart"
        :unidad_chart="unidad_chart"
        :tipo_unidad_chart="tipo_unidad_chart"
        :distributed_chart="distributed_chart"
        :nombre_data="nombre_data"
        :height_chart="height_chart"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import TreeMap from './charts/TreeMap.vue'
export default {
  components: { TreeMap },
  name:'infoCardTreeMap',
  props:[
    'icon', 'titulo','subtitulo',
    'data',
    'height_card', 'height_chart'
  ],
  computed: {
    data_chart () {
      return this.data.data
    },
    colors_chart () {
      return this.data.colors
    },
    unidad_chart () {
      return this.data.unidad
    },
    tipo_unidad_chart () {
      return this.data.tipo_unidad
    },
    distributed_chart () {
      return this.data.distributed
    },
    nombre_data () {
      return this.data.nombre_data
    }
  }
}
</script>

<style>

</style>