<template>
  <div id="Home">
    <div v-if="comuna_selected == 0">
      <region-home
        :region_detail="region_detail"
        :region_selected="region_selected"
      />
    </div>
    <div v-else>
      Sin Datos
    </div>
  </div>
</template>

<script>
import RegionHome from './RegionViews/HomeRegion.vue'

export default {
  name: 'Home',
  props: [
    'region_detail',
    'region_selected',
    'comuna_selected',
    'comuna_detail'
  ],
  components:{
    RegionHome
  },
  data: () => ({

  }),
}
</script>

<style>

</style>