<template>
   <v-card  flat :height="height_card">
      <v-card-title>
        <v-icon
        medium
        left
        >
        {{icon}}
        </v-icon>
        <h4 class="font-weight-medium">{{titulo}}</h4>  
      </v-card-title>
      <v-card-subtitle class="text-left">
          <h3 class="font-weight-medium">{{subtitulo}}</h3>
      </v-card-subtitle>
      <v-card-text style="margin-top:-25px;">
        <line-chart 
          v-if="data != null"
          :data_chart="data.series"
          :categories_chart="data.categories"
          :colors_chart="data.colors"
          :nombre_chart="data.name"
          :unidad_chart="data.unidad"
          :tipo_unidad_chart="data.tipo_unidad"
          :height_chart="height_chart"
        />
      </v-card-text>
  </v-card>
</template>

<script>
import LineChart from './charts/LineChart.vue'
export default {
  components: { LineChart },
  name:'infoCardLineChart',
  props:[
    'icon', 'titulo','subtitulo','data','height_chart',
    'height_card'
  ],
  // computed:{
  //   data_chart () {
  //     return this.data.series
  //   }
  // }
  
    
  

  
}
</script>

<style>

</style>