<template>
  <div>
    <div v-if="vistas_disponibles_test.includes(url) && (user_type === 'test' || user_type === 'demo')" >
      <div v-if="data_result.id_vista_comuna !== null">
        <div v-if="comuna_selected == 0">
          <select-view-region
            :data_url="data_result"
            :region_selected="region_selected"
            :region_detail="region_detail"
          />
        </div>
        <div v-else>
          <select-view-comuna 
            :data_url="data_result"
            :region_selected="region_selected"
            :region_detail="region_detail"
            :comuna_detail="comuna_detail"
            :comuna_selected="comuna_selected"
          />
        </div>
      </div>
      <div v-else>
        <select-view-region
          :data_url="data_result"
          :region_selected="region_selected"
          :region_detail="region_detail"
        />
      </div>
    </div>
    <div v-else>
      <div v-if="user_type !== 'test' && user_type !== 'demo'">
        <div v-if="data_result.id_vista_comuna !== null">
          <div v-if="comuna_selected == 0">
            <select-view-region
              :data_url="data_result"
              :region_selected="region_selected"
              :region_detail="region_detail"
            />
          </div>
          <div v-else>
            <select-view-comuna 
              :data_url="data_result"
              :region_selected="region_selected"
              :region_detail="region_detail"
              :comuna_detail="comuna_detail"
              :comuna_selected="comuna_selected"
            />
          </div>
        </div>
        <div v-else>
          <select-view-region
            :data_url="data_result"
            :region_selected="region_selected"
            :region_detail="region_detail"
          />
        </div>
      </div>
      <div v-else>
        <h3>
          Aqui no hay nada :( 
        </h3>
      </div>
    </div>
  </div>
  
</template>

<script>
import SelectViewComuna from './ComunaViews/SelectViewComuna.vue'
import SelectViewRegion from './RegionViews/SelectViewRegion.vue'
export default {
  components: { SelectViewRegion, SelectViewComuna },
  name: 'GenData',
  props: [
    'categorias','categoria','url','no_comunas',
    'comuna_selected','region_selected','regiones'
    ,'region_detail','comuna_detail','vistas_disponibles_test','user_type'
  ],
  created(){
    this.getDataURL()
  },  
  watch: {
    url () {
      this.getDataURL()
    }
  },
  methods: {
    getMeses(){
      return ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
    },
    getDataURL(){
      let categoria = this.categoria;
      let categoria_url = this.data_categoria[categoria];
      let data_categoria_url;

      if( categoria_url.length == 1) {
        data_categoria_url = categoria_url[0]
      }
      else {
        data_categoria_url = categoria_url.find(e => e.url_vista == this.url)
      }

      this.data_result = this.data_url[data_categoria_url.id]
      
      if( this.data_result != null && this.data_result.id_vista_comuna == null){
        this.$emit('update',true,0);
      }
      else {
        this.$emit('update',false,this.comuna_selected);
      }
    },
    generarAnios(anio1,anio2){
      var lista = [];
      for(let i=anio2; i >= anio1; i--){
        lista.push(i);
      }
      return lista
    },
  },
  data() {
    return {
      data_result:null,
      data_categoria: {
        10: [
          {
            url_vista: 'data_uso_internet',
            id: 1
          }
        ],
        9: [
          {
            url_vista: 'data_generacion_instalada_regional',
            id:2
          },
          {
            url_vista: 'data_generacion_ernc_regional',
            id:3
          }
        ],
        8: [
          {
            url_vista: 'data_superficie_agricola_regional',
            id:4
          },
          {
            url_vista: 'data_superficie_desprovista_vegetacion_regional',
            id:5
          }
        ],
        7: [],
        6: [
          {
            url_vista: 'data_superficie_urbana_industrial_regional',
            id: 6
          },
          {
            url_vista: 'data_viviendas_unid_tramo_superficie_regional',
            id: 7
          },
          {
            url_vista: 'data_avaluo_predios_agricolas_noagricolas_regional',
            id: 8
          },
          {
            url_vista: 'data_bienes_no_agricolas',
            id:9
          },
          {
            url_vista: 'data_materialidad_vivienda',
            id:10
          },
          {
            url_vista: 'data_deficit_habitacional',
            id:11
          }
        ],
        5: [
          {
            url_vista: 'data_pobreza_ingreso',
            id: 12
          },
          {
            url_vista: 'data_hacinamiento',
            id:13
          }
        ],
        4: [
          {
            url_vista: 'data_gasto_id_regional',
            id:14
          }, 
          {
            url_vista: 'data_patentes_registradas',
            id:15
          }
        ],
        3: [
          {
            url_vista: 'data_exportacion_agricola_regional',
            id: 16
          },
          {
            url_vista: 'data_superficie_plantada',
            id: 17
          }
        ],
        2: [
          {
            url_vista: 'data_transporte_aduana',
            id: 18
          },
          {
            url_vista: 'data_permisos_circulacion',
            id:19
          }
        ],
        1: [
          {
            url_vista: 'data_pib_regional',
            id:20
          },
          {
            url_vista: 'data_presupuesto_regional',
            id:21
          },
          {
            url_vista: 'data_exportacion_aduana',
            id:22
          },
          {
            url_vista: 'data_importacion_aduana',
            id:23
          },
          {
            url_vista: 'data_est_empresas_tramo',
            id:24
          },
          {
            url_vista: 'data_est_empresas_rubro',
            id:25
          },
          {
            url_vista: 'data_presupuesto_municipal',
            id: 26
          }
        ]
      },

      data_url: {
        1: {
          url_api: [
            'data_internet_banca/',
            'data_internet_compraventa/',
            'data_internet_educacion/',
            'data_internet_entretenimiento/',
            'data_internet_tramitespublicos/',
            'data_internet_usobuscadores/',
            'data_internet_usuarios/'
          ],
          id_vista_region : 1,
          nombre_data :'Uso de Internet',
          filtros: [
            {
              text: 'Año',
              data: [2020,2017,2015,2013]
            },
            {
              text: 'Tipo',
              data: [
                {
                  id: 0,
                  text: 'Banca Electronica',
                  descripcion: 'Porcentaje de uso de internet para operación de banca electrónica',
                  tooltip: 'Operación Banca electrónica'
                },
                {
                  id: 1,
                  text: 'Compra Venta',
                  descripcion: 'Porcentaje de uso de internet para compra y venta de artículos y servicios',
                  tooltip: 'Compra y venta de artículos y servicios'
                },
                {
                  id: 2,
                  text: 'Educación',
                  descripcion:'Uso de internet para actividades de educación formal y capacitación',
                  tooltip: 'Actividades de educación y capacitación'
                },
                {
                  id: 3,
                  text: 'Entretenimiento',
                  descripcion: 'Porcentaje de uso de internet para entretenimiento',
                  tooltip: 'Entretenimiento'
                },
                {
                  id: 4,
                  text: 'Trámites Públicos',
                  descripcion: 'Porcentaje de uso de internet para trámites en línea con instituciones públicas',
                  tooltip: 'Trámites en línea con instituciones públicas'
                },
                {
                  id: 5,
                  text: 'Uso Buscadores',
                  descripcion: 'Uso de internet para obtener información en buscadores',
                  tooltip: 'Obtener información en buscadores'
                },
                {
                  id: 6,
                  text: 'Usuarios',
                  descripcion: 'Porcentaje de usuarios de internet, por región',
                  tooltip: 'Porcentaje de usuarios de internet'
                },
              ]
            }
          ],
          id_vista_comuna: null,
          fuente_data: 'FUNDACIÓN PAÍS DIGITAL'
        },
        2: {
          url_api: ['capacidad_instalada_generacion/'],
          nombre_data: 'Capacidad Instalada de Generación',
          filtros: [
            {
              text: 'Año',
              data: [2020]
            }
          ],
          fuente_data: 'MINISTERIO DE ENERGÍA',
          id_vista_region: 2,
          id_vista_comuna: null
        },
        3:{
          url_api: ['capacidad_generacion_ernc/'],
          nombre_data: 'Generación ERNC',
          filtros: [
            {
              text: 'Año',
              data: [2010]
            }
          ],
          fuente_data: 'MINISTERIO DE ENERGÍA',
          id_vista_region: 2,
          id_vista_comuna: null
        },
        4:{
          url_api: ['superficie_area_agricola/'],
          nombre_data: 'Superficie Agrícola',
          fuente_data: 'INE-CONAF',
          id_vista_region: 3,
          filtros: [
            {
              text: 'Año',
              data: this.generarAnios(1999,2019)
            }
          ],
          id_vista_comuna: null
        },
        5:{
          url_api: ['superficie_area_desprovista_vegetacion/'],
          nombre_data: 'Superficie Desprovista de Vegetación',
          fuente_data: 'INE-CONAF',
          id_vista_region: 3,
          filtros: [
            {
              text: 'Año',
              data: this.generarAnios(1999,2019)
            }
          ],
          id_vista_comuna: null
        },
        6: {
          url_api: ['superficie_area_urbana_industrial/'],
          nombre_data: 'Superficie Urbana e Industrial',
          fuente_data: 'INE-CONAF',
          id_vista_region: 3,
          filtros: [
            {
              text: 'Año',
              data: this.generarAnios(1999,2019)
            }
          ],
          id_vista_comuna: null
        },
        7: {
          url_api: ['unidades_viviendas_tramo_superficie/'],
          nombre_data: 'Estadisticas Viviendas',
          filtros: [
            {
              text: 'Mes',
              data: this.getMeses()
            },
            {
              text: 'Año',
              data: this.generarAnios(2002,2020)
            },
            {
              text: 'Tipo',
              data: [
                {
                  text:'Superficie',
                  id: 's_',
                  data: 'Superficie construida',
                  unidad: 'Metros Cuadrados (M2)'

                },
                {
                  text:'Unidades',
                  id: 'u_',
                  data: 'Unidades construidas',
                  unidad: 'Viviendas'
                }
              ]
            },
            {
              text: 'Tramo',
              data: ['1-35','36-50','51-70','71-100','101-140','140+']
            }
          ],
          fuente_data: 'INE (OBS. URBANO - MINVU)',
          id_vista_region:4,
          id_vista_comuna: null
        },
        8:{
          url_api: ['avaluos_predios_agricolas_noagricolas/'],
          nombre_data: 'Avaluo Predios',
          filtros: [
            {
              text:'Tipo',
              data: [
                {
                  text: 'Exentos',
                  id: 'exentos'
                },
                {
                  text: 'Afectos',
                  id: 'afectos'
                }
              ]
            },
            {
              text: 'Año',
              data: this.generarAnios(2019,2020)
            }
          ],
          fuente_data: 'SII',
          id_vista_region: 5,
          id_vista_comuna: null
        },
        9: {
          url_api: ['est_bienes_raices_no_agricolas_region/','est_bienes_raices_no_agricolas_comuna/'],
          nombre_data: 'Estadísticas Bienes Raíces',
          filtros: [
            {
              text: 'Tipo Dato',
              data: [ 
                {
                  text: 'Predios',
                  id: 'predios'
                },
                {
                  text: 'Avaluo',
                  id: 'afectos'
                }
              ]
            },
            {
              text:'Tipo',
              data: [
                {
                  text: 'Exentos',
                  id: 'exento'
                },
                {
                  text: 'Afectos',
                  id: 'afecto'
                },
                {
                  text: 'Total',
                  id: 'totales'
                }
              ]
            },
            {
              text: 'Año',
              data: [2020]
            }
          ],
          fuente_data: 'SII',
          id_vista_region: 6,
          id_vista_comuna: null
        },
        10: {
          url_api: ['materialidad_vivienda/','categoria_materialidad_vivienda/'],
          nombre_data: 'Materialidad Vivienda',
          filtros: [
            {
              text: 'Tipo',
              data: [
                {value: 1, text: 'Aceptable'},
                {value:2,text:'Recuperable'},
                {value:3,text:'Irrecuperable'}
              ]
            },
            {
              text: 'Año',
              data: [2017,2015,2013,2011,2009,2006]
            }
          ],
          fuente_data: 'CASEN MINISTERIO DESARROLLO SOCIAL',
          id_vista_region: 7,
          id_vista_comuna: null
        },
        11:{
          url_api: ['deficit_habitacional'],
          nombre_data: 'Deficit Habitacional',
          filtros: [
            {
              text: 'Tipo',
              data: [
                {value: 1, text: 'Calidad'},
                {value:2,text:'Allegamiento Externo'},
                {value:3,text:'Allegamiento Hacinados'}
              ]
            },
            {
              text: 'Año',
              data: [2017,2015,2013,2011,2009,2006]
            }
          ],
          fuente_data: 'CASEN MINISTERIO DESARROLLO SOCIAL',
          id_vista_region: 7,
          id_vista_comuna: null
        },
        12: {
          url_api: ['pobreza_multidimensional/'],
          nombre_data: 'Pobreza Multidimensional',
          filtros: [
            {
              text: 'Año',
              data: [2017,2015]
            }
          ],
          fuente_data: 'CASEN MINISTERIO DESARROLLO SOCIAL',
          id_vista_region: 8,
          id_vista_comuna: null
        },
        13: {
          url_api: ['hacinamiento/'],
          nombre_data: 'Índice de Hacinamiento',
           filtros: [
            {
              text: 'Tipo',
              data:[
                {value: 1, text: "Sin Hacinamiento"},
                {value: 2, text: "Medio Bajo"},
                {value: 3, text: "Medio Alto"},
                {value: 4, text: "Critico"}
              ]
            },
            {
              text: 'Año',
              data: [2017,2015,2013,2011,2009,2006]
            }
          ],
          fuente_data: 'CASEN MINISTERIO DESARROLLO SOCIAL',
          id_vista_region: 9,
          id_vista_comuna: null
        },
        14: {
          url_api: ['gasto_regional_innovacion/'],
          nombre_data: 'Gasto en I+D Regional',
          fuente_data: 'MINISTERIO DE ECONOMÍA',
          filtros: [{
            text: 'Año',
            data:  this.generarAnios(2009,2017)
          }],
          id_vista_region: 10,
          id_vista_comuna: null
        },
        15: {
          url_api: ['patentes_registradas/'],
          nombre_data: 'Patentes Registradas',
          fuente_data: 'INAPI',
          id_vista_region: 11,
          filtros:[
            {
              text: 'Año',
              data: [
                  {
                      "value": '',
                      "text": "Todos los años"
                  },
                  {
                      "value": 2020,
                      "text": 2020
                  },
                  {
                      "value": 2019,
                      "text": 2019
                  },
                  {
                      "value": 2018,
                      "text": 2018
                  },
                  {
                      "value": 2017,
                      "text": 2017
                  },
                  {
                      "value": 2016,
                      "text": 2016
                  },
                  {
                      "value": 2015,
                      "text": 2015
                  },
                  {
                      "value": 2014,
                      "text": 2014
                  },
                  {
                      "value": 2013,
                      "text": 2013
                  },
                  {
                      "value": 2012,
                      "text": 2012
                  },
                  {
                      "value": 2011,
                      "text": 2011
                  },
                  {
                      "value": 2010,
                      "text": 2010
                  },
                  {
                      "value": 2009,
                      "text": 2009
                  }
              ]
            },
            {
              text:'Tipo',
              data:[
                  {
                      "value": '',
                      "text": "Todos los tipos"
                  },
                  {
                      "value": 'patente de invencion',
                      "text": "Patente de Invención"
                  },
                  {
                      "value": 'modelo de utilidad',
                      "text": "Modelo de Utilidad"
                  },
                  {
                      "value": 'dibujo o diseño industrial',
                      "text": "Dibujo o Diseño Industrial"
                  }
              ]
            }
          ],
          id_vista_comuna: null
        },
        16: {
          url_api: ['exportaciones_agricolas_region/'],
          nombre_data: 'Exportaciones SilvoAgropecuarias',
          fuente_data: 'ADUANA - INE',
          filtros: [
            {
              text: 'Año',
              data:this.generarAnios(2008,2020)
            },
            {
              text:'Tipo',
              data: [
                {value: 'exportaciones_agricola', text: "Agrícola"},
                {value: 'exportaciones_forestal', text: "Forestal"},
                {value: 'exportaciones_pecuario', text: "Pecuario"},
                {value: 'exportaciones_total', text: "Total"}
              ]
            }
          ],
          id_vista_region: 12,
          id_vista_comuna: null
        },
        17: {
          url_api: [
            'superficie_sembrada_por_grupo_cultivo_region/',
            'superficie_sembrada_por_grupo_cultivo_comuna/'
          ],
          nombre_data: 'Superficie Sembrada',
          fuente_data: 'INE - CENSO AGROPECUARIO 2007',
          filtros: [
            {
              text: 'Tipo',
              data: [
                {value: 'superficie_total', text: 'Total'},
                {value: 'superficie_cereales', text: 'Cereales'},
                {value: 'superficie_leguminosas', text: 'Leguminosas'},
                {value: 'superficie_industriales', text: 'Industriales'},
                {value: 'superficie_hortalizas', text: 'Hortalizas'},
                {value: 'superficie_flores', text: 'Flores'},
                {value: 'superficie_forrajeras', text: 'Forrajeras'},
                {value: 'superficie_frutales', text: 'Frutales'},
                {value: 'superficie_vinas', text: 'Viñas'},
                {value: 'superficie_viveros', text: 'Viveros'},
                {value: 'superficie_semilleros', text: 'Semilleros'},
                {value: 'superficie_forestales', text: 'Forestales'}
              ]
            }
          ],
          id_vista_region: 13,
          id_vista_comuna: null
        },
        18:{
          url_api: [
            'transporte_territorial_aduana/',
            'transporte_territorial/'
          ],
          nombre_data: 'Tráfico Terrestre',
          fuente_data: 'ADUANA',
          filtros: [
            {
              text: 'Año',
              data: this.generarAnios(2005,2020)
            },
            {
              text: 'Tipo',
              data: [
                {value: 'ingreso', text: 'Ingreso'},
                {value: 'salida',text: 'Salida'}
              ]
            },
            {
              text: 'Tipo Vehículo',
              data: [
                  {value: 'v_particulares', text: 'Particular',subtitle:'Vehículo Particular', tipo_dato: 'Vehículos'},
                  {value: 'v_pasajeros', text: 'Pasajeros', subtitle:'Vehículos Pasajeros', tipo_dato: 'Vehículos'},
                  {value: 'v_carga', text: 'Carga', subtitle:'Vehículos Carga', tipo_dato: 'Vehículos'},
                  {value: 'carga', text: 'Carga (Toneladas)', subtitle:'de Carga', tipo_dato: 'Toneladas'}
              ]
            }
          ],
          id_vista_region: 14,
          id_vista_comuna: null
        },
        19:{
          url_api: [
            'vehiculos_segun_motor/',
            'vehiculos_en_circulacion/',
            'vehiculos_cataliticos_nocataliticos/'
          ],
          nombre_data: 'Permisos de Circulación',
          fuente_data: 'INE',
          filtros: [
            {
              text:'Año',
              data:this.generarAnios(2008,2019)
            }
          ],
          id_vista_region:15,
          id_vista_comuna:1
        },
        20: {
          url_api: ['pib_anual/'],
          nombre_data: 'Producto Interno Bruto',
          fuente_data: 'BANCO CENTRAL',
          filtros: [
            {
              text:'Año',
              data: this.generarAnios(2013,2019)
            }
          ],
          filtro_regiones: {
            text:'Region',
            data: this.regiones
          },
          id_vista_region:16,
          id_vista_comuna: null
        },
        21: {
          url_api: ['presupuesto_regional/'],
          nombre_data: 'Presupuesto Regional',
          fuente_data: 'SUBDERE',
          filtros: [
            {
              text: 'Mes',
              data: this.getMeses()
            },
            {
              text: 'Año',
              data: this.generarAnios(2011,2021)
            }
          ],
          id_vista_region: 17,
          id_vista_comuna: null
        },
        22: {
          url_api: [
            'importacion_exportacion_aduana/',
            'importacion_exportacion/'
          ],
          nombre_data: 'Exportación',
          tipo_data: {text: 'Exportación', value: 'exportacion'},
          fuente_data: 'ADUANA',
          filtros: [
            {
              text: 'Año',
              data: this.generarAnios(2002,2020)
            },
            {
              text: 'Tipo',
              data:  [
                {'value': 'peso','text':'PESO','unidad': 'Toneladas'},
                {'value': 'monto','text':'FOB (US$)','unidad':'Millones de Dólares'}  
              ],
            }
          ],
          id_vista_region: 18,
          id_vista_comuna:null
        },
        23: {
          url_api: [
            'importacion_exportacion_aduana/',
            'importacion_exportacion/'
          ],
          nombre_data: 'Importación',
          tipo_data: {text: 'Importación', value: 'importacion'},
          fuente_data: 'ADUANA',
          filtros: [
            {
              text: 'Año',
              data: this.generarAnios(2002,2020)
            },
            {
              text: 'Tipo',
              data:  [
                {'value': 'peso','text':'PESO','unidad': 'Toneladas'},
                {'value': 'monto','text':'CIF (US$)','unidad':'Millones de Dólares'}  
              ],
            }
          ],
          id_vista_region: 18,
          id_vista_comuna:null
        },
        24: {
          url_api: [
            'est_empresas_tramo/',
          ] ,
          nombre_data: 'Empresas por Tramo',
          tipo_data: {text: 'Tramo', value: 'tramo'},
          fuente_data: 'SII',
          filtros: [
            {
              text: 'Año',
              data: this.generarAnios(2005,2019)
            },
            {
              text: 'Tramo',
              data: [
                {
                    "value": 3,
                    "text": "Micro",
                },
                {
                    "value": 2,
                    "text": "Pequeña",
                },
                {
                    "value": 5,
                    "text": "Mediana",
                },
                {
                    "value": 4,
                    "text": "Grande",
                },
                {
                    "value": 1,
                    "text": "Sin Información",
                }
              ]
            },
            {
              text: 'Tipo dato',
              data: [
                {value: 'empresas', text: 'Empresas',unidad:'Empresas'},
                {value: 'monto', text: 'Ventas', unidad: 'Millones de UF'}
              ]
            }
          ],
          id_vista_region:19,
          id_vista_comuna:null
        },
        25: {
          url_api: [
            'est_empresas_rubro/',
          ] ,
          nombre_data: 'Empresas por Rubro',
          tipo_data: {text: 'Rubro', value: 'rubro'},
          fuente_data: 'SII',
          filtros: [
            {
              text:'Año',
              data: this.generarAnios(2005,2019)
            },
            {
              text:'Rubro',
              data: [
                {
                    "value": 1,
                    "text": "Agricultura"
                },
                {
                    "value": 2,
                    "text": "Explotación de minas"
                },
                {
                    "value": 3,
                    "text": "Industria manufacturera"
                },
                {
                    "value": 4,
                    "text": "Suministro de energia"
                },
                {
                    "value": 5,
                    "text": "Suministro de agua"
                },
                {
                    "value": 6,
                    "text": "Construcción"
                },
                {
                    "value": 7,
                    "text": "Comercio"
                },
                {
                    "value": 8,
                    "text": "Transporte"
                },
                {
                    "value": 9,
                    "text": "Alojamiento"
                },
                {
                    "value": 10,
                    "text": "Telecomunicaciones"
                },
                {
                    "value": 11,
                    "text": "Act. Financieras"
                },
                {
                    "value": 12,
                    "text": "Act. Inmobiliarias"
                },
                {
                    "value": 13,
                    "text": "Act. Profesionales"
                },
                {
                    "value": 14,
                    "text": "Act. Administrativas"
                },
                {
                    "value": 15,
                    "text": "Administración pública"
                },
                {
                    "value": 16,
                    "text": "Enseñanza"
                },
                {
                    "value": 17,
                    "text": "Atención de salud"
                },
                {
                    "value": 18,
                    "text": "Act. Recreativas"
                },
                {
                    "value": 19,
                    "text": "Otras actividades"
                },
                {
                    "value": 20,
                    "text": "Sin información"
                },
                {
                    "value": 21,
                    "text": "Act. de los hogares"
                },
                {
                    "value": 22,
                    "text": "Organizaciones"
                }
              ]
            },
            {
              text: 'Tipo dato',
              data: [
                {value: 'empresas', text: 'Empresas',unidad:'Empresas'},
                {value: 'monto', text: 'Ventas', unidad: 'Millones de UF'}
              ],
            }
          ],
          id_vista_region:19,
          id_vista_comuna:null
        },
        26: {
          url_api: [
            'evolucion_presupuestaria/',
            'presupuesto_municipal/'
          ],
          nombre_data: 'Presupuesto Municipal',
          fuente_data: 'INE',
          filtros: [
            {
              text:'Año',
              data: this.generarAnios(2015,2019) 
            }
          ],
          opciones_ingresos: [
            {
              text: 'Mayor Ingreso',
              value: 'max_ingreso',
              data: 'mayores ingresos'
            },
            {
              text: 'Menor Ingreso',
              value: 'min_ingreso',
              data: 'menores ingresos'
            }
          ],
          id_vista_region: 20,
          id_vista_comuna: 2
        }
      }
    }
  }

}
</script>

<style>

</style>