<template>
  <div>
    <div id="header">
      <header-simple 
        :titulo="'Panel de Administración'"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col cols=5 sm=6>
          <v-card flat>
            <v-card-title>
              <v-icon
                medium
                left
                >
                mdi-account-multiple-plus
              </v-icon>
              <h5 v-if="is_responsive" class="font-weight-medium">Registrar Usuario</h5>  
              <h4 v-else class="font-weight-medium">Registrar Usuario</h4>
            </v-card-title>
            <v-card-text>
              <v-form @submit.prevent="register"  class="mt-0" ref='form'>
                <v-row>
                  <v-col cols=7>
                    <v-text-field  
                      v-model="email" 
                      light prepend-inner-icon='mdi-email' 
                      label='Email' 
                      outlined
                      type='email'  
                      :rules="[v => !!v || 'Ingrese un Email'] " 
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-select
                      :items="tipos_usuarios"
                      prepend-inner-icon='mdi-account-box'
                      v-model="tipo_selected"
                      label="Tipo de usuario"
                      :rules="[v => !!v || 'Seleccione un tipo']"
                      v-on:change="cambiarTipo"
                      return-object
                      outlined
                      required
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row style="margin-top:-15px;">
                  <v-col cols=6>
                     <v-text-field
                      v-model="username"                  
                      label="Nombre de usuario"
                      prepend-inner-icon='mdi-account'
                      outlined
                      :disabled="(tipo_selected != null) && (tipo_selected.value !== 'test' && tipo_selected.value !== 'demo') ? false : true" 
                      :rules="[
                        v => !!v || 'Ingrese un Usuario',
                        (v) => v && v.length <= 15 || 'Nombre debe ser menor a 15 caracteres'
                      ] " 
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols=6>
                    <v-btn 
                      :color="error_username == true ? 'red darken-1' : 
                              error_email == true ? 'red darken-1' :
                              register_succesful == true ? 'green darken-1' : '#1565C0'"
                      block type='submit' x-large dark transition="fade-transition" 
                    >
                      <div v-if="loading_register">
                        <v-progress-circular
                          :size="25"
                          :width="3"
                          color="white"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                      <div v-else>
                        <h4 v-if="error_username" class="font-weight-medium">
                          <v-icon left> mdi-alert-circle-outline</v-icon>
                          Nombre ya existe
                        </h4>
                        <h4 v-else-if="error_email" class="font-weight-medium">
                          <v-icon left> mdi-alert-circle-outline</v-icon>
                          Email ya existe
                        </h4>
                        <h4 v-else-if="error" class="font-weight-medium">
                          <v-icon left> mdi-alert-circle-outline</v-icon>
                          Error
                        </h4>
                        <h4 v-else-if="register_succesful" class="font-weight-medium">
                          <v-icon left>mdi-check-circle-outline </v-icon>
                          Registrado!
                        </h4>
                        <h4 v-else class="font-weight-medium">
                          <v-icon>mdi-plus</v-icon>Crear usuario
                        </h4>
                      </div>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols=7 sm=6>
          <info-card-column-chart 
              :icon="'mdi-chart-bar'"
              :titulo="'Cantidad de usuarios'"
              :height_chart="size_column_chart.height_chart"
              :height_card="size_column_chart.height_card"
              :data="data_column_chart"
            />
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px;">
        <v-col>
          <v-card flat :height="is_responsive ? 315 : 500">
            <v-card-title>
              <v-icon
                medium
                left
                >
                mdi-clipboard-account
              </v-icon>
              <h5 v-if="is_responsive" class="font-weight-medium">Lista de Usuarios</h5>  
              <h4 v-else class="font-weight-medium">Lista de Usuarios</h4>
            </v-card-title>
            <v-card-text style="margin-top:-20px;">
              <v-row>
                <v-col cols=5>
                    <v-text-field  
                      v-model="email_buscado" 
                      dense
                      light prepend-inner-icon='mdi-account-search' 
                      label='Email' 
                      outlined
                      type='text'  
                    >
                    </v-text-field>
                </v-col>
                <v-col cols=3>
                  <v-select
                      :items="tipos_usuarios_list"
                      prepend-inner-icon='mdi-account-box'
                      v-model="user_list_selected"
                      label="Tipo de usuario"
                      :rules="[v => !!v || 'Seleccione un tipo']"
                      v-on:change="cambiarTipoList"
                      return-object
                      outlined
                      required
                      dense
                    >
                  </v-select>
                </v-col>
              </v-row>
              <v-row style="margin-top:-35px;">
                <v-col cols=12>
                   <v-simple-table 
                    id="table"
                    :height="is_responsive ? 150 : 320"
                    :fixed-header="delete_dialog == true ? false : true"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            <h3>Email</h3>
                          </th>
                           <th class="text-left">
                            <h3>Username</h3>
                          </th>
                          <th class="text-left">
                            <h3>Tipo</h3>
                          </th>
                          <th class="text-center">
                            <h3>Días Acceso</h3>
                          </th>
                          <th class="text-center">
                            <h3>Días Restantes</h3>
                          </th>
                          <th class="text-center">
                            <h3>Estado</h3>
                          </th>
                          <th class="text-center">
                            <h3>Modificar</h3>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="text-left"
                          v-for="item in user_list"
                          :key="item.email"
                        >
                          <td>{{ item.email }}</td>
                          <td>{{ item.username }}</td>
                          <td>{{ item.user_type }}</td>
                          <td class="text-center">
                            {{item.user_type === 'test' ? 5 :
                              item.user_type === 'demo' ? 15 :
                              30
                            }}
                          </td>
                          <td class="text-center">
                            {{ item.dias_restantes }}
                          </td>
                          <td class="text-center">
                            {{item.activo == true ? 'activo' : 'inactivo'}}
                          </td>
                          <td>
                            <div class="centrar">
                              
                              <v-btn
                                depressed
                                color='red darken-1'
                                dark
                                small
                                @click="openDeleteModal(item)"
                              >
                                Eliminar
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                   <modal 
                      v-if="delete_dialog"
                      :user_email="email_user_to_delete"
                      @close="delete_dialog = false"
                      @delete="delete_user(id_user_to_delete)"
                    />
                  <v-alert
                    v-if="delete_succesful"
                    dense
                    text
                    type="success"
                  >
                    Usuario eliminado correctamente.
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </div>
  </div>
</template>

<style >
.centrar {
    display: flex;
    align-items: center;
    justify-content: center;   
}
</style>

<script>
import axios from 'axios';
const API_URL_USERS = 'https://api.datari.net/auth/users/'

import HeaderSimple from '../components/HeaderSimple.vue'
import {sleep} from '@/functions/functions.js'
import InfoCardColumnChart from '../components/infoCardColumnChart.vue';
import Modal from '../components/ModalDelete.vue';

export default {
  name: 'AdminDashboard',
  components: { HeaderSimple, InfoCardColumnChart, Modal},
  data () {
    return {
      email:null,
      username:null,
      tipo_selected:null,
      tipos_usuarios: [
        {
          text:'Test',
          value:'test'
        },
        {
          text:'Demo',
          value:'demo'
        },
        {
          text:'Suscripción',
          value:'prueba'
        }
      ],
      tipos_usuarios_list: [
         {
           text:'Todos',
            value:'all'
         },
        {
          text:'Test',
          value:'test'
        },
        {
          text:'Demo',
          value:'demo'
        },
        {
          text:'Suscripción',
          value:'prueba'
        }
      ],
      user_list_selected:{
        text:'Todos',
        value:'all'
      },
      user_list:null,
      user_list_test:null,
      cantidad_test:null,
      user_list_demo:null,
      cantidad_demo:null,
      user_list_prueba:null,
      cantidad_prueba:null,
      user_list_all:null,
      cantidad_user: null,
      register_succesful:false,
      error_username:false,
      error_email:false,
      error:false,
      loading_register:false,
      data_column_chart:null,
      email_buscado:null,
      delete_dialog:false,
      delete_succesful:false,
      id_user_to_delete:null,
      email_user_to_delete:null

    }
  },
  created() {
    this.get_data_users()
    this.get_data_test()
    this.get_data_demo()
    this.get_data_prueba()

    sleep(1500).then(() =>{
      this.data_column_chart = this.get_data_column_chart()
      this.user_list = this.user_list_all
    })
    
  },
  computed: {
    is_responsive () {
      if( this.$vuetify.breakpoint.name != 'xl'){
        return true
      }
      else {
        return false
      }
    },
    size_column_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 261,
          height_chart: 220
        }
      }
      else {
        return {
          height_card: 261,
          height_chart: 220
        }
      }
    },
  },
  watch: {
    email () {
      this.register_succesful = false
      this.error_username = false
      this.error_email = false
      this.error = false
    },

    username () {
      this.register_succesful = false
      this.error_username = false
      this.error_email = false
      this.error = false
    },
    
    email_buscado () {

      this.user_list = this.user_list.filter(e => e.email.includes(this.email_buscado))
      if(this.email_buscado === ''){
        this.user_list = this.get_data_list(this.user_list_selected)
      }
    }
  },
  methods: {
    delete_user(id) {
      if (id != null) {
        axios.delete('https://api.datari.net/auth/users/'+id)
        .then(response => {
          if (response.status == 204){
            this.delete_dialog = false
            this.delete_succesful = true
            this.get_data_users()
            this.get_data_test();
            this.get_data_demo();
            this.get_data_prueba();
            sleep(1500).then(() =>{
              this.data_column_chart = this.get_data_column_chart()
              this.user_list = this.get_data_list(this.user_list_selected)
              this.delete_succesful = false
            })
          }
        });
      }
    },

    openDeleteModal(item){
      this.delete_dialog = true
      this.id_user_to_delete = item.id
      this.email_user_to_delete = item.email
      
    },
    
    register () {
      this.loading_register = true
      if(this.$refs.form.validate()) {
        if(this.email != null &&  this.username != null && this.tipo_selected != null) {
          let email = this.email
          let username = this.username
          let user_type = this.tipo_selected.value
          let password = this.generatePassword()
          this.$store.dispatch('register', {email, username,password,user_type})
          .then( () => {
            sleep(2500).then(() => {
              this.loading_register = false
              this.register_succesful = true;
              this.get_data_users()
              this.get_data_test();
              this.get_data_demo();
              this.get_data_prueba();
              sleep(1500).then(() =>{
                this.data_column_chart = this.get_data_column_chart()
                this.user_list = this.get_data_list(this.user_list_selected)
              })
            });
          })
          .catch(err => {
            let type_error = err.response.data.errors

            sleep(2500).then(() => {
              this.loading_register = false
              if(Object.prototype.hasOwnProperty.call(type_error,'username')) {
                this.error_username = true
              }
            
              else if (Object.prototype.hasOwnProperty.call(type_error,'email')) {
                this.error_email = true
              }

              else {
                /* Error server */
                this.error = true
              }
            });
          })
        }
      }
    },

    get_data_column_chart(){
      let series = [this.cantidad_test, this.cantidad_demo, this.cantidad_prueba]
      let categories = ['Test','Demo','Suscripción']
      let colors = ['#6082B6','#E4D00A','#8A9A5B']

      return {
        series: [
          {
            data: series,
            name: 'Cantidad de usuarios'
          }
        ],
        categories: categories,
        colors: colors,
        unidad: 'Usuarios',
        tipo_unidad:'no_monto',
        tipo_categoria: 'regiones'
      }
    },

    async get_data_users() {
      let response = await axios.get(API_URL_USERS)
      let data = response.data

      this.user_list_all = data.results.filter(e => e.user_type !== 'staff')
      this.cantidad_user = this.user_list_all.length
      // this.cantidad_test = data.count;
    },

    async get_data_test() {
      let response = await axios.get(API_URL_USERS+"?user_type=test");
      let data = response.data
      this.user_list_test = data.results
      this.cantidad_test = data.count;
    },

    async get_data_demo() {
      let response = await axios.get(API_URL_USERS+"?user_type=demo");
      let data = response.data
      this.user_list_demo = data.results
      this.cantidad_demo = data.count;
    },

    async get_data_prueba() {
      let response = await axios.get(API_URL_USERS+"?user_type=prueba");
      let data = response.data
      this.user_list_prueba = data.results
      this.cantidad_prueba = data.count;
    },
    

    cambiarTipo(tipo) {
      if (tipo.value === 'test') {
        this.username = 'test_'+(this.cantidad_test+1)
      }
      else if(tipo.value === 'demo') {
        this.username = 'demo_'+(this.cantidad_demo+1)
      }
      else {
        this.username = null
      }
    },

    get_data_list(tipo) {
      let user_list = []
      if (tipo.value === 'test') {
        user_list = this.user_list_test
      }
      else if(tipo.value === 'demo') {
       user_list = this.user_list_demo
      }
      else if(tipo.value === 'prueba') {
        user_list = this.user_list_prueba
      }
      else if(tipo.value === 'all') {
        user_list = this.user_list_all
      }

      return user_list
    },

    cambiarTipoList(tipo) {
      this.user_list = this.get_data_list(tipo)
    },  

    generatePassword() {
      let length = 10;
      let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
      let retVal = ""

      for (let i = 0, n = charset.length; i < length; ++i) {
          retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    }
  }
}
</script>

<style>

</style>
HeaderComponent