<template>
  <div id="chart" >
    <apexchart type="line" :height="height_chart" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<style>
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center
}
</style>

<script>
export default {
  name: 'LineChart',
  props: [
    'data_chart','categories_chart', 'colors_chart',
    'tipo_unidad_chart','unidad_chart','nombre_chart',
    'height_chart'
  ],
  computed: {
    series () {
      return this.data_chart
    },
    chartOptions () {
      return {
        unidad: this.unidad_chart,
        tipo_unidad: this.tipo_unidad_chart,
        chart: {
          type: 'line',
          zoom: false,
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          }
        },
        colors:this.colors_chart,
        dataLabels: {
          enabled: false,
        },
        responsive: [{
          breakpoint: 1400,
          options: {
            xaxis: {
              labels: {
                style: {
                  colors: [],
                  fontSize: '11px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 500, 
                  cssClass: 'apexcharts-xaxis-label',
                },
              },
            }
          }
        }],
        stroke: {
          curve: 'smooth'
        },
      
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 5
        },
        xaxis: {
          categories: this.categories_chart,
        },
        yaxis: {
          labels: {
            show:false
          },
          title: {
            text: this.nombre_chart,
            style: {
              color: undefined,
              fontSize: '10px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 600,
              cssClass: 'apexcharts-yaxis-title',
            },
          },
          // min: 5,
          // max: 40
        },
        tooltip: {
          theme: 'dark',
          custom: function({series, seriesIndex,dataPointIndex, w}) {
            const formatNumber = (valor) => {
              let valor_format = valor.toLocaleString();
              let i;
              let result = '';

              for( i of valor_format ){
                if( i == '.') {
                  result = result + ','
                }

                else if( i == ',' ) {
                  result = result + '.'
                }

                else {
                  result = result + i
                }
              }
              return result
            };

            const get_variacion = (value, old_value,tipo_unidad) => {
              let variacion;
              if (tipo_unidad === 'porcentaje') {
                variacion = (value-old_value).toFixed(1)
              }
              else {
                
                if(old_value != 0){
                  let result = (value/old_value)*100
                  variacion = (result-100).toFixed(2)
                }
                else {
                  variacion = 100.00
                }
               
              }
              return variacion;
            };

            // let total = series[seriesIndex].reduce((a, b) => { return a + b}, 0);
            let value = series[seriesIndex][dataPointIndex]
            let old_value = dataPointIndex > 0 ? series[seriesIndex][dataPointIndex-1] : 0;
            let tipo_unidad = w.config.tipo_unidad
            let variacion = value > 0 && dataPointIndex > 0 ? get_variacion(value,old_value,tipo_unidad) : 0
            let value_formatted = formatNumber(value)
            // let porcentaje = ((value/total)*100).toFixed(1);
            let old_label = dataPointIndex > 0 ? w.globals.categoryLabels[dataPointIndex-1]+'-' : '' 
            let label = w.globals.categoryLabels[dataPointIndex];
            let unidad = w.config.unidad
    
            let text_value = tipo_unidad == 'monto' ? '$'+value_formatted : 
                              tipo_unidad == 'porcentaje' ? value_formatted+'%' :
                              value_formatted;

            let nombre_data = w.globals.seriesNames[seriesIndex]
            

            let text_variacion =  variacion > 0 ? variacion+' % <i class="mdi mdi-trending-up"></i>' :
                                  variacion < 0 ? variacion+' % <i class="mdi mdi-trending-down"></i>' : variacion+' %'

            let tooltip_html ='<div class="arrow_box" style="width:200px;height:170px;">'+
                              '<div class="title ml-3  font-weight-medium text-left">' + label + '</div>' +
                              '<div class="body-2 ml-3 mt-2 text-left">'+nombre_data+'</div>' +
                              '<div class="subtitle-1 font-weight-bold ml-3 text-left">'+text_value+'</div>' +
                              '<div class="subtitle-2 ml-3 text-left" style="margin-top:-5px;">' +unidad  + '</div>' +
                              '<div class="body-2 ml-3 mt-2 text-left">'+old_label+label+'</div>' +
                              '<div class="subtitle-1 font-weight-bold ml-3 text-left">'+text_variacion+'</div>' +
                             
                              '</div>'
            return tooltip_html
          }
        }
      }
    }
  }
}
</script>

<style>

</style>