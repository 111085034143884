<template>
  <div id="chart" class="chart-wrapper">
    <apexchart type="pie" :width="width_chart"  :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<style>
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center
}
</style>

<script>
export default {
  name: 'PieChart',
  props: [
    'data_chart','labels_chart','colors_chart',
    'legend_chart','width_chart','height_chart',
    'tipo_unidad_chart','unidad_chart'
  ],
  computed: {
    series () {
      return this.data_chart
    },

    chartOptions () {
      return {
        chart: {
          type: 'pie',
        },
        labels: this.labels_chart,
        legend:{
          position: this.legend_chart,
          horizontalAlign: 'center', 
          fontSize: '14px',
          fontFamily: 'Helvetica, Arial',
          fontWeight: 400,
          width: undefined,
          height: undefined,
        },
        colors: this.colors_chart,
        unidad: this.unidad_chart,
        tipo_unidad: this.tipo_unidad_chart,
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -15
            }
          }
        },
        dataLabels:{
          enabled: true,
          formatter: function (val) {
            if (val < 6){
              return ''
            }
            else {
              return val.toFixed(1)+'%'
            }
          },
          textAnchor: 'middle',
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          style: {
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 'bold',
              colors: ['#fff']
          },
        },
        responsive: [{
          breakpoint: 1500,
          options: {
            chart: {
              width: this.width_chart
            },
            legend: {
              show: this.width_chart > 400 ? true : false ,
              position: 'bottom'
            },
            dataLabels:{
              enabled: true,
              formatter: function (val) {
                if (val < 8){
                  return ''
                }
                else {
                  return val.toFixed(1)+'%'
                }
                  
              },
              textAnchor: 'middle',
              distributed: false,
              offsetX: 0,
              offsetY: 0,
              style: {
                  fontSize: '11px',
                  fontFamily: 'Helvetica, Arial, sans-serif',
                  fontWeight: 'bold',
                  colors: ['#fff']
              },
            },
          }
        }],
        tooltip: {
          custom: function({series, seriesIndex, w}) {
            const formatNumber = (valor) => {
              let valor_format = valor.toLocaleString();
              let i;
              let result = '';

              for( i of valor_format ){
                if( i == '.') {
                  result = result + ','
                }

                else if( i == ',' ) {
                  result = result + '.'
                }

                else {
                  result = result + i
                }
              }
              return result
            };

            let total = series.reduce((a, b) => { return a + b}, 0);
            let value = formatNumber(series[seriesIndex])
            let porcentaje = ((series[seriesIndex]/total)*100).toFixed(1);
            let label = w.globals.labels[seriesIndex];
            let unidad = w.config.unidad
            let tipo_unidad = w.config.tipo_unidad
            let text_value = tipo_unidad == 'monto' ? '$'+value : value

            let tooltip_html ='<div class="arrow_box" style="width:200px;height:120px;">'+
                              '<div class="title ml-3  font-weight-medium text-left">' + label + '</div>' +
                              '<div class="subtitle-1 font-weight-bold ml-3 text-left">'+text_value+'</div>' +
                              '<div class="subtitle-2 ml-3 text-left" style="margin-top:-5px;">' +unidad  + '</div>' +
                              '<div class="body-1 ml-3 mt-2 text-left">' +porcentaje+'%'+'</div>' +
                              '</div>'

            return tooltip_html
          }
        }
      }
    }
  }
}
</script>

<style>

</style>