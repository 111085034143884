<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col xl=4 sm=6>
          <v-row>
            <v-col xl=6 sm=6>  
              <info-card-simple 
                :icon="'mdi-information'"
                :titulo="region_detail.nombre"
                :subtitulo="selected_filtro_2.text + ' ' + selected_filtro_3.subtitle"
                :valor="data_region_tipo"
                :tipo_valor="selected_filtro_3.tipo_dato"
              />
            </v-col>
            
            <v-col sm=6 xl=6>
            <info-card-simple 
                :icon="'mdi-information'"
                :titulo="'País'"
                :subtitulo="selected_filtro_2.text + ' ' + selected_filtro_3.subtitle"
                :valor="data_pais_tipo"
                :tipo_valor="selected_filtro_3.tipo_dato"
              />
          
            </v-col>
          </v-row>
          <v-row style="margin-top:-15px">
            <v-col>
              <tranporte-territorial-map 
                v-if="data_mapa_region != null"
                :coordenadas_region="[ region_detail.lon, region_detail.lat]"
                :codigo_region="region_detail.numero_region"
                :data_mapa="data_mapa_region"
                :tipo_veh="selected_filtro_3"
                :height_mapa="size_mapa"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col xl=8 sm=6>
          <info-card-column-chart 
            :icon="'mdi-chart-bar'"
            :titulo="'Comparación Regional - '+selected_filtro_1"
            :subtitulo="selected_filtro_2.text + ' ' + selected_filtro_3.subtitle"
            :height_chart="size_column_chart.height_chart"
            :height_card="size_column_chart.height_card"
            :data="data_column_chart"
          />
          <info-card-line-chart 
            class="mt-2"
            :titulo="'Tráfico Anual'"
            :subtitulo="selected_filtro_3.subtitle"
            :icon="'mdi-trending-up'"
            :data="data_line_chart"
            :height_chart="size_line_chart.height_chart"
            :height_card="size_line_chart.height_card"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
const API_URL_MAPS = 'https://api.datari.net/datari/mapas/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardColumnChart from '../../components/infoCardColumnChart.vue';
import InfoCardLineChart from '../../components/infoCardLineChart.vue';
import TranporteTerritorialMap from '../../components/maps/TranporteTerritorialMap.vue';

export default {
  name: 'View14Region',
  components: { HeaderComponent, InfoCardSimple, InfoCardColumnChart, InfoCardLineChart, TranporteTerritorialMap},
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      selected_filtro_2: this.data_view.filtros[1].data[0],
      selected_filtro_3: this.data_view.filtros[2].data[0],
      data_region: null,
      data_region_tipo: null,
      data_pais: null,
      data_pais_tipo: null,
      data_regiones:null,
      data_anual_ingreso: null,
      data_anual_salida:null,
      data_column_chart:null,
      data_line_chart: null,
      data_mapa_region:null
    
    }
  },
  created() {
    this.get_data_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2.value)
    this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2.value)
    this.get_data_region_anual(this.region_selected)
    this.get_mapa_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2.value)
  },
  computed: {
    size_mapa () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl') {
        return 600
      }
      else {
        return 410
      }
    },
    size_line_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 433,
          height_chart: 350
        }
      }
      else {
        return {
          height_card: 255,
          height_chart:190
        }
      }
    },
     size_column_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 327,
          height_chart: 250
        }
      }
      else {
        return {
          height_card: 307,
          height_chart: 240
        }
      }
    }
  },
  watch: {
    region_selected() {
        this.get_data_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2.value)
        this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2.value)
        this.get_data_region_anual(this.region_selected)
        this.get_mapa_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2.value)

    }
  },
  methods: {

    async get_data_region(region, anio, tipo) {
      let url = this.data_view.url_api[0]
      let url_api = API_URL+url+'?region='+region+'&anio='+anio+'&tipo='+tipo
      let response = await axios.get(url_api)
      let data = response.data

      this.data_pais = data.datos_anuales
      let tipo_veh = this.selected_filtro_3.value
      this.data_pais_tipo = this.select_data_by_tipo(this.data_pais,tipo_veh)

      if (data.count > 0) {
        this.data_region = data.datos_anuales_region[0].totales
        this.data_region_tipo = this.select_data_by_tipo(this.data_region, tipo_veh) 
      }
      else {
        this.data_region_tipo = 'Sin Datos'
      }
    },

    async get_mapa_region(region, anio, tipo) {
      let url = this.data_view.url_api[1]
      let url_api = API_URL_MAPS+url+'?region='+region+'&anio='+anio+'&tipo='+tipo
      let response = await axios.get(url_api)
      let data = response.data.results
      this.data_mapa_region = data
    },

    async get_data_regiones(anio, tipo) {
      let url = this.data_view.url_api[0]
      let url_api = API_URL+url+'?anio='+anio+'&tipo='+tipo
      let response = await axios.get(url_api)
      let data = response.data
      this.data_regiones = data.datos_regiones

      let tipo_veh = this.selected_filtro_3
      this.data_column_chart = this.get_data_column_chart(this.data_regiones, tipo_veh)
    },

    async get_data_region_anual(region) {
      let url = this.data_view.url_api[0]
      let url_api = API_URL+url+'?region='+region
      let response = await axios.get(url_api)
      let data = response.data.results
      
      this.data_anual_ingreso = data.data_ingreso
      this.data_anual_salida = data.data_salida
      let tipo = this.selected_filtro_3

      this.data_line_chart = this.get_data_line_chart(this.data_anual_ingreso, this.data_anual_salida, tipo)
     
    },

    get_data_line_chart(data_ingreso, data_salida, tipo) {
      let series_ingreso = []
      let series_salida = []
      let categories = []

      for (let element of data_ingreso) {
        if (!categories.includes(element.anio)) {
          categories.push(element.anio)
        }

        series_ingreso.push(element[tipo.value])
      }

      for (let element of data_salida) {
         series_salida.push(element[tipo.value])
      }

      let unidad = 'Miles de Dolares (FOB)'
      let tipo_unidad = 'no_monto'

      return {
        series: [
          {
            name: 'Ingreso',
            data: series_ingreso
          },
          {
            name: 'Salida',
            data: series_salida
          }
        ],
        categories: categories,
        colors: ['#254cb4','#398104'],
        unidad: tipo.tipo_dato,
        tipo_unidad: tipo_unidad
      }
    },

    get_data_column_chart(data, tipo){
      let series = []
      let categories = []
      let colors = []
      
      for (let element of data) {
        if(element.region.id != 18){
          if (!categories.includes(element.region)) {
            let category = element.region.includes('Arica') ? 
                            ['Arica','Parinacota'] : 
                            element.region;
            
            categories.push(category);
            let dato = element.datos_anuales_region.totales[tipo.value]
            series.push(dato)

            if (element.id_region == this.region_selected) {
              colors.push('#398104');
            }
            else{
              colors.push("#898989");
            }
          }
        }
      }

      return {
        series: [
          {
            data: series,
            name: 'Cantidad'
          }
        ],
        categories: categories,
        colors: colors,
        unidad: tipo.tipo_dato,
        tipo_unidad:'no_monto',
        tipo_categoria: 'regiones'
      }
    }, 
    
    select_data_by_tipo(data,tipo) {
      return formatNumber(data[tipo])
    },

    updateFiltro(newValue, indexFiltro) {

      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
        this.get_data_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2.value)
        this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2.value)
        this.get_mapa_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2.value)


      }

      else if (indexFiltro == 1) {
        this.selected_filtro_2 = newValue
        this.get_data_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2.value)
        this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2.value)
        this.get_mapa_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2.value)

      }

      else if (indexFiltro == 2) {
        this.selected_filtro_3 = newValue
        this.data_region_tipo = this.select_data_by_tipo(this.data_region, this.selected_filtro_3.value)
        this.data_pais_tipo = this.select_data_by_tipo(this.data_pais,this.selected_filtro_3.value)
        this.data_column_chart = this.get_data_column_chart(this.data_regiones, this.selected_filtro_3)
        this.data_line_chart = this.get_data_line_chart(this.data_anual_ingreso, this.data_anual_salida, this.selected_filtro_3)


      }
      // this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2)
    }
  }
}
</script>

<style>

</style>