<template>
  <div id="chart">
    <apexchart type="treemap" :height="height_chart" width="100%" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<style scoped>
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>


<script>
export default {
  name:'TreeMap',
  props: [
    'data_chart', 
    'colors_chart',
    'unidad_chart',
    'nombre_data',
    'tipo_unidad_chart',
    'distributed_chart',
    'height_chart'
  ],
  computed: {
    series () {
      return [
        {
          data: this.data_chart
        }
      ]
    },

    chartOptions () {
      return {
        legend: {
          show: false
        },
        chart: {
          type: 'treemap',
          toolbar: {
            show:false
          }
        },
        colors: this.colors_chart,
        unidad: this.unidad_chart,
        tipo_unidad: this.tipo_unidad_chart,
        nombre_data: this.nombre_data,
        dataLabels:{
          enabled: true,
          style:{
            colors: ["#fff"],
            fontSize: '22px',
            fontFamily: 'Helvetica, sans-serif',
            fontWeight: 'medium',
          },
           formatter: function (val, opts) {
            let serie_data = opts.w.globals.series[0]
            let total = serie_data.reduce((a, b) => { return a + b}, 0);
            let value = opts.value

            let porcentaje = ((value/total)*100)
            
            if (porcentaje < 3) {
              return ''
            }
            else {
              return val
            }
          },
        },
        plotOptions: {
          treemap: {
            distributed: this.distributed_chart,
            enableShades: this.distributed_chart ? false : true ,
            shadeIntensity: 0.3,
          }
        },
        tooltip: {
          theme: 'dark',
          custom: function({series, seriesIndex,dataPointIndex, w}) {
            const formatNumber = (valor) => {
              let valor_format = valor.toLocaleString();
              let i;
              let result = '';

              for( i of valor_format ){
                if( i == '.') {
                  result = result + ','
                }

                else if( i == ',' ) {
                  result = result + '.'
                }

                else {
                  result = result + i
                }
              }
              return result
            };

            let total = series[seriesIndex].reduce((a, b) => { return a + b}, 0);
            let value = series[seriesIndex][dataPointIndex]
            let porcentaje = ((value/total)*100).toFixed(2)
            let label = w.globals.categoryLabels[dataPointIndex]
            let unidad = w.config.unidad
            let tipo_unidad = w.config.tipo_unidad
            let nombre_data = w.config.nombre_data

            let text_value = tipo_unidad == 'monto' ? '$'+formatNumber(value) :
                             tipo_unidad == 'porcentaje' ? value.toFixed(2)+'%' :
                             formatNumber(value)

            let text_html = tipo_unidad !== 'porcentaje' ?  
                              '<div class="body-2 ml-3 mt-2 text-left">Porcentaje</div>' +
                              '<div class="subtitle-1 font-weight-bold ml-3 text-left">'+porcentaje+'%</div>' :
                              ''

            
            let tooltip_html ='<div class="arrow_box" style="width:330px;height:170px;">'+
                              '<div class="title ml-3  font-weight-medium text-left">' + label + '</div>' +
                              '<div class="body-2 ml-3 mt-2 text-left">'+nombre_data+'</div>' +
                              '<div class="subtitle-1 font-weight-bold ml-3 text-left">'+text_value+'</div>' +
                              '<div class="subtitle-2 ml-3 text-left" style="margin-top:-5px;">' +unidad  + '</div>' +
                              text_html+
                              '</div>'
            return tooltip_html

          }
        }
      }
    }
  }
}
</script>
