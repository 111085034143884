<template>
  <v-card flat :height="height_map"> 
    <div id="mapContainer" class="basemap"></div>
  </v-card>
</template>

<style>
.basemap {
  width: 100%;
  height: 100%;
}
</style>

<script>
import mapboxgl from "mapbox-gl";
import {getZoomRegion, formatNumeroRegion} from '@/functions/functions.js'
export default {
  name: 'MapRegionComunas',
  props: [
    'coordenadas_region',
    'codigo_region',
    'height_map'
  ],
  data () {
    return {
      accessToken: 'pk.eyJ1IjoibGFiZGV2MjIiLCJhIjoiY2tzaHFvamRtMXdwZDJ1b2E2ZHRrMDNvYyJ9.A2-aL3_dJ9BIK26yO0Ahug',
      mapa_region:null
    }
  },
  mounted(){
    const zoom_region = this.get_zoom_region(this.codigo_region)
    const coordenadas_region = this.coordenadas_region
    const codigo_region = this.formatNumeroRegion(this.codigo_region)
    const mapa = this.create_basemap(coordenadas_region,zoom_region)
    this.addRegionSource(mapa);
    this.addRegionLayer(mapa,codigo_region)
  },
  computed: {
    dataRegion () {
      return this.codigo_region
    }
  },
  watch: {
    dataRegion(){
      const zoom_region = this.get_zoom_region(this.codigo_region)
      const codigo_region = this.formatNumeroRegion(this.codigo_region);
      const coordenadas_region = this.coordenadas_region
      const mapa = this.create_basemap(coordenadas_region,zoom_region)
      this.addRegionSource(mapa);
      this.addRegionLayer(mapa,codigo_region)

    }
  },
  methods: {
    create_basemap(coordinates,zoom_region){

      mapboxgl.accessToken = this.accessToken;
      let basemap = new mapboxgl.Map({
        container: "mapContainer",
        style: "mapbox://styles/mapbox/light-v10",
        center: coordinates,
        zoom: zoom_region,
      });

      basemap.addControl(new mapboxgl.NavigationControl());
      // basemap.addControl(new mapboxgl.FullscreenControl());

      return basemap;
    },
    
    addRegionSource(mapa){
       mapa.on('load', () => {
        mapa.addSource('map_regiones', {
          type: 'vector',
          url: 'mapbox://labdev22.azm906hb',
        });
      });
    },
    
    addRegionLayer(mapa,codigo_region){
      mapa.on('load', () => {
        mapa.addLayer({
          id:'region',
          source: 'map_regiones',
          'source-layer': 'chile_regiones_comunas-6uwztq',
          type: 'fill',
          paint: {
            // 'fill-color': '#5D6D7E',
            'fill-color': [
              'match', ['get','codigo_region'],
              codigo_region,  '#ced454',
              '#5D6D7E'
            ],
            'fill-opacity':0.3,
            'fill-outline-color': '#00194c'
          },
          // filter: ['==',['get', 'codigo_region'], codigo_region]
        });

        mapa.addLayer({
          'id': 'borde-regiones',
          'type': 'line',
          source: 'map_regiones',
          'source-layer': 'chile_regiones_comunas-6uwztq',
          'paint': {
            'line-color': '#414141',
            'line-width': 0.2,
            'line-opacity': 0.3
          },
        });
      });
    },

   

  

    get_zoom_region(codigo_region){
      return getZoomRegion(codigo_region)
    },

    formatNumeroRegion(codigo_region){
      return formatNumeroRegion(codigo_region)
    },
  }
}
</script>

<style>

</style>