
<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col>
          <info-card-simple
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Índice: Sin Hacinamiento'"
            :valor="data_region_1"
            :tipo_valor="selected_filtro_2"
          />
        </v-col>
        <v-col>
          <info-card-simple
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Índice: Medio-Bajo'"
            :valor="data_region_2"
            :tipo_valor="selected_filtro_2"
          />
        </v-col>
        <v-col>
          <info-card-simple
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Índice: Medio-Alto'"
            :valor="data_region_3"
            :tipo_valor="selected_filtro_2"
          />
        </v-col>
        <v-col>
          <info-card-simple
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Índice: Critico'"
            :valor="data_region_4"
            :tipo_valor="selected_filtro_2"
          />
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px;">
        <v-col>
          <info-card-line-chart 
            :titulo="'Variación Anual Hacinamiento'"
            :subtitulo="'Índice: '+selected_filtro_1.text"
            :icon="'mdi-trending-up'"
            :data="data_line_chart"
            :height_chart="size_line_chart.height_chart"
            :height_card="size_line_chart.height_card"
          />
        </v-col>
        <v-col>
          <info-card-tree-map 
            :icon="'mdi-chart-tree'"
            :titulo="'Hacinamiento por Índice'"
            :subtitulo="region_detail.nombre"
            :height_card="size_tree_map.height_card"
            :height_chart="size_tree_map.height_chart"
            :data="data_tree_map"
          />
          
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardLineChart from '../../components/infoCardLineChart.vue';
import InfoCardTreeMap from '../../components/infoCardTreeMap.vue';

export default {
  name: 'View9Region',
  components: { HeaderComponent, InfoCardSimple, InfoCardLineChart, InfoCardTreeMap},
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      selected_filtro_2: this.data_view.filtros[1].data[0],
      data_region_1:null,
      data_region_2:null,
      data_region_3:null,
      data_region_4: null,
      data_tree_map:null,
      data_line_chart:null
    }
  },
  created() {
    this.get_data_region(this.region_selected, this.selected_filtro_2)
    this.get_data_region_anios(this.region_selected, this.selected_filtro_1)
  },
  computed : {
    size_line_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 550,
          height_chart: 450
        }
      }
      else {
        return {
          height_card: 390,
          height_chart: 320
        }
      }
    },

    size_tree_map () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 550,
          height_chart: 470
        }
      }
      else {
        return {
          height_card: 390,
          height_chart: 310
        }
      }
    }
  },
  watch: {
    region_selected () {
      this.get_data_region(this.region_selected, this.selected_filtro_2)
      this.get_data_region_anios(this.region_selected, this.selected_filtro_1)
    }
  },
  methods: {

    async get_data_region(region, anio){
      let url = this.data_view.url_api[0];
      let url_api = API_URL+url+'?region='+region+'&anio_data='+anio
      let response = await axios.get(url_api)
      let data = response.data.results
      let data_region_1 = data[0].valor
      let data_region_2 = data[1].valor
      let data_region_3 = data[2].valor
      let data_region_4 = data[3].valor

      this.data_region_1 = data_region_1.toFixed(2)+'%'
      this.data_region_2 = data_region_2.toFixed(2)+'%'
      this.data_region_3 = data_region_3.toFixed(2)+'%'
      this.data_region_4 = data_region_4.toFixed(2)+'%'

      this.order_by_valor(data)
      let data_tree_map = this.get_data_tree_map(data)
      this.data_tree_map = data_tree_map
    },

    async get_data_region_anios(region, tipo_indice) {
      let url = this.data_view.url_api[0];
      let url_api = API_URL+url+'?region='+region+'&categoria='+tipo_indice.value
      let response = await axios.get(url_api)
      let data = response.data.results
      let data_line_chart = this.get_data_line_chart(data)
      this.data_line_chart = data_line_chart
    },

    get_data_line_chart(data) {
      let series = []
      let categories = []

      for (let element of data) {
        if (!categories.includes(element.anio_data)) {
          categories.push(element.anio_data)
        }

        series.push(element.valor)
      }

      let unidad = ''
      let tipo_unidad = 'porcentaje'
      let nombre_data = 'Índice de hacimaniento'

      return {
        series:  [
          {
            name: nombre_data,
            data: series
          }
        ],
        categories: categories,
        colors: ['#254cb4'],
        unidad: unidad,
        tipo_unidad: tipo_unidad
      }
    },

    get_data_tree_map(data){
      let series = []

      for (let element of data) {
        let tipo = element.categoria.tipo
        let valor = element.valor
        
        let data_element = {
          x: tipo,
          y: valor
        }

        series.push(data_element)
      }

      return {
        data: series,
        distributed: false,
        nombre_data: 'Índice Hacinamiento',
        unidad: '',
        tipo_unidad:'porcentaje',
        tipo_categoria: 'no_regiones'
      }
    },

    order_by_valor(data) {
      data.sort(function (a,b) {
        return b.valor - a.valor
      });
    },

     updateFiltro(newValue, indexFiltro) {

      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
        this.get_data_region_anios(this.region_selected, this.selected_filtro_1)

      }

      else if (indexFiltro == 1) {
        this.selected_filtro_2 = newValue
        this.get_data_region(this.region_selected, this.selected_filtro_2)
      }
    }
  }
}
</script>

<style>

</style>