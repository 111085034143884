<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col sm=3 xl=4>
          <info-card-simple 
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Predios '+selected_filtro_2.text"
            :valor="data_region_predios"
            :tipo_valor="unidad_data_predios"
          />

          <info-card-simple 
            class="mt-2"
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Avaluo '+selected_filtro_2.text"
            :valor="data_region_avaluo"
            :tipo_valor="unidad_data_avaluo"
          />
        </v-col>
        <v-col sm=9 xl=8>
          <info-card-column-chart 
            :icon="'mdi-chart-bar'"
            :titulo="'Comparación Regional'"
            :subtitulo="selected_filtro_1.text+' '+selected_filtro_2.text"
            :height_chart="size_column_chart.height_chart"
            :height_card="size_column_chart.height_card"
            :data="data_column_chart"
          /> 
        </v-col>
      </v-row>
      <v-row style="margin-top: -15px;">
        <v-col>
          <info-card-tree-map 
            :icon="'mdi-chart-tree'"
            :titulo="'Comparación Comunal'"
            :subtitulo="selected_filtro_1.text+' '+selected_filtro_2.text"
            :height_card="size_tree_map.height_card"
            :height_chart="size_tree_map.height_chart"
            :data="data_tree_map"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardColumnChart from '../../components/infoCardColumnChart.vue';
import InfoCardTreeMap from '../../components/infoCardTreeMap.vue';

export default {
  components: { HeaderComponent, InfoCardSimple, InfoCardColumnChart, InfoCardTreeMap },
  name: 'View4Region',
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      //Tipo data
      selected_filtro_1:this.data_view.filtros[0].data[0],
      //Tipo 
      selected_filtro_2:this.data_view.filtros[1].data[0],
      //Año
      selected_filtro_3:this.data_view.filtros[2].data[0],

      data_region_predios: null,
      unidad_data_predios:null,
      data_region_avaluo: null,
      unidad_data_avaluo:null,
      data_column_chart: null,
      data_tree_map:null
    }
  },
  created () {
    this.get_data_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2, this.selected_filtro_3)
    this.get_data_comunas_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2, this.selected_filtro_3)
    this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2, this.selected_filtro_3)
  },
  computed: {
    size_column_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 326,
          height_chart: 250
        }
      }
      else {
        return {
          height_card: 307,
          height_chart: 235
        }
      }
    },

    size_tree_map () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 430,
          height_chart: 355
        }
      }
      else {
        return {
          height_card: 260,
          height_chart: 185
        }
      }
    }
  },
  watch: {
    region_selected () {
      this.get_data_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2, this.selected_filtro_3)
      this.get_data_comunas_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2, this.selected_filtro_3)
      this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2, this.selected_filtro_3)
    }
  },
  methods: {

    async get_data_region(region,tipo_data, tipo, anio) {
      let url = this.data_view.url_api[0];
      let url_api = API_URL+url+'?region='+region+'&anio='+anio
      let response = await axios.get(url_api)
      let data = response.data.results[0]

      let data_region_predios, data_region_avaluo 

      let tipo_dato_predios = this.get_tipo_dato_predios(tipo)
      let tipo_dato_avaluo = this.get_tipo_dato_avaluo(tipo)

      data_region_predios = data[tipo_dato_predios]
      data_region_avaluo = data[tipo_dato_avaluo]
      
      let unidad_predios = 'Predios'
      let unidad_avaluo = 'Millones de Pesos'

      this.data_region_predios = formatNumber(data_region_predios)
      this.unidad_data_predios = unidad_predios
      this.data_region_avaluo = '$'+formatNumber(data_region_avaluo)
      this.unidad_data_avaluo = unidad_avaluo
    },

    async get_data_comunas_region(region, tipo_data, tipo, anio) {
      let url = this.data_view.url_api[1];
      let url_api = API_URL+url+'?region='+region+'&anio='+anio
      let response = await axios.get(url_api)
      let data = response.data.results
      
      let tipo_dato = tipo_data.id === 'predios' ? this.get_tipo_dato_predios(tipo) :
                      this.get_tipo_dato_avaluo(tipo)

      let nombre_data = tipo_data.text + ' ' + tipo.text
      let unidad = tipo_data.id === 'predios' ? 'Predios' : 'Millones de Pesos'
      let tipo_unidad = tipo_data.id === 'predios' ? 'no_monto' : 'monto'
        
      this.order_by_tipo(data,tipo_dato) 

      let data_tree_map = this.get_data_tree_map(data, tipo_dato)
      data_tree_map['unidad'] = unidad
      data_tree_map['tipo_unidad'] = tipo_unidad
      data_tree_map['nombre_data'] = nombre_data     

      this.data_tree_map = data_tree_map
    },

    order_by_tipo(data,tipo) {
      data.sort(function (a,b) {
        return b[tipo] - a[tipo]
      });
    },

    get_data_tree_map(data, tipo_dato){
      let series = []

      for (let element of data) {
        let comuna = element.comuna.nombre
        let valor = element[tipo_dato]
        
        let data_element = {
          x: comuna,
          y: valor
        }

        series.push(data_element)
      }

      return {
        data: series,
        distributed: false
      }
    },

    async get_data_regiones(tipo_data, tipo, anio) {
      let url = this.data_view.url_api[0];
      let url_api = API_URL+url+'?anio='+anio
      let response = await axios.get(url_api)
      let data = response.data.results
      
      let unidad_predios = 'Predios'
      let unidad_avaluo = 'Millones de Pesos'

      let tipo_dato = tipo_data.id === 'predios' ? this.get_tipo_dato_predios(tipo) :
                      this.get_tipo_dato_avaluo(tipo)

      let nombre_data = tipo_data.text + ' ' + tipo.text

      let data_column_chart = this.get_data_column_chart(data, tipo_dato, nombre_data)
     
      let unidad_column_chart = tipo_data.id === 'predios' ? unidad_predios : unidad_avaluo
      let tipo_unidad_column_chart = tipo_data.id === 'predios' ? 'no_monto' : 'monto'

      data_column_chart['unidad'] = unidad_column_chart
      data_column_chart['tipo_unidad'] = tipo_unidad_column_chart
      data_column_chart['tipo_categoria'] = 'regiones'

      this.data_column_chart = data_column_chart
    },

    get_data_column_chart(data, tipo_dato,nombre_data) {
      let series = []
      let categories = []
      let colors = []

      for (let element of data) {
        let id_region = element.region.id
        if (id_region != 18) {
          if (!categories.includes(element.region.nombre)) {
            let category = element.region.nombre.includes('Arica') ? ['Arica','Parinacota'] : 
                            element.region.nombre;
            
            categories.push(category);
          
          }

          let dato = element[tipo_dato]
          series.push(dato)

          if (id_region == this.region_selected) {
            colors.push('#398104');
          }
          else{
            colors.push("#898989");
          }
        }
      }

      return {
        series: [
          {
            data: series,
            name: nombre_data
          }
        ],
        categories: categories,
        colors: colors
      }
    },

    get_tipo_dato_predios(tipo) {
      let tipo_dato_predios = tipo.id === 'totales' ? 'predios_totales' :
                              tipo.id === 'exento' ? 'predios_exentos':
                              'predios_afectos'
      
      return tipo_dato_predios
    },

     get_tipo_dato_avaluo(tipo) {
      let tipo_dato_avaluo = tipo.id === 'totales' ? 'total_avaluo' :
                              tipo.id === 'exento' ? 'total_avaluo_exento':
                              'total_avaluo_afecto'
      
      return tipo_dato_avaluo
    },

    updateFiltro(newValue, indexFiltro) {

       if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
      }

      else if (indexFiltro == 1) {
        this.selected_filtro_2 = newValue
      }

      else if (indexFiltro == 2) {
        this.selected_filtro_3 = newValue
      }

      this.get_data_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2, this.selected_filtro_3)
      this.get_data_comunas_region(this.region_selected, this.selected_filtro_1, this.selected_filtro_2, this.selected_filtro_3)
      this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2, this.selected_filtro_3)

      
    }
  }
}
</script>

<style>

</style>