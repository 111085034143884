<template>
  <div id="header">
    <v-app-bar color="light-blue darken-2" class="rounded" flat dark dense>
      <h2 class="font-weight-medium">{{titulo}}</h2>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'HeaderSimple',
  props:['titulo','fuente']
}
</script>

<style>

</style>