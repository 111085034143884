<template>
  <div id="reset_password">
    <v-app>
      <v-main style="background-color:#E0E0E0">
        <v-container  fill-height>
          <v-layout align-center justify-center>
            <v-flex style="max-width:400px">
              <v-card>
                <v-card-title class="justify-center">
                  <div class="d-flex">
                    <v-img
                      alt="labict logo"
                      class="shrink "
                      contain
                      src="@/media/logo_azul.png"
                      transition="scale-transition"
                      width="200"
                      height ="56"
                    />
                  </div>
                </v-card-title>
                <v-card-text class="mt-0 px-6">
                  <div>
                    <h2 class="font-weight-medium">Recuperación de Contraseña</h2>
                    <h3 class="font-weight-medium mt-3">Ingrese su Email</h3>
                  </div>
                  <div class="mt-6">
                    <v-form @submit.prevent="reset"  ref="form">
                      <v-text-field  
                        v-model='email' 
                        light 
                        outlined
                        prepend-inner-icon="mdi-account"
                        label='Email' 
                        type='email'  
                        :rules="[v => !!v || 'Ingrese un Email'] "
                      />
                  
                      <div>
                        <v-alert
                          dense
                          type="success"
                          :value="valido"
                          transition="fade-transition"
                        >             
                          <div style="margin-left:-40px;">
                            <span>Correo enviado!</span>
                          </div>
                        </v-alert>
                      </div>
                      <v-btn color="#1565C0" block type='submit' class="white--text mt-2">
                        Continuar
                      </v-btn>
                    </v-form>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
const API_URL_RESET = 'https://api.datari.net/auth/request-reset-email/';
import axios from 'axios';

export default {
name: 'Reset',
  data(){
    return {
      email: null,
      valido: false,
    }
  },
  methods: {
    reset() {
      this.$refs.form.validate();
      // let change_password_url = 'https://datari.net/change_password/'
      let change_password_url = 'https://beta.datari.net/change_password/'
      // let change_password_url = 'http://localhost:8080/change_password/'
      if(this.email != null){
        let email = this.email;
        axios({
          method: 'post',
          url: API_URL_RESET,
          data: {
              email: email,
              redirect_url: change_password_url
          }
        });
        this.valido=true;
      }
    }
  },
}
</script>