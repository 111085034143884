<template>
  <div>
    <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
      <v-toolbar-title>
        <h3 class="font-weight-light" v-if="this.$vuetify.breakpoint.name === 'xl'">{{titulo}}</h3>
        <h4 class="font-weight-light" v-else>{{titulo}}</h4>
      </v-toolbar-title>
      <div v-if="filtros.length != 0" class="ml-5">
        <v-row>
          <v-col v-for="(filtro,index) in filtros.slice(0,2)" :key="index"
            :xl="filtro.text === 'Año' && filtros.length > 1 ? 3 : 
                  filtro.text === 'Año'  && filtros.length == 1 ? 5 : 
                  filtro.text !== 'Año' && filtros.length > 1 ? 4 : 
                  filtro.text !== 'Año' && filtros.length == 1 ? 8 : 4"
            
            :sm="filtro.text === 'Año'  && filtros.length > 1 ? 3 :
                  filtro.text === 'Año'  && filtros.length == 1 ? 5 : 
                  filtro.text !== 'Año' && filtros.length >1 ? 5 :
                  filtro.text !== 'Año' && filtros.length == 1 ? 6 : 5"
          >
            <v-select
              :value="filtro.data[0]"
              :items="filtro.data"
              :label="filtro.text"
              hide-no-data
              hide-details
              flat
              solo-inverted
              dense
              item-value="id"
              item-text="text"
              @change="changeItem($event,index)"
              return-object
            >
            </v-select>
          </v-col>
          <v-col v-if="filtros.length > 2" xl=1 sm=1>
            <v-bottom-sheet
              v-model="mas_filtros"
              hide-overlay
              width="800"
              
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="light-blue darken-4"
                  dark        
                  elevation="1"
                  v-bind="attrs"
                  v-on="on"
                >
                  +Filtros
                </v-btn>
              </template>
               <v-sheet
                  class="text-center" 
                  height="130px"
                  rounded
                 
                >
                <div style="width:100%;" class="centrar">
                  <div class="mt-4 ml-2 mr-2" v-for="(filtro,index) in filtros.slice(2)" :key="index">
                    <v-select
                      :value="filtro.data[0]"
                      :items="filtro.data"
                      :label="filtro.text"
                      hide-no-data
                      hide-details
                      outlined
                      dense
                      item-value="id"
                      item-text="text"
                      @change="changeItem($event,index+2)"
                      return-object
                    />
                  </div>
                </div>
                <v-btn
                  class="mt-4"
                  color="error"
                  @click="mas_filtros = !mas_filtros"
                >
                  <v-icon small>mdi-close</v-icon>
                  <span class="ml-1">Cerrar</span>
                </v-btn>
              </v-sheet> 
            </v-bottom-sheet>
          </v-col>
        </v-row>
      </div>
      <v-spacer></v-spacer>
      <div id="fuente">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
            
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>Fuente: {{fuente}} </span>
        </v-tooltip>
      </div>
    </v-app-bar>
  </div>
</template>

<style>
.centrar {
  display: flex;
   align-items: center;
  justify-content: center; 
}
</style>

<script>
export default {
  name: 'Header',
  props:[
    'titulo','fuente',
    'filtros'
  ],
  data: () => ({
    mas_filtros:false
  }),
  methods: {
    changeItem (value,indexFiltro) {
      this.$emit('update',value,indexFiltro);
    }
  }
}
</script>

<style>

</style>