<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col sm=3 xl=3>
          <info-card-simple 
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Exportación '+selected_filtro_2.text"
            :valor="data_region_anio_tipo"
            :tipo_valor="'Miles de Dolares (FOB)'"
          />

          <info-card-simple 
            class="mt-2"
            :icon="'mdi-information'"
            :titulo="'País'"
            :subtitulo="'Exportación '+selected_filtro_2.text"
            :valor="data_pais_anio_tipo"
            :tipo_valor="'Miles de Dolares (FOB)'"
          />
        </v-col>
        <v-col xl=9 sm=9>
          <info-card-column-chart 
            :icon="'mdi-chart-bar'"
            :titulo="'Comparación Regional'"
            :subtitulo="'Exportación '+selected_filtro_2.text"
            :height_chart="size_column_chart.height_chart"
            :height_card="size_column_chart.height_card"
            :data="data_column_chart"
          />
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px;">
        <v-col sm=4 xl=4>
          <info-card-tree-map 
            :icon="'mdi-chart-tree'"
            :titulo="'Distribución por Rubro'"
            :height_card="size_tree_map.height_card"
            :height_chart="size_tree_map.height_chart"
            :data="data_tree_map"
          />
        </v-col>
        <v-col sm=8 xl=8>
          <info-card-line-chart 
            :titulo="'Exportación Anual'"
            :subtitulo="selected_filtro_2.text"
            :icon="'mdi-trending-up'"
            :data="data_line_chart"
            :height_chart="size_line_chart.height_chart"
            :height_card="size_line_chart.height_card"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardLineChart from '../../components/infoCardLineChart.vue';
import InfoCardTreeMap from '../../components/infoCardTreeMap.vue';
import InfoCardColumnChart from '../../components/infoCardColumnChart.vue';

export default {
  name: 'View12Region',
  components: { HeaderComponent, InfoCardSimple, InfoCardLineChart, InfoCardTreeMap, InfoCardColumnChart},
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      selected_filtro_2: this.data_view.filtros[1].data[0],
      data_region_anio:null,
      data_pais_anio: null,
      data_regiones_anio:null,
      data_region_anios:null,
      data_region_anio_tipo: null,
      data_pais_anio_tipo: null,
      data_line_chart: null,
      data_tree_map:null,
      data_column_chart:null
    }
  },
  created() {
    this.get_data_region_anio(this.region_selected, this.selected_filtro_1)
    this.get_data_region(this.region_selected)
    this.get_data_regiones_anio(this.selected_filtro_1)
  },
  computed : {
    size_line_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 430,
          height_chart: 350
        }
      }
      else {
        return {
          height_card: 255,
          height_chart:190
        }
      }
    },

    size_tree_map () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 430,
          height_chart: 380
        }
      }
      else {
        return {
          height_card: 255,
          height_chart: 210
        }
      }
    },
    size_column_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 327,
          height_chart: 250
        }
      }
      else {
        return {
          height_card: 307,
          height_chart: 240
        }
      }
    }
  },
  watch: {
    region_selected() { 
      this.get_data_region_anio(this.region_selected, this.selected_filtro_1)
      this.get_data_region(this.region_selected)
      this.get_data_regiones_anio(this.selected_filtro_1)
    }
  },
  methods: {

    async get_data_region_anio(region, anio) {
      let url = this.data_view.url_api[0]
      let url_api = API_URL+url+'?region='+region+'&anio='+anio
      let response = await axios.get(url_api)
      let data = response.data.results[0]
      
      let data_rubros_region = data.data_rubros
      this.data_tree_map = this.get_data_tree_map(data_rubros_region)
      
      let data_region = {
        exportaciones_agricola: data.exportaciones_agricola,
        exportaciones_forestal: data.exportaciones_forestal,
        exportaciones_pecuario: data.exportaciones_pecuario,
        exportaciones_total: data.exportaciones_total
      }

      this.data_region_anio = data_region;
      let tipo_data = this.selected_filtro_2.value
      this.data_region_anio_tipo =this.select_data_by_tipo(data_region, tipo_data)
    },

    async get_data_region(region) {
      let url = this.data_view.url_api[0]
      let url_api = API_URL+url+'?region='+region
      let response = await axios.get(url_api)
      let data = response.data.results

      this.order_by_anio(data)
      this.data_region_anios = data
      let tipo = this.selected_filtro_2
      this.data_line_chart = this.get_data_line_chart(data, tipo)
    },

    async get_data_regiones_anio(anio) {
      let url = this.data_view.url_api[0]
      let url_api = API_URL+url+'?anio='+anio
      let response = await axios.get(url_api)
      let data = response.data.results

      let data_pais_anio = data.find(e => e.region.id == 18)
      
      let data_pais = {
        exportaciones_agricola: data_pais_anio.exportaciones_agricola,
        exportaciones_forestal: data_pais_anio.exportaciones_forestal,
        exportaciones_pecuario: data_pais_anio.exportaciones_pecuario,
        exportaciones_total: data_pais_anio.exportaciones_total
      }

      this.data_pais_anio = data_pais;
      let tipo_data = this.selected_filtro_2.value
      this.data_pais_anio_tipo =this.select_data_by_tipo(data_pais, tipo_data)

      let tipo = this.selected_filtro_2
      this.data_regiones_anio = data
      this.data_column_chart = this.get_data_column_chart(data, tipo)  
    },

    get_data_column_chart(data, tipo){
      let series = []
      let categories = []
      let colors = []
      
      for (let element of data) {
        if(element.region.id != 18){
          if (!categories.includes(element.region.nombre)) {
            let category = element.region.nombre.includes('Arica') ? 
                            ['Arica','Parinacota'] : 
                            element.region.nombre;
            
            categories.push(category);
            let dato = element[tipo.value]
            series.push(dato)

            if (element.region.id == this.region_selected) {
              colors.push('#398104');
            }
            else{
              colors.push("#898989");
            }
          }
        }
      }

      return {
        series: [
          {
            data: series,
            name: 'Exportación '+tipo.text
          }
        ],
        categories: categories,
        colors: colors,
        unidad: 'Miles de Dolares (FOB)',
        tipo_unidad:'monto',
        tipo_categoria: 'regiones'
      }
    }, 

    get_data_tree_map(data){
      let series = []
      let colors = []

      this.order_by_valor(data)

      for (let element of data) {
        let rubro = element.rubro
        let valor = element.valor
        
        let data_element = {
          x: rubro,
          y: valor
        }

        series.push(data_element)
        colors.push(element.color)
      }

      return {
        data: series,
        distributed: true,
        nombre_data: 'Exportación por Rubro',
        unidad: 'Miles de Dolares (FOB)',
        tipo_unidad:'monto',
        colors: colors
        
      }
    },

    get_data_line_chart(data, tipo) {
      let series = []
      let categories = []

      for (let element of data) {
        if (!categories.includes(element.anio)) {
          categories.push(element.anio)
        }

        series.push(element[tipo.value])
      }

      let unidad = 'Miles de Dolares (FOB)'
      let tipo_unidad = 'monto'
      let nombre_data = 'Exportación '+tipo.text

      return {
        series: [
          {
            name: nombre_data,
            data:series
          }
        ],
        categories: categories,
        colors: ['#254cb4'],
        unidad: unidad,
        tipo_unidad: tipo_unidad
      }
    },
   
    order_by_valor(data) {
      data.sort(function (a,b) {
        return b.valor - a.valor
      });
    },

    order_by_anio(data) {
      data.sort(function (a,b) {
        return a.anio - b.anio
      });
    },

    select_data_by_tipo(data,tipo) {
      return '$'+formatNumber(data[tipo])
    },

    

    updateFiltro(newValue, indexFiltro) {

      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
        this.get_data_region_anio(this.region_selected, this.selected_filtro_1)
        this.get_data_regiones_anio(this.selected_filtro_1)
      }

      else if (indexFiltro == 1) {
        this.selected_filtro_2 = newValue
        this.data_region_anio_tipo = this.select_data_by_tipo(this.data_region_anio, this.selected_filtro_2.value)
        this.data_pais_anio_tipo = this.select_data_by_tipo(this.data_pais_anio, this.selected_filtro_2.value)
        this.data_line_chart = this.get_data_line_chart(this.data_region_anios, this.selected_filtro_2)
        this.data_column_chart = this.get_data_column_chart(this.data_regiones_anio, this.selected_filtro_2)  

      }
    }
  }
}
</script>

<style>

</style>