<template>
  <v-card flat :height="height_mapa"> 
    <div id="mapContainer" class="basemap"></div>
    <div id="tooltip"></div>
  </v-card>
</template>

<style>
.basemap {
  width: 100%;
  height: 100%;
}
</style>

<style >
#tooltip {
  font-family: Helvetica, Arial, sans-serif;
  position: absolute;
  padding: 4px;
  margin: 8px;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  max-width: 300px;
  font-size: 14px;
  z-index: 9;
  pointer-events: none;
  height: 220px !important;
  width: 300px !important;
}
</style>

<script>
import mapboxgl from "mapbox-gl";
import {ColumnLayer} from '@deck.gl/layers';
import {MapboxLayer} from '@deck.gl/mapbox';
import {getZoomRegion, formatNumeroRegion} from '@/functions/functions.js'

export default {
  name: 'MapTransporteTerritorial',
  props: [
    'coordenadas_region',
    'codigo_region',
    'height_mapa',
    'data_mapa',
    'tipo_veh'
  ],
  data () {
    return {
      accessToken: 'pk.eyJ1IjoibGFiZGV2MjIiLCJhIjoiY2tzaHFvamRtMXdwZDJ1b2E2ZHRrMDNvYyJ9.A2-aL3_dJ9BIK26yO0Ahug',
      mapa_region:null,
      layer_1:null
    }
  },
  mounted () {
    const zoom_region = getZoomRegion(this.codigo_region)
    const coordenadas_region = this.coordenadas_region
    const codigo_region = formatNumeroRegion(this.codigo_region)
    this.mapa_region = this.get_base_map(coordenadas_region, zoom_region)
    this.addRegionSource(this.mapa_region);
    this.addRegionLayer(this.mapa_region,codigo_region)
    this.addTipoLayer(this.data_mapa, this.tipo_veh, this.mapa_region)
  },
  watch :{
  
    data_mapa() {
      const zoom_region = getZoomRegion(this.codigo_region)
      const coordenadas_region = this.coordenadas_region
      const codigo_region = formatNumeroRegion(this.codigo_region)
      this.mapa_region = this.get_base_map(coordenadas_region, zoom_region)
      this.addRegionSource(this.mapa_region);
      this.addRegionLayer(this.mapa_region, codigo_region)
      this.addTipoLayer(this.data_mapa, this.tipo_veh, this.mapa_region)

    },

    tipo_veh () {
      const zoom_region = getZoomRegion(this.codigo_region)
      const coordenadas_region = this.coordenadas_region
      const codigo_region = formatNumeroRegion(this.codigo_region)
      this.mapa_region = this.get_base_map(coordenadas_region, zoom_region)
      this.addRegionSource(this.mapa_region);
      this.addRegionLayer(this.mapa_region, codigo_region)
      this.addTipoLayer(this.data_mapa, this.tipo_veh, this.mapa_region)
    }
  },
  methods: {
    get_base_map(coordenadas, zoom) {
      mapboxgl.accessToken = this.accessToken;
      let basemap = new mapboxgl.Map({
        container: "mapContainer",
        style: "mapbox://styles/mapbox/light-v10",
        center: coordenadas,
        zoom: zoom+0.5,
        pitch: 65,
        bearing: 300,
        antialias: true
      });

      basemap.addControl(new mapboxgl.NavigationControl());
      // basemap.addControl(new mapboxgl.FullscreenControl());

      return basemap;
    },

    addRegionSource(mapa){
       mapa.on('load', () => {
        mapa.addSource('map_regiones', {
          type: 'vector',
          url: 'mapbox://labdev22.azm906hb',
        });
      });
    },

    addTipoLayer(data_mapa, tipo, mapa) {
      
      let participacion_tipo = tipo.value === 'v_particulares' ? 'participacion_particular' :
                               tipo.value === 'v_pasajeros' ? 'participacion_pasajero' :
                               tipo.value === 'v_carga' ? 'participacion_v_carga' :
                               'participacion_carga'

      let valor_tipo = tipo.value === 'v_particulares' ? 'valor_particular' :
                       tipo.value === 'v_pasajeros' ? 'valor_pasajero' :
                       tipo.value === 'v_carga' ? 'valor_v_carga' :
                       'valor_carga'

      let layer = this.create_tipo_layer(participacion_tipo,valor_tipo, data_mapa)
      mapa.on('load', () => {
          mapa.addLayer(layer)
      })
    },

    create_tipo_layer(participacion_tipo, valor_tipo,data_mapa) {

      return new MapboxLayer({
				id: 'LayerTipo',
				type: ColumnLayer,
				data: data_mapa,
				angle:10,
				coverage:1,
				diskResolution:700,
				elevationscale: 100,
				extruded: true,
				getElevation: d => d.properties[participacion_tipo] * 900 ,
				getFillColor: d => d.properties.color,
				getLineColor: [0, 0, 0],
				getLineWidth: 10,
				getPosition: d => d.geometry.coordinates,
				radius: 5000,
				pickable: true,
				controller: true,
				highlightColor: [0, 0, 128, 128],
				autoHighlight: true,
        visible: true,
				onHover: ({object,x,y}) => {
            const tooltip = document.getElementById('tooltip');
            
            function formatCL(val){
              var valor = val.toLocaleString();
              var i;
              var stringFormat = ''; 
              for(i of valor){
                if( i === '.' ){
                  i = ',';
                  stringFormat+=i;
                }
                else if(i ===','){
                  i = '.';
                  stringFormat+=i;
                }
                else{
                  stringFormat+=i;
                }
              }
              return stringFormat;
            }

            if(object){
              let nombre = object.properties.nombre;
              let valor = object.properties[valor_tipo]
              let tipo = object.properties.tipo;
              let tipo_valor = this.tipo_veh.subtitle
              let anio = object.properties.anio;
							let comuna = object.properties.comuna;
              let participacion = object.properties[participacion_tipo]
              let unidad = this.tipo_veh.tipo_dato;

              tooltip.style.top = `${y}px`;
              tooltip.style.left = `${x+20}px`;
              tooltip.innerHTML = `
                <div class="text-center"><b>${nombre}</b></div>
								<div class="text-center">${comuna}</div>
                <div class="text-left ml-3 mt-4"><b>${tipo}  ${tipo_valor}</b></div>
                <div class="text-left ml-3 mt-1"><b>${anio}</b></div>
                <div class="text-left ml-3 ">${formatCL(valor)} ${unidad}</div>
                <div class="text-left ml-3 mt-2"><b>Participación Regional</b></div>
                <div class="text-left ml-3 ">${participacion} %</div>
                
              `;
              tooltip.style.visibility = "visible";
            }
            else {
              tooltip.style.visibility = "hidden";
              tooltip.innerHTML = '';
            }            
          }
      });
    },

    addRegionLayer(mapa,codigo_region){
      mapa.on('load', () => {
        mapa.addLayer({
          id:'region',
          source: 'map_regiones',
          'source-layer': 'chile_regiones_comunas-6uwztq',
          type: 'fill',
          paint: {
            // 'fill-color': '#5D6D7E',
            'fill-color': [
              'match', ['get','codigo_region'],
              codigo_region,  '#191970',
              '#5D6D7E'
            ],
            'fill-opacity':0.25,
            'fill-outline-color': '#00194c'
          },
        });

        mapa.addLayer({
          'id': 'borde-regiones',
          'type': 'line',
          source: 'map_regiones',
          'source-layer': 'chile_regiones_comunas-6uwztq',
          'paint': {
            'line-color': '#414141',
            'line-width': 0.5,
            'line-opacity': 0.4
          },
        });
      });
    },
  }
}
</script>
