<template>
  <div id="login">
    <v-app>
      <v-main
        style="background-color:#E0E0E0"
      >
        <v-container  fill-height >
          <v-layout align-center justify-center>
            <v-flex style="max-width:400px">
              <v-card>
                <v-card-title class="justify-center">
                  <div class="d-flex">
                    <v-img
                      alt="labict logo"
                      class="shrink "
                      contain
                      src="@/media/logo_azul.png"
                      transition="scale-transition"
                      width="200"
                      height ="56"
                    />
                  </div>
                </v-card-title>
                <v-card-text class="mt-8 px-6">
                  <v-form @submit.prevent="login" ref='form_login'  >
                    <div id="inputs">
                      <v-text-field  v-model='email' light prepend-inner-icon='mdi-account' label='Email' type='email' outlined :rules="[v => !!v || 'Ingrese un Email'] " >
                      </v-text-field>
                      <v-text-field  v-model='password' class="mt-0" light prepend-inner-icon='mdi-lock' label='Contraseña' outlined type='password' :rules="[v => !!v || 'Ingrese una contraseña'] "  >
                      </v-text-field>
                    </div>
                    <v-btn class="mt-2" 
                      :color="error_credenciales == true ? 'red darken-1' : 
                              error_active == true ? 'red darken-1' :
                              login_succesful == true ? 'green darken-1' : '#1565C0'" 
                      block 
                      dark 
                      large
                      type='submit' 
                      transition="fade-transition"
                      
                    >
                    <div v-if="loading_login == true">
                      <v-progress-circular
                        :size="25"
                        :width="3"
                        color="white"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                    <div v-else>
                      <h4 v-if="error_credenciales" class="font-weight-medium">
                        <v-icon left> mdi-alert-circle-outline</v-icon>
                        Credenciales incorrectas
                      </h4>
                      <h4 v-else-if="error_active" class="font-weight-medium">
                        <v-icon left> mdi-alert-circle-outline</v-icon>
                        Cuenta desactivada
                      </h4>
                      <h4 v-else-if="login_succesful" class="font-weight-medium">
                        <v-icon left>mdi-check-circle-outline </v-icon>
                        Credenciales validas
                      </h4>
                      <h4 v-else class="font-weight-medium">
                        Iniciar Sesión
                      </h4>
                    </div>
                  </v-btn>
                  <v-btn outlined color="primary" small  href="/reset_password" class="mt-3">
                    Recuperar contraseña
                  </v-btn>
                </v-form>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>

import {sleep} from '@/functions/functions.js'

export default {
  name: 'Login',
  data() {
    return {
      email: null,
      username:null,
      password: null,
      error_credenciales: false,
      error_active: false,
      login_succesful: false,
      loading_login:false,
    }
  },
  computed:{
     updateInputEmail(){
      return this.email;
    },
    updateInputPassword(){
      return this.password;
    }
  },
  watch:{
     updateInputEmail(){
      this.loading_login = false;
      this.login_succesful = false;
      this.error_credenciales=false;
      this.error_active = false;
    },
    updateInputPassword(){
      this.loading_login = false;
      this.login_succesful = false;
      this.error_credenciales=false;
      this.error_active = false;
    }
  },
  methods:{
    login(){
      this.$refs.form_login.validate()
      if(this.email != null &&  this.password != null){
        this.loading_login = true;
        let email = this.email;
        let password = this.password;
        
        sleep(2500).then(() => {
          this.$store.dispatch('login', {email, password})
          .then( () => {
            this.loading_login = false;
            this.error_credenciales = false;
            this.error_active = false;
            this.login_succesful = true;

            sleep(2000).then(() =>{
              this.$router.push({path: '/'})
            })
          })
          .catch(err => {
            this.loading_login = false;
            this.login_succesful = false;
            let error_type = err.response.data.detail;
            if(error_type === 'credenciales_invalidas'){
              this.error_credenciales = true;
              this.error_active = false;
            }
            else if(error_type === 'desactivada'){
              this.error_credenciales = false;
              this.error_active = true;
            }
          })
        })
        this.$refs.form_login.validate();
      }
    }
  }
  

}
</script>


