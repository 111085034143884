<template>
  <div id="VerifyEmail">
    <v-app>
      <v-main style="background-color:#E0E0E0">
        <v-container  fill-height>
          <v-layout align-center justify-center>
            <v-flex style="max-width:900px">
              <v-card>
                <v-card-title class="justify-center">
                  <div class="d-flex">
                    <v-img
                      alt="labict logo"
                      class="shrink "
                      contain
                      src="@/media/logo_azul.png"
                      transition="scale-transition"
                      width="200"
                      height ="56"
                    />
                  </div>
                </v-card-title>
                <v-card-text class="mt-4 px-6">
                  <div>
                    <v-alert
                      
                      type="success"
                      outlined
                      text
                      :value="verify_success"
                      transition="fade-transition"
                    > 
                      <div style="margin-left:-30px;">
                        <span><b>{{email}}</b> su cuenta ha sido activada correctamente</span>
                        <br>
                        <span>Se ha enviado un enlace para crear la contraseña.</span>
                      </div>            
                    </v-alert>
                  </div>
                  <div>
                    <v-alert
                      dense
                      outlined
                      text
                      type="error"
                      :value="verify_fail"
                      transition="fade-transition"
                    > 
                      <div style="margin-left:-30px;">
                        <span>No es posible ejecutar la acción requerida.</span>
                      </div>            
                    </v-alert>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
const API_URL_RESET = 'https://api.datari.net/auth/request-reset-email/'
import axios from 'axios'

export default {
  name: 'VerifyEmail',
  props: ['email'],
  data () {
    return {
      verify_success:false,
      verify_fail: false
    }
  },
  created() {
    if (this.email === undefined) {
      this.verify_fail = true
    }
    else {
      this.generate_reset_password()
      this.verify_success = true
    }
  },
  methods: {
    generate_reset_password () {
      // let change_password_url = 'https://datari.net/change_password/'
      let change_password_url = 'https://beta.datari.net/change_password/'
      // let change_password_url = 'http://localhost:8080/change_password/'
      if(this.email != null){
        let email = this.email;
        axios({
            method: 'post',
            url: API_URL_RESET,
            data: {
                email: email,
                redirect_url: change_password_url
            }
        });
      }
    }
  }

}
</script>

<style>

</style>