<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col xl=3 sm=3>
          <info-card-simple
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'En situación de pobreza'"
            :valor="cantidad_region"
            :tipo_valor="'Personas'"

          />
          <info-card-simple
            class="mt-2"
            :icon="'mdi-information'"
            :titulo="'País'"
            :subtitulo="'En situación de pobreza'"
            :valor="cantidad_pais"
            :tipo_valor="'Personas'"
          />
          
        </v-col>
        <v-col xl=9 sm=9>
          
          <info-card-column-chart 
            :icon="'mdi-chart-bar'"
            :titulo="'Comparación Regional'"
            :subtitulo="'Personas en situación de pobreza'"
            :height_chart="size_column_chart.height_chart"
            :height_card="size_column_chart.height_card"
            :data="data_column_chart"
          />
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px;">
        <v-col>
          <info-card-tree-map 
            :icon="'mdi-chart-tree'"
            :titulo="'Comparación Comunal'"
            :height_card="size_tree_map.height_card"
            :height_chart="size_tree_map.height_chart"
            :data="data_tree_map"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardColumnChart from '../../components/infoCardColumnChart.vue';
import InfoCardTreeMap from '../../components/infoCardTreeMap.vue';

export default {
  name: 'View8Region',
  components: { HeaderComponent, InfoCardSimple, InfoCardColumnChart, InfoCardTreeMap},
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      cantidad_region:null,
      cantidad_pais:null,
      data_column_chart:null,
      data_tree_map:null
    }
  },
  created() {
    this.get_data_region(this.region_selected, this.selected_filtro_1)
  },
  computed : {
    size_column_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 327,
          height_chart: 260
        }
      }
      else {
        return {
          height_card: 307,
          height_chart: 240
        }
      }
    },
    size_tree_map () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 430,
          height_chart: 355
        }
      }
      else {
        return {
          height_card: 260,
          height_chart: 200
        }
      }
    }
  },
  watch: {
    region_selected () {
      this.get_data_region(this.region_selected, this.selected_filtro_1)
    }
  },
  methods: {
    async get_data_region(region, anio) {
      let url = this.data_view.url_api[0]
      let url_api = API_URL+url+'?region='+region+'&anio='+anio
      let response = await axios.get(url_api)
      let data = response.data
      let data_region = data.data_region
      let data_comunas = data.results
      let data_pais = data.data_pais
      let data_regiones = data_pais.regiones

      let cantidad_region = data_region.cantidad_personas
      let cantidad_pais = data_pais.cantidad_personas
     
      this.cantidad_region = formatNumber(cantidad_region)
      this.cantidad_pais = formatNumber(cantidad_pais)
      
      let data_column_chart = this.get_data_column_chart(data_regiones)
      this.data_column_chart = data_column_chart
      
      this.order_by_porcentaje_pobreza(data_comunas)
      let data_tree_map = this.get_data_tree_map(data_comunas)
      this.data_tree_map = data_tree_map    
    },

    get_data_tree_map(data){
      let series = []

      for (let element of data) {
        let comuna = element.comuna.nombre
        let valor = element.porcentaje_pobreza
        
        let data_element = {
          x: comuna,
          y: valor
        }

        series.push(data_element)
      }

      return {
        data: series,
        distributed: false,
        nombre_data: 'Pobreza Multidimensional',
        unidad: 'En situación de pobreza',
        tipo_unidad:'porcentaje',
        tipo_categoria: 'regiones'
      }
    },

    order_by_porcentaje_pobreza(data){
      data.sort(function (a,b) {
        return b.porcentaje_pobreza - a.porcentaje_pobreza
      });
    },

    get_data_column_chart(data) {
      let series = []
      let categories = []
      let colors = []
      
      for (let element of data) {
        if (!categories.includes(element.nombre)) {
          let category = element.nombre.includes('Arica') ? 
                          ['Arica','Parinacota'] : 
                          element.nombre;
          
          categories.push(category);
        }

        let dato = element.cantidad_personas
        series.push(dato)

        if (element.nombre == this.region_detail.nombre) {
          colors.push('#398104');
        }
        else{
          colors.push("#898989");
        }
      }

      return {
        series: [
          {
            data: series,
            name: 'Personas'
          }
        ],
        categories: categories,
        colors: colors,
        unidad: 'Personas',
        tipo_unidad:'no_monto',
        tipo_categoria: 'regiones'
      }
    },

     updateFiltro(newValue, indexFiltro) {

      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
      }
      this.get_data_region(this.region_selected, this.selected_filtro_1)

    }
  }
}
</script>
