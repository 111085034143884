<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col> 
          <info-card-simple 
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Avaluo '+selected_filtro_1.id"
            :valor="data_region"
            :tipo_valor="unidad_data"
          />
        </v-col>
        <v-col>
          <info-card-simple-bar
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Variación Avaluos '+selected_filtro_1.text"
            :valor="data_variacion_region"
            :nombre_valor="(selected_filtro_2-1)+'-'+selected_filtro_2"
          />
        </v-col>
        <v-col>
          <info-card-simple 
            :icon="'mdi-information'"
            :titulo="'País'"
            :subtitulo="'Avaluo '+selected_filtro_1.id"
            :valor="data_pais"
            :tipo_valor="unidad_data"
          />
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px">
        <v-col xl=4 sm=4>
          <info-card-donut-chart 
            :height_card="size_donut_chart.height_card"
            :titulo="'Avaluo Total'"
            :subtitulo="region_detail.nombre" 
            :icon="'mdi-chart-donut'"         
            :data="data_donut_chart"
            :height_chart="size_donut_chart.height_chart"
            :width_chart="size_donut_chart.width_chart"
          />
        </v-col>
        <v-col xl=8 sm=8> 
          <info-card-tree-map 
            :icon="'mdi-chart-tree'"
            :titulo="'Avaluos Regionales'"
            :subtitulo="'Predios '+selected_filtro_1.text"
            :height_card="size_tree_map.height_card"
            :height_chart="size_tree_map.height_chart"
            :data="data_tree_map"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardSimpleBar from '../../components/infoCardSimpleBar.vue';
import InfoCardTreeMap from '../../components/infoCardTreeMap.vue';
import InfoCardDonutChart from '../../components/infoCardDonutChart.vue';

export default {
  components: { HeaderComponent, InfoCardSimple, InfoCardSimpleBar, InfoCardTreeMap, InfoCardDonutChart },
  name: 'View4Region',
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      selected_filtro_2: this.data_view.filtros[1].data[0],
      data_region: null,
      data_variacion_region:null,
      unidad_data:null,
      data_pais:null,
      data_tree_map:null,
      data_donut_chart: null
    }
  },
  created () {
    this.get_data_region(this.selected_filtro_1,this.selected_filtro_2, this.region_selected)
    this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2)
  },
  computed: {
    size_tree_map () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 600,
          height_chart: 520,
        }
      }
      else {
        return {
          height_card: 405,
          height_chart: 330,
          
        }
      }
    },
    
    size_donut_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 600,
          height_chart: 540,
          width_chart:600
        }
      }
      else {
        return {
          height_card: 405,
          height_chart: 330,
          width_chart:380
        }
      }
    }
  },
  watch: {
    region_selected () {
      this.get_data_region(this.selected_filtro_1, this.selected_filtro_2,this.region_selected)
      this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2)

    }
  },
  methods: {

    async get_data_region(tipo, anio,region) {
      let url = this.data_view.url_api[0];
      let url_api = API_URL+url+'?region='+region+'&anio='+anio
      let response = await axios.get(url_api)
      let data = response.data.results[0]
      
      let data_exentos = data.avaluos_exentos
      let data_afectos = data.avaluos_afectos
      // let data_total = data_exentos+data_afectos
      let variacion_exentos = data.variacion_exentos
      let variacion_afectos = data.variacion_afectos

      let data_region = tipo.id === 'exentos' ? data_exentos : data_afectos
      let data_variacion_region = tipo.id === 'exentos' ? variacion_exentos : variacion_afectos
  
      this.data_region = '$'+formatNumber(data_region)
      this.data_variacion_region = data_variacion_region
      this.unidad_data = data.tipo_dato

      let data_donut_chart = {
        series: [data_exentos, data_afectos],
        labels: ['Predios Exentos','Predios Afectos'],
        unidad: 'Millones de Pesos',
        tipo_unidad: 'monto',
        colors:['#0069c0','#001970']
      }

      this.data_donut_chart = data_donut_chart
    },

    async get_data_regiones(tipo, anio) {
      let url = this.data_view.url_api[0]
      let url_api = API_URL+url+'?anio='+anio
      let response = await axios.get(url_api)
      let data = response.data.results

      let data_pais =  data.find( e => e.region.id == 18)
      let data_exentos_pais = data_pais.avaluos_exentos
      let data_afectos_pais = data_pais.avaluos_afectos

      data_pais = tipo.id === 'exentos' ? data_exentos_pais : data_afectos_pais
      this.data_pais = '$'+formatNumber(data_pais)

      let tipo_avaluo = 'avaluos_'+this.selected_filtro_1.id
      this.order_by_tipo(data,tipo_avaluo)
      this.data_tree_map = this.get_data_tree_map(data, tipo_avaluo)
    },

    order_by_tipo(data,tipo) {
      data.sort(function (a,b) {
        return b[tipo] - a[tipo]
      });
    },
    get_data_tree_map(data,tipo) {
      let series = []
      let colors = []

      for (let element of data) {
        let region = element.region
        let avaluo
        if (region.id != 18) {
          region = element.region.nombre
          avaluo = element[tipo]

          let data_element = {
            x: region, 
            y: avaluo
          }

          series.push(data_element)

          if (element.region.id == this.region_selected) {
            colors.push('#4682B4')
          }
          else {
            colors.push('#89CFF0')
          }
        }
      }

      let nombre_data = 'Avaluo'
      let unidad = 'Millones de Pesos'
      let tipo_unidad = 'monto'
      let distributed = true

      return {
        data: series,
        colors: colors,
        nombre_data: nombre_data,
        unidad: unidad,
        tipo_unidad: tipo_unidad,
        distributed: distributed
      }
    },

    updateFiltro(newValue, indexFiltro) {


      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
      }

      else if (indexFiltro == 1) {
        this.selected_filtro_2 = newValue
      }

      this.get_data_region(this.selected_filtro_1, this.selected_filtro_2, this.region_selected)
      this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2) 
    }
  }
}
</script>

<style>

</style>