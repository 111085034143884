<template>
  <div>
    <v-card flat :height="height_card">
      <v-card-title>
        <v-icon
        medium
        left
        >
        {{icon}}
        </v-icon>
        <h4 class="font-weight-medium">{{titulo}}</h4>  
      </v-card-title>
      <v-card-subtitle class="text-left">
          <h3 class="font-weight-medium">{{subtitulo}}</h3>
      </v-card-subtitle>
      <v-card-text style="margin-top:-30px;">
        <div v-if="data != null">
        <v-row>
          <v-col xl=4 sm=5>
            <v-list>
              <div>
                 <h2 class="font-weight-light text-left" v-if="height_chart === 'xl'">{{data.nombre_labels}}</h2>
                 <h3 class="font-weight-light text-left" v-else>{{data.nombre_labels}}</h3>
              </div>
              <div :style="height_chart === 'xl' ? 'margin-top:0px' : 'margin-top:-10px'">
                <v-list-item
                  v-for="(label,i) in data.labels"
                  :key="i"
                  :class="height_chart === 'xl' ? 'mb-n4': 'mb-n7'"
                >
                  <span :class="height_chart === 'xl' ? 'font-weight-light subtitle-1' : 'font-weight-light subtitle-2'" v-if="label === data.selected"><b>{{i+1}}. {{label}}</b></span>
                  <span :class="height_chart === 'xl' ? 'font-weight-light subtitle-1' : 'font-weight-light subtitle-2'" v-else>{{i+1}}. {{label}}</span>
                </v-list-item>
              </div>
            </v-list>
          </v-col>
          <v-col xl=8 sm=7> 
             <v-list>
                <div>
                 <h2 class="font-weight-light" v-if="height_chart === 'xl'">{{data.nombre_data}}</h2>
                 <h3 class="font-weight-light" v-else>{{data.nombre_data}}</h3>
              </div>
              <div
                :style="height_chart === 'xl' ? 'margin-top:0px;' : 'margin-top:-10px;'"
              >
                <v-list-item
                  v-for="(valor_data,i) in data.data"
                  :key="i"
                  :class="height_chart === 'xl' ? 'mb-n4': 'mb-n7'"
                >
                  <v-progress-linear 
                    :value="valor_data"
                    :color="data.labels.indexOf(data.selected) == i ? 'blue' : 'blue-grey'"
                    :height="height_chart === 'xl' ? 20 : 16"
                  >
                    <template v-slot:default="{ value }">
                      <span v-if="height_chart === 'xl'"><strong>{{ value.toFixed(2) }}%</strong></span>
                      <span v-else><strong><small>{{ value.toFixed(2) }}%</small></strong></span>
                    </template>
                  </v-progress-linear>
                </v-list-item>
              </div>
            </v-list>
          </v-col>
        </v-row>
        <!-- <ranking-chart 
          v-if="data != null"
          :data_chart="data_chart"
          :categories_chart="labels_chart"
          :height_chart="height_chart"
          :colors_chart="colors_chart"
          :unidad_chart="unidad_chart"
          :tipo_unidad_chart="tipo_unidad_chart"
          :nombre_data="nombre_data"
        /> -->
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name:'infoCardRanking',
  props:[
    'icon', 'titulo','subtitulo',
    'data',
    'height_card',
    'height_chart'
  ],
}
</script>