<template>
  <v-card flat :height="height_card">
      <v-card-title>
        <v-icon
        medium
        left
        >
        {{icon}}
        </v-icon>
        <h4 class="font-weight-medium">{{titulo}}</h4>  
      </v-card-title>
      <v-card-subtitle class="text-left">
          <h3 class="font-weight-medium">{{subtitulo}}</h3>
      </v-card-subtitle>
      <v-card-text style="margin-top:-10px;">
        <donut-chart
          v-if="data != null"
          :width_chart="width_chart"
          :data_chart="data_chart"
          :labels_chart="labels_chart"
          :tipo_unidad_chart="tipo_unidad_chart"
          :unidad_chart="unidad_chart"
          :colors_chart="colors_chart"
         />
      </v-card-text>
    </v-card>
</template>

<script>
import DonutChart from './charts/DonutChart.vue'
export default {
  components: { DonutChart },
  name:'infoCardDonutChart',
  props: [
    'icon','titulo','subtitulo',
    'data',
    'height_card','height_chart','width_chart'
  ],
  computed:{
    data_chart () {
      return this.data.series
    },
    labels_chart () {
      return this.data.labels
    },
    colors_chart () {
      return this.data.colors
    },
     unidad_chart () {
      return this.data.unidad
    },

    tipo_unidad_chart () {
      return this.data.tipo_unidad
    }
  }
   
}
</script>

<style>

</style>