<template>
  <div>
    <div v-if="data_url.id_vista_region == 1">
      <view-1 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 2">
      <view-2 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 3">
      <view-3  :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 4">
      <view-4 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 5">
      <view-5 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 6">
      <view-6 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 7">
      <view-7 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 8">
      <view-8 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 9">
      <view-9 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 10">
      <view-10 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 11">
      <view-11 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 12">
      <view-12 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 13">
      <view-13 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 14">
      <view-14 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 15">
      <view-15  :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 16">
      <view-16 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 17">
      <view-17 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 18">
      <view-18 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 19">
      <view-19 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
    <div v-else-if="data_url.id_vista_region == 20">
      <view-20 :region_selected="region_selected" :region_detail="region_detail" :data_view="data_url" />
    </div>
  </div>
</template>

<script>
import View1 from './View1.vue'
import View2 from './View2.vue'
import View3 from './View3.vue'
import View4 from './View4.vue'
import View5 from './View5.vue'
import View6 from './View6.vue'
import View7 from './View7.vue'
import View8 from './View8.vue'
import View9 from './View9.vue'
import View10 from './View10.vue'
import View11 from './View11.vue'
import View12 from './View12.vue'
import View13 from './View13.vue'
import View14 from './View14.vue'
import View15 from './View15.vue'
import View16 from './View16.vue'
import View17 from './View17.vue'
import View18 from './View18.vue'
import View19 from './View19.vue'
import View20 from './View20.vue'
export default {
  components: { View1, View2, View3, View4, View5, View6, View7, View8, View9, View10, View11, View12, View13, View14, View15, View16, View17, View18, View19, View20 },
  name: 'SelectViewRegion',
  props: [
    'data_url',
    'region_selected',
    'region_detail'
  ]
}
</script>

<style>

</style>