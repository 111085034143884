<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col xl=3 sm=3>
          <info-card-simple 
            :icon="'mdi-information'"
            :titulo="comuna_detail.nombre"
            :subtitulo="'Vehículos en circulación'"
            :valor="data_comuna"
            :tipo_valor="'Vehículos'"
          />
          <info-card-simple 
            class="mt-2"  
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Vehículos en circulación'"
            :valor="data_region"
            :tipo_valor="'Vehículos'"
          />
        </v-col>
        <v-col xl=9 sm=9>
          <info-card-line-chart 
            :titulo="'Variación Anual'"
            :subtitulo="comuna_detail.nombre"
            :icon="'mdi-trending-up'"
            :data="data_line_chart"
            :height_chart="size_line_chart.height_chart"
            :height_card="size_line_chart.height_card"
          />
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px;">
        <v-col>
          <info-card-tree-map 
            :icon="'mdi-chart-tree'"
            :titulo="'Comparación Comunal'"
            :subtitulo="'Vehículos en Circulación'"
            :height_card="size_tree_map.height_card"
            :height_chart="size_tree_map.height_chart"
            :data="data_tree_map"
          /> 
        </v-col>
      </v-row>
     
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardTreeMap from '../../components/infoCardTreeMap.vue';
import InfoCardSankey from '../../components/infoCardSankey.vue';
import InfoCardLineChart from '../../components/infoCardLineChart.vue';

export default {
  name: 'View1Comuna',
  components: { HeaderComponent, InfoCardSimple, InfoCardTreeMap, InfoCardSankey, InfoCardLineChart},
  props: [
    'region_selected',
    'data_view',
    'region_detail',
    'comuna_selected',
    'comuna_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      data_comuna: null,
      data_region: null,
      data_tree_map:null,
      data_line_chart:null,
    }
  },
  created() {
    this.get_data_comuna_anio(this.comuna_selected, this.selected_filtro_1)
    this.get_data_region_anio(this.region_selected, this.selected_filtro_1)
    this.get_data_comuna_anios(this.comuna_selected)
  },
  computed : {
    size_tree_map () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 430,
          height_chart: 350
        }
      }
      else {
        return {
          height_card: 255,
          height_chart: 185
        }
      }
    },
   
    size_line_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl') {
        return {
          height_chart:  232,
          height_card:326
        }
      }
      else {
        return {
          height_chart:  215,
          height_card:307
        }
      }
    },
  },
  watch: {
    comuna_selected() {
      this.get_data_comuna_anio(this.comuna_selected, this.selected_filtro_1)
      this.get_data_region_anio(this.region_selected, this.selected_filtro_1)
      this.get_data_comuna_anios(this.comuna_selected)
    }
  },
  methods: {

    async get_data_comuna_anio(comuna, anio) {
      let url = this.data_view.url_api[1];
      let  url_api = API_URL+url+'?comuna='+comuna+'&anio_data='+anio
      let response = await axios.get(url_api)
      let data = response.data.results[0]

      let data_region = data.total_region
      let data_comuna = data.total

      this.data_comuna = formatNumber(data_comuna)
      this.data_region = formatNumber(data_region)

    },

    async get_data_comuna_anios(comuna) {
      let url = this.data_view.url_api[1];
      let  url_api = API_URL+url+'?comuna='+comuna
      let response = await axios.get(url_api)
      let data = response.data.results

      this.data_line_chart = this.get_data_line_chart(data)

    },

    async get_data_region_anio(region, anio) {
      let url = this.data_view.url_api[1];
      let  url_api = API_URL+url+'?region='+region+'&anio_data='+anio
      let response = await axios.get(url_api)
      let data = response.data.results

      this.data_tree_map = this.get_data_tree_map(data)
    },

    get_data_line_chart(data) {
      let series = []
      let categories = []

      for (let element of data) {
        if (!categories.includes(element.anio_data)) {
          categories.push(element.anio_data)
          series.push(element.total)
        }
      }

      let unidad = 'Vehículos'
      let tipo_unidad = 'no_monto'
      let nombre_data = 'En circulación'

      return {
        series: [
          {
            name: nombre_data,
            data: series
          }
        ],
        categories: categories,
        colors: ['#254cb4'],
        unidad: unidad,
        tipo_unidad: tipo_unidad
      }
    },

    get_data_tree_map(data,tipo){
      let series = []
      let colors = []

      this.order_by_total(data)

      for (let element of data) {
        let comuna = element.comuna.nombre
        let valor = element.total
        
        let data_element = {
          x: comuna,
          y: valor
        }

        series.push(data_element)

        if (element.comuna.id == this.comuna_selected) {
          colors.push('#4682B4')
        }
        else {
          colors.push('#89CFF0')
        }
        
      }
      return {
        data: series,
        distributed: true,
        colors: colors,
        nombre_data: 'Vehículos en Circulación',
        unidad: 'Vehículos',
        tipo_unidad:'no_monto',        
      }
    },

    order_by_total(data){
      
       data.sort(function (a,b) {
        return b.total - a.total
      });
    },

    updateFiltro(newValue, indexFiltro) {

      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
      }
      this.get_data_comuna_anio(this.comuna_selected, this.selected_filtro_1)
      this.get_data_region_anio(this.region_selected, this.selected_filtro_1)
    }
  }
}
</script>