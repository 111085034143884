<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col xl=3 sm=3>
          <info-card-simple 
            :icon="'mdi-information'"
            :titulo="region_detail.nombre"
            :subtitulo="'Superficie '+selected_filtro_1.text"
            :valor="data_region_tipo"
            :tipo_valor="'Hectareas'"
          />

           <info-card-simple 
            class="mt-2"
            :icon="'mdi-information'"
            :titulo="'País'"
            :subtitulo="'Superficie '+selected_filtro_1.text"
            :valor="data_pais_tipo"
            :tipo_valor="'Hectareas'"
          />
        </v-col>
        <v-col xl=9 sm=9>
          <info-card-column-chart 
            :icon="'mdi-chart-bar'"
            :titulo="'Comparación Regional'"
            :subtitulo="'Superficie '+selected_filtro_1.text"
            :height_chart="size_column_chart.height_chart"
            :height_card="size_column_chart.height_card"
            :data="data_column_chart"
          />
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px;">
        <v-col xl=3 sm=3> 
          <info-card-pie-chart
            :icon="'mdi-chart-pie'"
            :titulo="'Superficie por Tipo'"
            :data="data_pie_chart"
            :width_chart="size_pie_chart.width_chart"
            :height_card="size_pie_chart.height_card"
          />
        </v-col>
        <v-col xl=9 sm=9>
          <info-card-tree-map 
            :icon="'mdi-chart-tree'"
            :titulo="'Comparación Comunal'"
            :subtitulo="'Superficie '+selected_filtro_1.text"
            :height_card="size_tree_map.height_card"
            :height_chart="size_tree_map.height_chart"
            :data="data_tree_map"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardPieChart from '../../components/infoCardPieChart.vue';
import InfoCardColumnChart from '../../components/infoCardColumnChart.vue';
import InfoCardTreeMap from '../../components/infoCardTreeMap.vue';

export default {
  name: 'View13Region',
  components: { HeaderComponent, InfoCardSimple, InfoCardPieChart, InfoCardColumnChart, InfoCardTreeMap},
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      data_region:null,
      data_region_comunas:null,
      data_pais:null,
      data_region_tipo: null,
      data_pais_tipo: null,
      data_regiones:null,
      data_pie_chart: null,
      data_column_chart:null,
      data_tree_map:null
    }
  },
  created() {
    this.get_data_region(this.region_selected)
    this.get_data_regiones()
    this.get_data_region_comunas(this.region_selected)
  },
  computed : {
    size_pie_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 430,
          width_chart: 455
        }
      }
      else {
        return {
          height_card: 255,
          width_chart: 265
        }
      }
    },
    size_tree_map () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 430,
          height_chart: 360
        }
      }
      else {
        return {
          height_card: 255,
          height_chart: 185
        }
      }
    },
    size_column_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 327,
          height_chart: 250
        }
      }
      else {
        return {
          height_card: 307,
          height_chart: 240
        }
      }
    },
  },
  watch: {
    region_selected() { 
      this.get_data_region(this.region_selected)
      this.get_data_regiones()
      this.get_data_region_comunas(this.region_selected)
    }
  },
  methods: {

    async get_data_region(region) {
      let url = this.data_view.url_api[0];
      let  url_api = API_URL+url+'?region='+region
      let response = await axios.get(url_api)
      let data = response.data.results[0]
      
      if(data !== undefined) {
        let data_tipos_superficie = this.data_view.filtros[0].data
        this.data_pie_chart = this.get_data_pie_chart(data_tipos_superficie,data)
        
        this.data_region = data
        let tipo = this.selected_filtro_1.value
        this.data_region_tipo = this.select_data_by_tipo(this.data_region, tipo)
      }
      else {
        this.data_pie_chart = null
        this.data_region_tipo = 'Sin Datos'
      }
    },

    async get_data_regiones(){
      let url = this.data_view.url_api[0];
      let  url_api = API_URL+url
      let response = await axios.get(url_api)
      let data = response.data.results

      this.data_pais = data.find(e => e.region.id == 18)
      let tipo = this.selected_filtro_1
      this.data_pais_tipo = this.select_data_by_tipo(this.data_pais, tipo.value)

      this.data_regiones = data
      this.data_column_chart = this.get_data_column_chart(this.data_regiones, tipo)
    },

    async get_data_region_comunas(region) {
      let url = this.data_view.url_api[1];
      let  url_api = API_URL+url+'?region='+region
      let response = await axios.get(url_api)
      let data = response.data.results

      this.data_region_comunas = data;
      this.data_tree_map = this.get_data_tree_map(this.data_region_comunas, this.selected_filtro_1)
    },

    get_data_tree_map(data,tipo){
      let series = []

      this.order_by_tipo(data, tipo.value)

      for (let element of data) {
        let comuna = element.comuna.nombre
        let valor = element[tipo.value]
        
        let data_element = {
          x: comuna,
          y: valor
        }

        series.push(data_element)
      }

      return {
        data: series,
        distributed: false,
        nombre_data: 'Superficie Sembrada',
        unidad: 'Hectareas',
        tipo_unidad:'no_monto',        
      }
    },

    get_data_column_chart(data, tipo){
      let series = []
      let categories = []
      let colors = []
      
      for (let element of data) {
        if(element.region.id != 18){
          if (!categories.includes(element.region.nombre)) {
            let category = element.region.nombre.includes('Arica') ? 
                            ['Arica','Parinacota'] : 
                            element.region.nombre;
            
            categories.push(category);
            let dato = element[tipo.value]
            series.push(dato)

            if (element.region.id == this.region_selected) {
              colors.push('#398104');
            }
            else{
              colors.push("#898989");
            }
          }
        }
      }

      return {
        series: [
          {
            data: series,
            name: 'Superficie '+tipo.text
          }
        ],
        categories: categories,
        colors: colors,
        unidad: 'Hectareas',
        tipo_unidad:'no_monto',
        tipo_categoria: 'regiones'
      }
    }, 


    get_data_pie_chart(data_tipos_superficie, data_region) {
      let series = []
      let categories = []
      let colors = [
        "#E53935",'#33b2df', '#546E7A', '#d4526e', '#13d8aa',
        '#A5978B', '#2b908f', '#f9a3a4', '#F39C12',"#BBDEFB",'#f48024',
        '#69d2e7',"#4E342E","#66BB6A"
      ]
      for (let element of data_tipos_superficie) { 
        if (element.text !== 'Total') {
          if (!categories.includes(element.text)) {
            categories.push(element.text)

            let tipo_superficie = element.value
            let dato_superficie = data_region[tipo_superficie]
            series.push(dato_superficie)
          }
        }
      }
      let unidad = 'Hectareas'
      let tipo_unidad = 'no_monto'

      return {
        series: series,
        categories: categories,
        colors: colors,
        unidad: unidad,
        tipo_unidad: tipo_unidad
      }
    },

    order_by_tipo(data,tipo){
       data.sort(function (a,b) {
        return b[tipo] - a[tipo]
      });
    },

    select_data_by_tipo(data,tipo) {
      return formatNumber(data[tipo])
    },

     updateFiltro(newValue, indexFiltro) {

      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
        this.data_region_tipo = this.select_data_by_tipo(this.data_region, this.selected_filtro_1.value)
        this.data_pais_tipo = this.select_data_by_tipo(this.data_pais, this.selected_filtro_1.value)
        this.data_column_chart = this.get_data_column_chart(this.data_regiones,this.selected_filtro_1)
        this.data_tree_map = this.get_data_tree_map(this.data_region_comunas, this.selected_filtro_1)
      }
    }
  }
}
</script>