<template>
  <div>
    <div id="header">
      <header-component 
        :titulo="data_view.nombre_data"
        :fuente="data_view.fuente_data"
        :filtros="data_view.filtros"
        @update="updateFiltro"
      />
    </div>
    <div id="body" class="mt-2">
      <v-row>
        <v-col xl=4 sm=5>
          <v-row>
            <v-col cols=6>
              <info-card-simple 
                :icon="'mdi-information'"
                :titulo="region_detail.nombre"
                :subtitulo="selected_filtro_3.text"
                :valor="data_region_tipo_value"
                :tipo_valor="selected_filtro_3.unidad"
              />
               <info-card-simple 
                class="mt-2"
                :icon="'mdi-information'"
                :titulo="region_detail.nombre"
                :subtitulo="'Total '+selected_filtro_3.text"
                :valor="data_region_total_value"
                :tipo_valor="selected_filtro_3.unidad"
              />
            </v-col>
            <v-col cols=6>
              <info-card-simple 
                :icon="'mdi-information'"
                :titulo="'País'"
                :subtitulo="selected_filtro_3.text"
                :valor="data_pais_tipo_value"
                :tipo_valor="selected_filtro_3.unidad"
              />
             
              <info-card-simple 
                class="mt-2"
                :icon="'mdi-information'"
                :titulo="'País'"
                :subtitulo="'Total '+selected_filtro_3.text"
                :valor="data_pais_total_value"
                :tipo_valor="selected_filtro_3.unidad"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col xl=8 sm=7>
          <info-card-column-chart 
            :icon="'mdi-chart-bar'"
            :titulo="'Comparación Regional'"
            :subtitulo="data_view.tipo_data.text+': '+selected_filtro_2.text"
            :height_chart="size_column_chart.height_chart"
            :height_card="size_column_chart.height_card"
            :data="data_column_chart"
          />
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px"> 
        <v-col xl=6>
          <info-card-tree-map 
            :icon="'mdi-chart-tree'"
            :titulo="'Distribución '+data_view.tipo_data.text+'s'"
            :subtitulo="region_detail.nombre"
            :height_card="size_tree_map.height_card"
            :height_chart="size_tree_map.height_chart"
            :data="data_tree_map"
          />
        </v-col>
        <v-col xl=6>
          <info-card-line-chart 
            :titulo="'Variación Anual'"
            :subtitulo="data_view.tipo_data.text+': '+selected_filtro_2.text"
            :icon="'mdi-trending-up'"
            :data="data_line_chart"
            :height_chart="size_line_chart.height_chart"
            :height_card="size_line_chart.height_card"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/'
import axios from 'axios';
import {formatNumber} from '@/functions/functions.js'
import HeaderComponent from '../../components/HeaderComponent.vue';
import InfoCardSimple from '../../components/infoCardSimple.vue';
import InfoCardTreeMap from '../../components/infoCardTreeMap.vue';
import InfoCardColumnChart from '../../components/infoCardColumnChart.vue';
import InfoCardLineChart from '../../components/infoCardLineChart.vue';

export default {
  name: 'View19Region',
  components: { HeaderComponent, InfoCardSimple, InfoCardTreeMap, InfoCardColumnChart, InfoCardLineChart},
  props: [
    'region_selected',
    'data_view',
    'region_detail'
  ],
  data () {
    return {
      selected_filtro_1: this.data_view.filtros[0].data[0],
      selected_filtro_2: this.data_view.filtros[1].data[0],
      selected_filtro_3: this.data_view.filtros[2].data[0],
      data_region_tipo: null,
      data_region_tipo_value:null,
      data_pais_tipo:null,
      data_pais_tipo_value:null,
      data_region_total: null,
      data_region_total_value:null,
      data_pais_total: null,
      data_pais_total_value:null,
      data_tipos_region:null,
      data_regiones_tipo:null,
      data_tipo_anios:null,
      data_tree_map: null,
      data_line_chart:null,
      data_column_chart:null
    }
  },
  created() {
    this.get_data_region_tipo(this.region_selected, this.selected_filtro_1, this.selected_filtro_2)
    this.get_data_region_total(this.region_selected, this.selected_filtro_1)
    this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2)
    this.get_data_region_anios(this.region_selected, this.selected_filtro_2)

  },
  computed: {
    size_line_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl') {
        return {
          height_chart:  360,
          height_card:440
        }
      }
      else {
        return {
          height_chart:  185,
          height_card:255
        }
      }
    },
    size_column_chart () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 327,
          height_chart: 250
        }
      }
      else {
        return {
          height_card: 304,
          height_chart: 235
        }
      }
    },
    size_tree_map () {
      const size_screen = this.$vuetify.breakpoint.name;
      if (size_screen === 'xl'){
        return {
          height_card: 440,
          height_chart: 350
        }
      }
      else {
        return {
          height_card: 255,
          height_chart: 185
        }
      }
    },
  },
  watch: {
    region_selected() {
      this.get_data_region_tipo(this.region_selected, this.selected_filtro_1, this.selected_filtro_2)
      this.get_data_region_total(this.region_selected, this.selected_filtro_1)
      this.data_column_chart = this.get_data_column_chart(this.data_regiones_tipo, this.selected_filtro_3)
      this.get_data_region_anios(this.region_selected, this.selected_filtro_2)

    },

    data_view () {
      this.selected_filtro_1 = this.data_view.filtros[0].data[0]
      this.selected_filtro_2 = this.data_view.filtros[1].data[0]
      this.selected_filtro_3 = this.data_view.filtros[2].data[0]
      this.get_data_region_tipo(this.region_selected, this.selected_filtro_1, this.selected_filtro_2)
      this.get_data_region_total(this.region_selected, this.selected_filtro_1)
      this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2)
      this.get_data_region_anios(this.region_selected, this.selected_filtro_2)
     }
  },
  methods: {

    async get_data_region_tipo(region, anio, tipo) {
      let tipo_data = this.data_view.tipo_data
      let url = this.data_view.url_api[0];
      let  url_api = API_URL+url+'?region='+region+'&anio='+anio+'&'+tipo_data.value+'='+tipo.value
      let response = await axios.get(url_api)
      let data = response.data            
      
      this.data_region_tipo= tipo_data.value === 'tramo' ?
                        data.data_region.data_region :
                        data.data_anual_region.valores

      this.data_pais_tipo = tipo_data.value === 'tramo' ?
                      data.data_region.data_pais :
                      data.data_anual_pais

      this.data_region_tipo_value = this.selectByTipoDatoRegion(this.data_region_tipo, tipo_data.value)
      this.data_pais_tipo_value = this.selectByTipoDatoPais(this.data_pais_tipo, tipo_data.value)
    },

    async get_data_region_total(region,anio) {
      let tipo_data = this.data_view.tipo_data
      let url = this.data_view.url_api[0];
      let  url_api = API_URL+url+'?region='+region+'&anio='+anio
      let response = await axios.get(url_api)
      let data = response.data 

      this.data_region_total= tipo_data.value === 'tramo' ?
                        data.data_region.data_region :
                        data.data_anual_region

      this.data_tipos_region = tipo_data.value === 'tramo' ?
                        this.data_region_total.data_tramos :
                        this.data_region_total.datos_rubros

      
      let tipo_ = this.select_dato_tipos(tipo_data, this.selected_filtro_3)
      this.data_tree_map = this.get_data_tree_map(this.data_tipos_region, tipo_)

      this.data_pais_total = tipo_data.value === 'tramo' ?
                      data.data_region.data_pais :
                      data.data_anual_pais
      
      this.data_region_total_value = this.selectByTipoDatoRegion_2(this.data_region_total, tipo_data)
      this.data_pais_total_value = this.selectByTipoDatoPais_2(this.data_pais_total, tipo_data)
    },

    async get_data_region_anios(region,tipo) {
      let tipo_data = this.data_view.tipo_data
      let url = this.data_view.url_api[0];
      let  url_api = API_URL+url+'?region='+region+'&'+tipo_data.value+'='+tipo.value
      let response = await axios.get(url_api)
      let data = response.data            

      this.data_tipo_anios = tipo_data.value === 'tramo' ?
                            data.data_tramo_anios :
                            data.data_rubro_anios
      
      let tipo_dato = this.select_data_by_tipo(tipo_data)
      this.data_line_chart = this.get_data_line_chart(this.data_tipo_anios, tipo_dato)

    },

    async get_data_regiones(anio, tipo) {
      let tipo_data = this.data_view.tipo_data
      let url = this.data_view.url_api[0];
      let  url_api = API_URL+url+'?&anio='+anio+'&'+tipo_data.value+'='+tipo.value
      let response = await axios.get(url_api)
      let data = response.data  
      this.data_regiones_tipo = data.data_anual_regiones
      this.data_column_chart = this.get_data_column_chart(this.data_regiones_tipo, this.selected_filtro_3)
    },

    get_data_line_chart(data,tipo) {
      let series = []
      let categories = []

      for (let element of data) {
        if (!categories.includes(element.anio)) {
          categories.push(element.anio)
        }

        let dato = this.selected_filtro_3.value === 'monto' ? element[tipo]/1000000 : element[tipo]
        series.push(dato)
      }

      let unidad = this.selected_filtro_3.unidad
      let tipo_unidad = this.selected_filtro_3.value === 'monto' ? 'monto' : 'no_monto'
      let nombre_data = this.selected_filtro_3.text

      return {
        series: [
          {
            name: nombre_data,
            data: series
          }
        ],
        categories: categories,
        colors: ['#254cb4'],
        unidad: unidad,
        tipo_unidad: tipo_unidad
      }
    },

    get_data_column_chart(data,tipo){
      let series = []
      let categories = []
      let colors = []
      
      for (let element of data) {
        if(element.region.id != 18){
          if (!categories.includes(element.region)) {
            let category = element.region.includes('Arica') ? 
                            ['Arica','Parinacota'] : 
                            element.region;
            
            let dato = tipo.value === 'empresas' ? element.total_empresas : element.total_ventas/1000000
            categories.push(category);
            series.push(dato)

            if (element.region === this.region_detail.nombre) {
              colors.push('#398104');
            }
            else{
              colors.push("#898989");
            }
          }
        }
      }

      return {
        series: [
          {
            data: series,
            name: tipo.text
          }
        ],
        categories: categories,
        colors: colors,
        unidad: this.selected_filtro_3.unidad,
        tipo_unidad:this.selected_filtro_3.value == 'monto' ? 'monto' : 'no_monto',
        tipo_categoria: 'regiones'
      }
    },

    select_data_by_tipo(tipo_data){
      let tipo_dato = this.selected_filtro_3
      
      let valor = tipo_dato.value === 'empresas' ?
                  'total_empresas_'+tipo_data.value :
                  'total_ventas_'+tipo_data.value
      return valor
    },

    select_dato_tipos(tipo_data, tipo_dato) {
      let result;
      if (tipo_data.value === 'rubro') {
        result = {
          id: 'rubro',
          text: 'tipo'    
        }
        if (tipo_dato.value === 'empresas') {
          result['value'] = 'cantidad_rubro'
        }
        else {
          result['value'] = 'ventas_rubro'
        }
      }
      else {
        result = {
          id: 'id_tramo',
          text: 'tramo'    
        }
        if (tipo_dato.value === 'empresas') {
          result['value'] = 'cantidad_empresas_tramo'
        }
        else {
          result['value'] = 'ventas_empresas_tramo'
        }
      }
      return result
      
    },

    get_data_tree_map(data,tipo){
      let series = []
      let colors = []

      this.orderbyTipo(data, tipo.value)

      for (let element of data) {
        let tipo_element = element[tipo.text]
        let valor = this.selected_filtro_3.value === 'monto' ?
                    element[tipo.value]/1000000 :
                    element[tipo.value]
    
        let data_element = {
          x: tipo_element,
          y: valor
        }

        series.push(data_element)

        if (element[tipo.id] == this.selected_filtro_2.value) {
          colors.push('#4682B4')
        }
        else {
          colors.push('#89CFF0')
        }
      }

      return {
        data: series,
        colors:colors,
        distributed: true,
        nombre_data:this.selected_filtro_3.text,
        unidad: this.selected_filtro_3.unidad,
        tipo_unidad:this.selected_filtro_3.value === 'monto' ? 'monto' : 'no_monto',        
      }
    },

    orderbyTipo(data, tipo) {
      data.sort(function (a,b) {
        return b[tipo] - a[tipo]
      });
    },

    selectByTipoDatoRegion_2(data, tipo_data) {
      let tipo_dato = this.selected_filtro_3
      let valor;

      if (tipo_data.value === 'tramo') {
         valor = tipo_dato.value === 'empresas' ?
                    formatNumber(data.empresas_region) :
                    '$'+formatNumber(data.ventas_region/1000000)
      }
      else {
        valor = tipo_dato.value === 'empresas' ?
                formatNumber(data.total_anual_empresas) :
                '$'+formatNumber(data.total_anual_ventas/1000000)
      }

      return valor
    },

    selectByTipoDatoRegion(data,tipo_data) {
      let tipo_dato = this.selected_filtro_3
      let valor;
      if (tipo_data === 'tramo') {
        valor = tipo_dato.value === 'empresas' ?
                    formatNumber(data.empresas_region) :
                    '$'+formatNumber(data.ventas_region/1000000)
      }
      else {
        valor = tipo_dato.value === 'empresas' ?
                    formatNumber(data.total_empresas_rubro) :
                    '$'+formatNumber(data.total_ventas_rubro/1000000)
      }
      return valor;
    },

    selectByTipoDatoPais(data,tipo_data) {
      let tipo_dato = this.selected_filtro_3
      let valor;
      if (tipo_data === 'tramo') {
        valor = tipo_dato.value === 'empresas' ?
                    formatNumber(data.empresas_pais) :
                    '$'+formatNumber(data.ventas_pais/1000000)
      }
      else {
        valor = tipo_dato.value === 'empresas' ?
                    formatNumber(data.total_empresas) :
                    '$'+formatNumber(data.total_ventas/1000000)
      }
      return valor;
    },

    selectByTipoDatoPais_2(data,tipo_data) {
      let tipo_dato = this.selected_filtro_3
      let valor;
      if (tipo_data.value === 'tramo') {
        valor = tipo_dato.value === 'empresas' ?
                    formatNumber(data.empresas_pais) :
                    '$'+formatNumber(data.ventas_pais/1000000)
      }
      else {
         valor = tipo_dato.value === 'empresas' ?
                formatNumber(data.total_anual_empresas) :
                '$'+formatNumber(data.total_anual_ventas/1000000)
      }
      return valor;
    },
 
    updateFiltro(newValue, indexFiltro) {

      if (indexFiltro == 0) {
        this.selected_filtro_1 = newValue
        this.get_data_region_tipo(this.region_selected, this.selected_filtro_1, this.selected_filtro_2)
        this.get_data_region_total(this.region_selected, this.selected_filtro_1)
        this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2)


      }

      else if (indexFiltro == 1) {
        this.selected_filtro_2 = newValue
        let tipo_data = this.data_view.tipo_data
        this.get_data_region_tipo(this.region_selected, this.selected_filtro_1, this.selected_filtro_2)
        let tipo_ = this.select_dato_tipos(tipo_data, this.selected_filtro_3)
        this.data_tree_map = this.get_data_tree_map(this.data_tipos_region, tipo_)
        this.get_data_regiones(this.selected_filtro_1, this.selected_filtro_2)
        this.get_data_region_anios(this.region_selected, this.selected_filtro_2)
      }

      else if (indexFiltro == 2) {
        this.selected_filtro_3 = newValue
        let tipo_data = this.data_view.tipo_data
        this.data_region_tipo_value = this.selectByTipoDatoRegion(this.data_region_tipo, tipo_data.value)
        this.data_pais_tipo_value = this.selectByTipoDatoPais(this.data_pais_tipo, tipo_data.value)
        this.data_region_total_value = this.selectByTipoDatoRegion_2(this.data_region_total, tipo_data)
        this.data_pais_total_value = this.selectByTipoDatoPais_2(this.data_pais_total, tipo_data)
        
        let tipo_ = this.select_dato_tipos(tipo_data, this.selected_filtro_3)
        this.data_tree_map = this.get_data_tree_map(this.data_tipos_region, tipo_)

        this.data_column_chart = this.get_data_column_chart(this.data_regiones_tipo, this.selected_filtro_3)

        let tipo_dato = this.select_data_by_tipo(tipo_data)
        this.data_line_chart = this.get_data_line_chart(this.data_tipo_anios, tipo_dato)
      }

    }
  }
}
</script>